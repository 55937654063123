// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const editIcon = require("../assets/edit_icon.png");
export const deleteIcon = require("../assets/delete_icon.png");
export const emailRequest = require("../assets/email-request.png");
export const profileIcon = require("../assets/profile_icon.png");
export const rightArrow = require("../assets/right_arrow.png");
export const emptyBackground = require("../assets/empty_background.png");
export const leaveteamIcon = require("../assets/leave_icon.png");
// Customizable Area End