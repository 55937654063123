Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.listWorkspace = "bx_block_workspace/workspaces/search_workspaces"
exports.createWorkspaceAPI = "bx_block_workspace/workspaces/create_workspace_and_workspace_members"
exports.searchMemberWorkspace = "account_block/accounts/search"
exports.listCategories = "bx_block_categories/categories"
exports.listSubCategories = "bx_block_categories/sub_categories"
exports.detailWorkspace = "bx_block_workspace/workspaces/"
exports.uploadFileWorkspace = "bx_block_workspace/workspaces/upload_files"
exports.addMemberToWorkspace = "bx_block_workspace/workspaces/add_workspace_members"
exports.inviteMemberToWorkspace = "bx_block_workspace/workspaces/invite"
exports.removeMemberToWorkspace = "bx_block_workspace/workspaces/remove_workspace_members"
exports.getLisTeams = "bx_block_team/teams/get_teams_and_teams_members"
exports.getResultList = "bx_block_workspace/query_hub/get_result_list"
exports.leaveWorkspaceApiUrl = "bx_block_workspace/workspaces"
exports.filesUploadedByWorkspaceIdApiUrl = "bx_block_workspace/workspaces/file_list?workspace_id"
exports.uploadFileClientServer = "upload_simple"
exports.uploadSplitFileClientServer = "upload_and_split"
exports.urlClientServer = ""
exports.deleteWorkspaceApiUrl = "bx_block_workspace/workspaces"
exports.lastInteractions = "bx_block_workspace/workspace_activities/interaction_list"

exports.getJsonData = "bx_block_workspace/query_hub/get_jsondata"
exports.getFileList = "bx_block_workspace/query_hub/get_fileInfo"
exports.uploadFile = "bx_block_bulk_uploading/upload_files"
exports.gethistoryList = "bx_block_workspace/query_hub/get_history"



exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";
exports.textCancel = "Cancel";
exports.textCreate = "Create";
exports.textAdd = "Add";
exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";


exports.workSpace = "Workspaces";
exports.search = "Search";
exports.create = "Create";
exports.cancel = "Cancel";
exports.remove = "Remove";
exports.newWorkspace = "New workspace";
exports.newTeam = "New team";
exports.dropDownSpaceholder = "Category or subcategory";
exports.createWorkspace = "Create new workspace"
exports.updateWorkspace = "Update workspace"
exports.workspaceName = "Workspace name"
exports.emptyText = "No created workspaces yet"
exports.createWorkspaceName = "Create workspace name"
exports.descriptionTitle = "Description (Optional)"
exports.fieldIsNotEmpty = "Field cannot be empty"
exports.descriptionPlaceholder = "Add description"
exports.category = "Category"
exports.categoryPlaceholder = "Choose category"
exports.subCategory = "Sub category"
exports.subCategoryPlaceholder = "Choose sub category"
exports.addMemberTitle = "Add members or team"
exports.inviteMemberTitle = "Invite users to workspace"
exports.inviteMemberBtnOk = "Invite"
exports.addMemberPlaceholder = "Search member to add by email, name or team"
exports.generalTabTitle = "General"
exports.queryTabTitle = "AI Query Hub"
exports.memberTabTitle = "Members"

exports.team = "Team"
exports.status = "Status"
exports.addingDate = "Adding date"
exports.email = "Email"
exports.permission = "Permission"
exports.addMembers = "Add members"
exports.add = "Add"
exports.checkDeleteComfirmation = "I confirm I want to delete workspace"
exports.warningCheckDeleteComfirmation = "Please mark the box to confirm an action"
exports.deleteHeaderText = "Are you sure you want to delete?"
exports.teamInviteText = "You’ve been invited to join the team!"
exports.workspaceInviteText = "You’ve been invited to join the WorkSpace!"
exports.removeMemberDescriptionText = "Are you sure you want to remove this user?"
exports.okButtonRemoveMemberText = "Remove member"
exports.viewProfileText = "View profile"
exports.deleteWorkspaceDescription1="<p>Please confirm you want to delete "
exports.deleteWorkspaceDescription2 = "<p> All data within this workspace will be deleted.</p>"
exports.deleteFliesDescription1="<p>Please confirm you want to delete file  "
exports.deleteFilesDescription2 = "<p> File will be not possible to restore.</p>"
exports.cancelDelete = "Cancel"
exports.confirmDelete = "Delete Files"
exports.deleteFiles = "Are you sure you want to delete?"
exports.leaveWorkspaceTitle = "Are you sure you want to leave?"
exports.leaveWorkspaceDesc1="<p>Please confirm you want to leave "
exports.leaveWorkspaceDesc2="<p>To rejoin this workspace you will need to contact the</p><p>workspace admin.</p>"
exports.teamInviteDescription="Please accept the invitation and join the team "
exports.workspaceInviteDescription="Please accept the invitation and join the Workspace "
exports.okleaveWorkspaceText = "Leave workspace"
exports.okButtonDeleteWorkspaceText = "Delete workspace"
exports.okButtonAcceptText = "Accept"
exports.textBackToWorkspace="Back to workspace"
exports.textDeclineInvitation="Decline"
//Detail of DetailWorkspaceTabView
exports.resultsTabTitle = "Results"
exports.jsonTabTitle = "JSON"
exports.filesTabTitle = "Files"
exports.historyTabTitle = "History"
exports.allFilesText="All Interactions"
exports.inviteText="Invite"
exports.editWorkspaceName="Edit workspace name"
exports.editWorkspace="Edit workspace"
exports.deleteWorkspace="Delete workspace"
exports.leaveWorkspace="Leave workspace"
exports.uploadFilesText="Upload Files"
exports.fileOverviewText="File overview"
exports.dragDropFilesText="Drag and drop your files or"
exports.browseText="Browse"
exports.lastIterctionsText="Last Interactions"
exports.viewHistory="View history"

//Columns name of Result Tab of AI Query Hub
exports.cl_id = "#"
exports.cl_category = "Category"
exports.cl_description = "Description"
exports.cl_amount = "Amount(USD)"

//Columns name of File Tab of AI Query Hub
exports.cl_fileInfo = "Uploaded files"
exports.cl_uploadBy = "Uploaded by"

//Columns name of History Tab of AI Query Hub
exports.cl_dateandtime = "Date/Time"
exports.cl_actionDesc = "Action"
exports.cl_action = ""


exports.publishButtonTitle = "Publish"
exports.copyJsonButtonTitle = "Copy JSON"
// Customizable Area End
