import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { WorkspaceMember } from "./WorkspaceListController.web";
import { ActionHistory } from "./HistoryTabController.web";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";

export interface DetailCategoryObject {
  name: string
  id: number
}

export interface AddNewMemberToWorkspaceObject { 
  workspace_id: number;
  account_ids: Array<number>;
  emails: Array<string>;
}

export interface CategoryObject {
  id: string
  attributes: {
      name: string
      id: number
      sub_categories?: Array<DetailCategoryObject>
  }
}

export interface UpdateWorkspaceObject {
  name: string
  description: string
  category: string
  subcategory: string
  account_ids: Array<number>
}

// Customizable Area End

export const webConfigJSON = require("../src/config.js");
 
  // Customizable Area Start
  interface Column {
    id: string;
    label: string;
    align?: 'right' | 'left' | 'center' | 'justify' | 'inherit';
  }
  interface Data {
    [key: string]: any;
  }
export interface FileInfo {
  filename: string,
  content_type: string,
  url: any | string,
  size: number,
  created_at: string,
  updated_at: string,
}
  export interface FileBase {
    files: FileInfo[]
  }

  export interface FileUpload {
    id: string,
    type: string,
    attributes: FileBase,
  }
  // Customizable Area End
export interface Props {
  navigation: any;
  // Customizable Area Start
  listUser: Array<WorkspaceMember>;
  currentUserId: string;
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean; 
  columns: Column[];
  rows: Data[];
  anchorEl:any,
  workspaceDetail : any;
  currentworkspaceId: any;
  page: number;
  rowsPerPage: number;
  isDeleteWorkspace: boolean;
  lastInteractions: ActionHistory[];
  isUpdateDialogOpen:boolean;
  listCategories: Array<CategoryObject>;
  listSubCategories: Array<CategoryObject>;
  totalCategory: Array<DetailCategoryObject>;
  isAdmin: boolean;
  isLeaveWorkspace: boolean;
  isOpenUploadDialog: boolean;
  isOpenDeleteFiles:boolean;
  anchorEl2:any,
  fileId:any,
  fileName:any,
  filesUploaded?: FileUpload[] | undefined;
  totalUploadFile: number;
  isOpenPreviewDialog: boolean;
  dataFiles?: { workspace_id: number; files: any; uploadChoice: string }
  isAddModalOpen: boolean;
  isSnackbarOpen: boolean;
  snackbarContent: string;
  isReloadHistory: boolean;
  isCheckedDeleteConfirmation: boolean;
  // Customizable Area End
}
interface SS { 
 // Customizable Area Start
 id:any
  // Customizable Area End
}

export default class GeneralTabViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  apiGetWorkspaceById: string = "";
  apiDeleteWorkspaceById:string = "";
  apiGetLastInteractions:string = "";
  apiGetCategoryList:string = "";
  apiGetSubCategoryList:string = "";
  apiUpdateWorkspace:string="";
  apiLeaveWorkspaceId:string="";
  uploadFileApiId:string = "";
  removeFilesApiCallId:string = '';
  filesUploadedByWorkspaceIdApiId:string = "";
  addNewMemberToWorkspaceId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      columns: [] as Column[],
      rows: [] as Data[],
      loading: true,
      fileId:"",
      fileName:"",
      anchorEl:null,
      workspaceDetail:{},
      page: 1,
      anchorEl2:null,
      isOpenDeleteFiles:false,
      rowsPerPage: 5,
      isDeleteWorkspace: false,
      lastInteractions:[],
      isUpdateDialogOpen:false,
      listCategories: [],
      listSubCategories: [],
      totalCategory: [],
      isAdmin: true,
      isLeaveWorkspace: false,
      isOpenUploadDialog: false,
      filesUploaded: [],
      totalUploadFile: 0,
      isOpenPreviewDialog: false,
      dataFiles: undefined,
      currentworkspaceId: 0,
      isAddModalOpen: false,
      isSnackbarOpen: false,
      snackbarContent: "",
      isReloadHistory: false,
      isCheckedDeleteConfirmation: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.checkIsAdmin();
    this.getWorkspaceById();
    // this.getLastInteractions();
    this.getCategoryListAPI();
    this.getFilesUploadedByWorkspaceIdAPI();
    // Customizable Area End
  }

  addNewMemberToWorkspaceAPI = async (dataNewWorkspace: AddNewMemberToWorkspaceObject) => {
    const token = await getStorageData("authToken")
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )
    this.addNewMemberToWorkspaceId = request.messageId;
    createRequestMessage({
        token,
        requestMessage: request,
        method: "POST",
        body: JSON.stringify(dataNewWorkspace),
        endPoint: `${baseURL}/${webConfigJSON.inviteMemberToWorkspace}`
    });
  }

  async getWorkspaceById() {
    // Customizable Area Start
    this.setState({isReloadHistory:false})
    const token = await getStorageData("authToken")
    const workspaceId = this.props.navigation.getParam("id")
    const webHeader = {
        "Content-Type": webConfigJSON.apiContentType,
        token
      };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetWorkspaceById = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.detailWorkspace}${workspaceId}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  deleteWorkspaceAPI = async () => {
    // Customizable Area Start
    const token = await getStorageData("authToken")
    const workspaceId = this.props.navigation.getParam("id")
    const webHeader = {
      "Content-Type": webConfigJSON.apiContentType,
      token
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteWorkspaceById = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.deleteWorkspaceApiUrl}/${workspaceId}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.deleteApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async getLastInteractions() {
    // Customizable Area Start
    const token = await getStorageData("authToken");
    const workspaceId = this.props.navigation.getParam("id");
    const webHeader = {
        "Content-Type": webConfigJSON.apiContentType,
        token
      };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetLastInteractions = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.lastInteractions}?workspace_id=${workspaceId}&page=${this.state.page}&per_page=${this.state.rowsPerPage}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async getCategoryListAPI() {
    // Customizable Area Start
    const token = await getStorageData("authToken");
    const webHeader = {
        "Content-Type": webConfigJSON.apiContentType,
        token
      };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCategoryList = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.listCategories}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async getSubCategoryListAPI() {
    // Customizable Area Start
    const token = await getStorageData("authToken");
    const webHeader = {
        "Content-Type": webConfigJSON.apiContentType,
        token
      };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSubCategoryList = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.listSubCategories}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if(webApiRequestCallId === this.apiGetWorkspaceById){
            this.setState({
              workspaceDetail : webResponseJson,
              errorMsg: "",
              isReloadHistory:true,
              loading: false
        },()=>this.getLastInteractions());
        }
        if(webApiRequestCallId === this.filesUploadedByWorkspaceIdApiId){
          this.setState({
            filesUploaded : webResponseJson.files,
            errorMsg: "",
            loading: false
          },()=>this.getLastInteractions());

          await this.getTotalUploadFile(this.state.filesUploaded);
          
        }
        if (webApiRequestCallId === this.addNewMemberToWorkspaceId) {
          if (webResponseJson.message === "Invitations sent successfully.") {
            this.setState({ isSnackbarOpen: true,isReloadHistory: true, snackbarContent: webResponseJson?.error ?? webResponseJson.message})
          } else {
            this.setState({ isSnackbarOpen: true, snackbarContent: webResponseJson?.error ?? "Cannot sent email invite. Please try later" })
          }
        }
        if(webApiRequestCallId === this.apiDeleteWorkspaceById){
          this.props.navigation.navigate("WorkspaceList")
        }
        if(webApiRequestCallId === this.apiLeaveWorkspaceId){
          this.props.navigation.navigate("WorkspaceList")
        }
        if(webApiRequestCallId === this.apiGetLastInteractions){
          this.setState({ lastInteractions: webResponseJson });
        }
        if(webApiRequestCallId === this.apiGetCategoryList){
          this.setState({ listCategories: webResponseJson.data })
          this.getSubCategoryListAPI();
        }
        if(webApiRequestCallId === this.removeFilesApiCallId){
          if(webResponseJson.message){
          this.setState({isSnackbarOpen:true,isReloadHistory: true, snackbarContent: webResponseJson.message,isOpenDeleteFiles:false },()=>this.getLastInteractions())
          this.getFilesUploadedByWorkspaceIdAPI();
          }else{
            this.setState({isSnackbarOpen:true,isReloadHistory: true, snackbarContent: webResponseJson.error,isOpenDeleteFiles:false })
            this.getFilesUploadedByWorkspaceIdAPI();
          }  
        }
        if (webApiRequestCallId === this.apiGetSubCategoryList) {
          const duplicateCategories = [...this.state.listCategories]
          const listTotalAllCategory = duplicateCategories.concat(webResponseJson.data)
          this.setState({ 
              listSubCategories: webResponseJson.data,
              totalCategory: listTotalAllCategory.map(item => ({
                  name: item.attributes.name,
                  id: item.attributes.id,
              })),
              loading: false
          })
        }
        if(webApiRequestCallId === this.apiUpdateWorkspace){
          this.setState({isSnackbarOpen:true, snackbarContent: "Updated successfully"})
          this.getWorkspaceById();
        }
        if(webApiRequestCallId === this.uploadFileApiId){
          this.setState({isSnackbarOpen:true,isReloadHistory: true, snackbarContent: "Uploaded Successfully"},()=>this.getLastInteractions())
          this.getWorkspaceById();
          this.getLastInteractions();
          this.getFilesUploadedByWorkspaceIdAPI();
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if(webApiRequestCallId === this.apiGetWorkspaceById){
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
        if(webApiRequestCallId === this.apiLeaveWorkspaceId){
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        } 
        if(webApiRequestCallId === this.filesUploadedByWorkspaceIdApiId){
          this.setState({
            errorMsg: webErrorReponse.message,
            loading: false
          });
        }

      }
    }
    // Customizable Area End
  }
  // Customizable Area Start 
  componentDidUpdate(prevProps: Props) {
      // Check if the 'value' prop has changed
      if (prevProps.listUser !== this.props.listUser) {
          this.checkIsAdmin();
      }
  }

  onClosePreviewDialog = (data?: Array<File>) => {
    this.setState({ isOpenPreviewDialog: false })
    if (data) {
      Promise.all(data.map(file => {
        this.uploadFileClientServerAPI(file)
        this.uploadSplitFileClientServerAPI(file)
      }))
    }
  }

  onCloseCancelDialog = () => {
    this.setState({ isOpenDeleteFiles: false })
}
confirmdeleteFiles = ()=>{
  this.setState({isOpenDeleteFiles:true, anchorEl2:null})
}
onCloseAll =()=>{
  this.setState({isReloadHistory:false})
  this.removeFiles(this.state.fileId)
}
  removeFiles = async (id:number) => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": webConfigJSON.apiContentType,
      token:token,
    };
    const workspaceId = this.props.navigation.getParam("id");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      file_id: id
  };

    this.removeFilesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_workspace/workspaces/${workspaceId}/delete_workspace_files`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClose2 = () => {
    this.setState({anchorEl2:null});
    };
    handleClick1 = (event:any,id:string,name:string) => {
      this.setState({anchorEl2:event.currentTarget,fileId:id,fileName:name});
    };
  uploadFileClientServerAPI = async (currentFile: File) => {
    const data = new FormData();
    data.append( "file", currentFile);
    fetch(`${webConfigJSON.urlClientServer}/${webConfigJSON.uploadFileClientServer}`, { method: 'POST', body: data })
  }

  uploadSplitFileClientServerAPI = async (currentFile: File) => {
    const data = new FormData();
    data.append( "file", currentFile);
    fetch(`${webConfigJSON.urlClientServer}/${webConfigJSON.uploadSplitFileClientServer}`, { method: 'POST', body: data })
  }

  onCloseLeaveWorkspaceDialog = () => {
    this.setState({ isLeaveWorkspace: false })
  }

  onChooseFile = (file: FileInfo) => {
    const listName = file.filename.split('.')
    const extension = listName[listName.length - 1]
    this.setState({ isOpenPreviewDialog: true,
      dataFiles: {
        files: [{
          name: file.url,
          type: extension,
          size: 0,
          lastModified: -1,
        }],
        uploadChoice: "",
        workspace_id: 0
      }
    })
  }
  onOpenAddModal = () => {
    this.setState({ isAddModalOpen: true })
  }

  onCloseDialog = (data?: AddNewMemberToWorkspaceObject) => {
    this.setState({ isAddModalOpen: false })
    if (data) {
        this.addNewMemberToWorkspaceAPI(data)
    }
  }

  onOkLeaveWorkspaceDialog = () => {
    this.setState({ isLeaveWorkspace: false })
    this.leaveWorkspaceAPI()
  }

  onOpenLeaveWorkspaceDialog = () => {
    this.setState({ isLeaveWorkspace: true })
    this.handleClose()
  }

  leaveWorkspaceAPI = async () => {
    // Customizable Area Start
    const token = await getStorageData("authToken")
    const workspaceId = this.props.navigation.getParam("id")
    const webHeader = {
      "Content-Type": webConfigJSON.apiContentType,
      token
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLeaveWorkspaceId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.leaveWorkspaceApiUrl}/${workspaceId}/leave_workspace`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.deleteApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  getFilesUploadedByWorkspaceIdAPI = async () => {
    const token = await getStorageData("authToken")
    const workspaceId = this.props.navigation.getParam("id")
    const webHeader = {
      "Content-Type": webConfigJSON.apiContentType,
      token
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filesUploadedByWorkspaceIdApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.filesUploadedByWorkspaceIdApiUrl}=${workspaceId}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

   getTotalUploadFile = async (uploadObject: FileUpload[] | undefined) => {
    if(uploadObject) {
      let totalFile = 0;
      await uploadObject.map((obj) => {
        if(obj.attributes.files.length >0) {
            totalFile = totalFile+obj.attributes.files.length;
        }
      })
      this.setState({totalUploadFile: totalFile});
    }
  }

  checkIsAdmin = () => {
    const userAdmin = this.props.listUser.find((item) => item.permission === "Admin")
    this.setState({ isAdmin: userAdmin?.account_id?.toString() === this.props.currentUserId }) 
    this.setState({currentworkspaceId: this.props.navigation.getParam("id")});
  }

  handleAllFiles = () => { 
    const workspaceId = this.props.navigation.getParam("id")
    this.props.navigation.navigate('GeneralTabAllFiles', { id: workspaceId },"/allInteractions");
  } 

  handleClose = () => {
    this.setState({anchorEl:null});
  };
  
  handleClick = (event:any) => {
    this.setState({anchorEl:event.currentTarget});
  };

  columns = [
    {
      id: 'created_at',
      label: 'Date/Time',
    },
    {
      id: 'description',
      label: 'Action',
      minWidth: 170,
      align:"left"
    },
    {
      id: 'action',
      label: '',
      align: 'right',
      format: () => {
        return(
          <IconButton>
          <MoreVertIcon />
        </IconButton>
        )
      }
    }
  ];

  handleChangePage = (event: any, newPage: number) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 1,
    });
  };

  onCloseRemoveDialog = () => {
    this.setState({ isDeleteWorkspace: false })
  }

  onOkCloseDialog = () => {
    this.setState({ isDeleteWorkspace: false })
    this.deleteWorkspaceAPI()
  }

  onOpenRemoveDialog = () => {
    this.setState({ isDeleteWorkspace: true })
    this.handleClose()
  }

  onOpenUpdateWorkspace = () => {
    this.setState({ isUpdateDialogOpen: true })
    this.handleClose()
  }

  updateWorkspaceAPI = async (dataNewWorkspace: UpdateWorkspaceObject) => {
    // Customizable Area Start
    const token = await getStorageData("authToken");
    const workspaceId = this.props.navigation.getParam("id");
    const webHeader = {
        "Content-Type": webConfigJSON.apiContentType,
        token
      };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateWorkspace = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.detailWorkspace}${workspaceId}/update_workspace`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.putApiMethod
    );

    webRequestMessage && webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataNewWorkspace)
    );

    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  onCloseUpdateDialog = (dataNewWorkspace?: UpdateWorkspaceObject) => {
    this.setState({ isUpdateDialogOpen: false });
    if (dataNewWorkspace) {
      this.updateWorkspaceAPI(dataNewWorkspace)
    }
  }

  generalTabUploadFile = async (data: { workspace_id: number; files: Array<File> }) => {
    // Customizable Area Start
    const token = await getStorageData("authToken")
    const workspaceId = this.props.navigation.getParam("id");

    const webHeader = {
      token
    };
      
    const createBulkFileUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFileApiId = createBulkFileUploadMsg.messageId;

    createBulkFileUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.uploadFileWorkspace
    );


    const formData = new FormData();
    formData.append("workspace_id", workspaceId.toString());
    data.files.forEach(item => {
      formData.append( "files[]", item);
    });
    createBulkFileUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    createBulkFileUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    createBulkFileUploadMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethod
    );

    runEngine.sendMessage(createBulkFileUploadMsg.id, createBulkFileUploadMsg);
    // Customizable Area End
    return true;
  }

  openUploadDialog = () => {
    this.setState({ isOpenUploadDialog: true })
  }

  onCloseUploadDialog = (data?: { workspace_id: number; files: Array<File>; uploadChoice: string }) => {
    this.setState({ isOpenUploadDialog: false })
    if (data) {
      if (data.uploadChoice === "Upload file with AI processing") {
        this.setState({ isOpenPreviewDialog: true, dataFiles: data })
      } else {
        this.generalTabUploadFile(data)
      }
    }
  }

  handleCloseAddMemberDialog = () => this.setState({ isSnackbarOpen: false, snackbarContent: "" })
  // Customizable Area End
}
