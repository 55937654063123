// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import createRequestMessage from "../../utilities/src/create-request-message";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";



export const configJSON = require("./config.js");

interface Data {
  [key: string]: any;
}
export interface AttachmentObject {
  id: string
  attributes: {
    id: number
    submitted_by: string
    workspace: string
    last_modified: string
    file: FileAttachment
  }
}

export type FileDataTableType = {
  id?: string | number;
  fileInfo: FileAttachment;
  dateAndTime: string;
  action?: {
    avarta: string,
    userName: string,
    actionDesc: string
};
}

export interface FileAttachment {
  file_name: string
  url: string
  file_size: number
}
  
export interface Props {
  classes?: Record<string, string>
  workspaceId: number
  currentUserId: string
}
interface S {
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean; 
  rows: Data[];
  isOpenUploadDialog: boolean;
  listAttachment?: FileDataTableType[];
  listFileTable: FileDataTableType[];
  anchorEl?:HTMLElement | null;
}
interface SS { 
 
  
}

export default class FileTabController extends BlockComponent<Props, S, SS> {
  
  getListAttachmentId: string = ""
  uploadFileId: string = ""
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      totalCandidates: "",
      errorMsg: "",
      token: "",
      rows: [] as Data[],
      loading: true,
      isOpenUploadDialog: false,
      listAttachment: [],
      listFileTable: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getListFileAttached();
  }

  async receive(from: string, message: Message) {
    
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getListAttachmentId) {
        if(responseJson?.error){
          this.setState({ loading: false, listAttachment: [
            {
              id: 1,
              fileInfo: {
                file_name: 'PPO382.doc',
                file_size: 1,
                url: '#'
              },
              dateAndTime: '08/13/24 14:14',
              action: {
                avarta: '',
                userName: 'Jane Cooper',
                actionDesc: ''          
              }
            },
            {
              id: 2,
              fileInfo: {
                file_name: 'PAG_382.pdf',
                file_size: 1,
                url: '#'
              },
              dateAndTime: '08/13/24 13:11',
              action: {
                avarta: '',
                userName: 'Jane Cooper',
                actionDesc: ''          
              }
            },
            {
              id: 3,
              fileInfo: {
                file_name: 'IIO382.mp3',
                file_size: 1,
                url: '#'
              },
              dateAndTime: '08/13/24 12:16',
              action: {
                avarta: '',
                userName: 'Jane Cooper',
                actionDesc: ''          
              }
            },
            {
              id: 4,
              fileInfo: {
                file_name: 'TRI382.mov',
                file_size: 1,
                url: '#'
              },
              dateAndTime: '08/13/24 12:46',
              action: {
                avarta: '',
                userName: 'Jane Cooper',
                actionDesc: ''          
              }
            },
          ] });
        } else {
          if (configJSON.message === "No attachment found") {
            this.setState({ loading: false, listAttachment: [] });
          } else {
            this.setState({ loading: false, listAttachment: responseJson.data });
          }
        }
      }
      if (apiRequestCallId === this.uploadFileId) {
        this.getListFileAttached()
      }
    }
  }

  getListFileAttached = async () => {
    this.setState({ loading: true })
    const token = await getStorageData("authToken")
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getListAttachmentId = request.messageId;
    createRequestMessage({
        token,
        requestMessage: request,
        method: "GET",
        endPoint: `${baseURL}/${configJSON.getFileList}`
    });
  }
  
  uploadFileAPI = async (data: { workspace_id: number; files: Array<File> }) => {
    const token = await getStorageData("authToken")

    const createBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFileId = createBulkUploadMsg.messageId;

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadFileWorkspace
    );

    const header = {
      token,
    }

    const formData = new FormData();
    formData.append("workspace_id", data.workspace_id.toString());
    data.files.forEach(item => {
      formData.append( "files[]", item);
    });
    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    createBulkUploadMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
  }

  handleClick = (event:any) => {
    this.setState({anchorEl:event.currentTarget});
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  openUploadDialog = () => {
    this.setState({ isOpenUploadDialog: true })
  }

  onCloseUploadDialog = (data?: { workspace_id: number; files: Array<File> }) => {
    this.setState({ isOpenUploadDialog: false })
    if (data) {
      this.uploadFileAPI(data)
    }
  }
  
}
// Customizable Area End