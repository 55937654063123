// Customizable Area Start
import React from "react";
import MuiTab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import QueryHubTabViewController from "./QueryHubTabViewController.web";
import { Box, Button, IconButton, Menu, MenuItem, Typography, } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ResultTab from "./ResultTab.web";
import JsonTab from "./JsonTab.web";
import HistoryTab from "./HistoryTab.web";
import { FileTab } from "./FileTab.web";
import ChatBoxCopilot from "./ChatBoxCopilot.web";
import { color } from "react-native-reanimated";
const configJSON = require("./config");
const publishIcon = require("./svg-icons/publish.svg");
const chatboxIcon = require("./svg-icons/chatbox.svg");
const downloadIcon = require("./svg-icons/download.svg");
const shareIcon = require("./svg-icons/share.svg");
const rerunIcon = require("./svg-icons/rerun.svg");

interface ChatBoxProps {
    onClose: () => void;
    title: string;
}

export class QueryHubTabView extends QueryHubTabViewController {
  renderTabsCompontents(tabIndex: number) {
    if (tabIndex === 0) {
        return (
            <ResultTab
                classes={this.props.classes} 
                currentUserId={this.props.currentUserId} 
                workspaceId={this.props.workspaceId}
            />
        );
    } else if (tabIndex === 1) {
        return (
            // <></>
            <JsonTab
                classes={this.props.classes} 
                currentUserId={this.props.currentUserId} 
                workspaceId={this.props.workspaceId}
            />
        )
    } else if (tabIndex === 2) {
        return (
            <FileTab 
                classes={this.props.classes} 
                currentUserId={this.props.currentUserId} 
                workspaceId={this.props.workspaceId}
            />
        )
    } else {
        return (
            <HistoryTab
                classes={this.props.classes} 
                currentUserId={this.props.currentUserId} 
                workspaceId={this.props.workspaceId}
                isReload ={false}
            />
        )
    }
  }

  handleCloseChatCopilot = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { view, isChatOpen, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const displayStyle = view === 0 || view === 1 ? 'block' : 'none';
    return (
        <div className={this.props.classes.mainContainer} style={{backgroundColor: '#fff'}}>
            <div className={this.props.classes.container} style={{display: "inline-block", backgroundColor: '#fff',}}>
                <div className={isChatOpen ? this.props.classes.contentLeftChanged: this.props.classes.contentLeftDefault}>
                    <div className={this.props.classes.headerView} style={{backgroundColor: '#E2E8F0', paddingRight: 0, height: '40px'}}>
                        <div className={this.props.classes.tabHeader}>
                            <Tabs
                                value={view}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                className={this.props.classes.tabQueryHub}
                                TabIndicatorProps={{
                                    style: { display: 'none' }
                                }}
                            >
                                <MuiTab
                                    className={view === 0 ? this.props.classes.chosenTab : this.props.classes.normalTab}
                                    label={configJSON.resultsTabTitle}
                                />
                                <MuiTab
                                    className={view === 1 ? this.props.classes.chosenTab : this.props.classes.normalTab}
                                    label={configJSON.jsonTabTitle}
                                />
                                <MuiTab
                                    className={view === 2 ? this.props.classes.chosenTab : this.props.classes.normalTab}
                                    label={configJSON.filesTabTitle}
                                />
                                <MuiTab
                                    className={view === 3 ? this.props.classes.chosenTab : this.props.classes.normalTab}
                                    label={configJSON.historyTabTitle}
                                />
                            </Tabs>
                            
                        </div>
                        <div style={{ display: displayStyle }}>
                            <Button
                                data-test-id="btnPublish"
                                className={this.props.classes.publishButton}
                                startIcon={<img src={publishIcon}/>}
                                style={{fontFamily: "DM Sans",}}
                            >
                                {configJSON.publishButtonTitle}
                            </Button>
                            <IconButton onClick={this.handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={this.handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                PaperProps={{
                                    style: {
                                        position: 'absolute',
                                        top: 165,
                                        transform: 'translateY(0)',
                                    },
                                }}
                            >
                                <MenuItem onClick={this.handleClose} style={{fontFamily: "DM Sans",
                                    cursor: "pointer",
                                }}>
                                    <IconButton>
                                        <img src={downloadIcon}/>
                                    </IconButton>
                                    Download
                                </MenuItem>
                                <MenuItem onClick={this.handleClose} style={{fontFamily: "DM Sans",cursor: "pointer"}}>
                                    <IconButton>
                                        <img src={shareIcon}/>
                                    </IconButton>
                                    Share</MenuItem>
                                <MenuItem onClick={this.handleClose} style={{fontFamily: "DM Sans", cursor: "pointer"}}>
                                    <IconButton>
                                        <img src={rerunIcon}/>
                                    </IconButton>
                                    Rerun
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                    {this.renderTabsCompontents(view)}
                </div>
                <div className={isChatOpen ? this.props.classes.contentRightChange: this.props.classes.contentRightDefault}>
                    <div className={this.state.isChatOpen? this.props.classes.chatboxOpen : this.props.classes.chatboxClose}>
                        <div>
                        <Box
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '8px',
                                position: 'relative',
                                backgroundColor: '#fff',
                            }}
                            >
                                <Box
                                    style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: '77px',
                                    backgroundColor: '#D9D1EF',
                                    padding: '8px 16px',
                                    
                                    }}
                                >
                                    <Typography style={{fontSize: '18px', fontFamily: 'DM Sans', fontWeight: 700}}>Copilot</Typography>
                                    <IconButton
                                        onClick={this.toggleChat}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                    <CloseIcon style={{color: "black"}}/>
                                    </IconButton>
                                </Box>

                                <ChatBoxCopilot 
                                    classes={this.props.classes} 
                                    currentUserId={this.props.currentUserId} 
                                    workspaceId={this.props.workspaceId}
                                    isReload ={false}
                                />
                            </Box>
                        </div>
                    </div>
                </div>

                <div className={isChatOpen ? this.props.classes.chatboxIconClosed : this.props.classes.chatboxIconOpen} 
                    onClick={this.toggleChat}>

                    <Button
                        data-test-id="btnChatbox"
                        className={this.props.classes.chatboxSymbol}
                        startIcon={<img src={chatboxIcon}/>}
                    >
                    </Button>
                </div>
            </div>
        </div>
    );
  }
}

const useStylesQueryHub: Record<string, CSSProperties> = {
    
    tabQueryHub: {
        backgroundColor: "#fff",
        padding: "6px",
        borderRadius: "12px",
        minHeight: "unset",
    },
    chosenTab: {
        backgroundColor: "#EDEAFF",
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '21px',
        padding: "4px 12px",
        color: "#684EF3",
        borderRadius: "6px",
        border: "1px solid #D9D1EF",
        textTransform: "capitalize",
        minWidth: "unset",
        minHeight: "unset",
    },
    publishButton: {
        color: 'rgba(255, 255, 255, 1)', 
        backgroundColor: '#684EF3', 
        fontSize: '16px', 
        fontWeight: 400, 
        borderRadius: '8px', 
        gap: '8px', 
        textTransform: 'none',
        width: "103px",
        height: "36px",
        padding: "6px 10px",
        '&:hover': {
            backgroundColor: '#684EF3',
            boxShadow: 'none', 
        },
    },
    chatboxIconOpen: {
        position: "fixed",
        bottom: "20px",
        right: "40px",
        color: "white",
        cursor: "pointer",
        zIndex: 1000,
    } ,
    chatboxIconClosed: {
        display: 'none',
    },
    contentLeftDefault: {
        width: "100%",
        height:"100%"
    },
    contentLeftChanged: {
        width: "70%",
        height: "100%",
        float: "left",
    },
    contentRightDefault: {
        width: 0,
        height: "100%",
        overflow: "hidden",
    },
    contentRightChange: {
        width: "30%",
        height: "88.1%",
        float: "left",
        zIndex: 999,
    },
    chatboxSymbol: {
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        minWidth: 0,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    } ,

};

export default withStyles(useStylesQueryHub)(QueryHubTabView);
// Customizable Area End
