Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.getListWorkspace = "bx_block_bulk_uploading/list_and_search_workspace?search_workspace="
exports.getAIOptionList = "bx_block_workspace/get_extract_titles"
exports.createAttachment = "bx_block_bulk_uploading/attachments"
exports.getAttachment = "bx_block_bulk_uploading/attachments"
exports.uploadFileClientServer = "upload_simple"
exports.uploadSplitFileClientServer = "upload_and_split"
exports.urlClientServer = ""
exports.buildReport = "/build_final_report"
exports.bulkUploading = "bx_block_bulk_uploading/attachments"

exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body"; 
exports.allFilesText = "All Files"

exports.continueText = "Continue"
exports.browseText = "Browse"
exports.search = "Search"
exports.dashboard = "Dashboard"
exports.dragText = "Drag and drop your files or"
exports.uploadFilesText = "Upload files"
exports.replaceFilesText = "Replace file"
exports.removeFilesText = "Remove file"
exports.purposeTitleText = "Is there an action required to apply to this file?"
exports.workspaceChoice = "Which workspace shall this be uploaded to?"
exports.deleteFiles = "Are you sure you want to delete?"
exports.workspacePlaceholder = "Choose a workspace"
exports.processTextHeader = "Processing files"
exports.cancelUploadText = "Cancel upload"
exports.cancelDescription = "Please confirm you want to cancel files processing and uploading process. Files will not be uploaded into workspace and your account."
exports.cancelTitle = "Are you sure you want to cancel?"
exports.deleteFliesDescription1="<p>Please confirm you want to delete file  "
exports.deleteFilesDescription2 = "<p> File will be not possible to restore.</p>"
exports.backToUpload = "Back to uploading"
exports.confirmCancel = "Confirm cancellation"
exports.cancelDelete = "Cancel"
exports.confirmDelete = "Delete Files"
// Customizable Area End