import React from "react";

// Customizable Area Start
import {  
  createTheme,
  ThemeProvider,
 Box,  Typography, 
  Divider, styled
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from "moment";
import { logoText } from "../../email-account-login/src/assets";
import { downloadIcon } from "./assets";
const theme = createTheme({
  overrides: {
    MuiDialogContent: {
      root: {
        padding: '0px',
      },
    },
    MuiDialog: {
      paper: {
        width: "1376px",
      },
      paperWidthSm: {
        maxWidth: "1376px",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "26px",
      }
    }
  },
});
// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start  
    return (
      <ThemeProvider theme={theme}>
          <Box style={styles.dialogContent as React.CSSProperties}>
          <Box style={styles.header as React.CSSProperties}>
          <img style={styles.headerText as React.CSSProperties} src={logoText} />
          <Box style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <Typography data-test-id="backtosignuptext" style={styles.backtosignup as React.CSSProperties} onClick={()=>this.backToSignup()}>Close and back to Sign Up</Typography>
              <CloseIcon data-test-id="backtosignupIcon"  style={styles.iconClose as React.CSSProperties} onClick={()=>this.backToSignup()} />
          </Box>
        </Box>
            <Divider />
            <Box style={styles.body as React.CSSProperties}>
              <div style={styles.leftSide as React.CSSProperties}>
                <StyledPrivacyBox data-test-id="PrivacyPolicyBtn" onClick={this.handlePrivacy}>
                  <Box>
                    <Typography style={styles.privacyPolicy as React.CSSProperties}>Privacy Policy</Typography>
                  </Box>
                  <Box>
                    {this.state.showIcon ? (<ArrowForwardIosIcon style={{ fontSize: "1rem" }as React.CSSProperties} />) : (<KeyboardArrowUpIcon style={{ fontSize: "1.5rem" }as React.CSSProperties} onClick={this.handlePrivacy} />)}
                  </Box>
                </StyledPrivacyBox>
                
                {this.state.isOpen && this.state.sections.map((section:any, index:any) => (
          <StyledTerms key={index} 
          onClick={() => this.handleScroll(section.id)}>
              {`${index + 1}. ${section.header}`}
            <Divider />          
          </StyledTerms>
        ))}
                <StyledTermsBox data-test-id="TermsandconditionBtn" onClick={this.handleTearms}>
                  <Box>
                    <Typography style={styles.privacyPolicy as React.CSSProperties}>Terms and Conditions</Typography>
                  </Box>
                  <Box>
                  {this.state.showIconTearms ? (<ArrowForwardIosIcon style={{ fontSize: "1rem" }as React.CSSProperties} />) : (<KeyboardArrowUpIcon style={{ fontSize: "1.5rem" }as React.CSSProperties} onClick={this.handleTearms}/>)}
                  </Box>
                </StyledTermsBox>
                {this.state.isTearmsOpen && this.state.sections.map((section:any, index:any) => (
          <StyledTerms data-test-id={`header-${index}`}key={index} 
          onClick={() => this.handleScroll(section.id)}>
              {`${index + 1}. ${section.header}`}
            <Divider />          
          </StyledTerms>
        ))}
              </div>
              <Box style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "1rem",width:"90%" }as React.CSSProperties}>
              
                
                <Box style={styles.rightSide as React.CSSProperties}>
                <Box>
                  <Box style={{ display: "flex", gap: "12px" , justifyContent: 'space-between'}as React.CSSProperties}>
                    <div style={{display:"flex",flexDirection:"column"}as React.CSSProperties}>
                    <Typography style={styles.heading as React.CSSProperties}>{this.state.name}</Typography>
                    <Typography style={styles.revisedTime as React.CSSProperties}>Last Revised: {moment(this.state.reviseddate).format("MMM DD, YYYY")}</Typography>
                    </div>
                    <img data-test-id="downloadIcon"
  style={styles.downloadIcon as React.CSSProperties}
  src={downloadIcon}
  alt="Download"
  onClick={() => {
    const url = this.state.pdfURL;
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }}
/>

                  </Box>
                </Box>


                <Typography id="dialog-description">
          {this.state.sections.map((section:any) => (
            <div key={section.id} id={section.id}>
              <h3 style={styles.headerspt as React.CSSProperties}>{section.header}</h3>
              <div style={styles.parapt as React.CSSProperties}  dangerouslySetInnerHTML={{ __html: section.content }} />
            </div>
          ))}
        </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
    
      </ThemeProvider>
    )
    // Customizable Area End
  }
}

// Customizable Area Start



const StyledPrivacyBox = styled(Box)({
  width: '330px',
  padding: "12px",
  background: '#F6F5F9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
  borderRadius: "12px",
  gap: "8px",
  cursor: "pointer",
  '&:hover': {
    background: "lightblue"
  }
});

const StyledTermsBox = styled(Box)({
  width: '330px',
  marginTop: "8px",
  padding: "12px",
  backgroundColor: '#F6F5F9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
  borderRadius: "12px",
  gap: "8px",
  cursor: "pointer",
  '&:hover': {
    background: "lightblue"
  }
})

const StyledTerms = styled(Typography)({
  fontWeight: 400,
  fontFamily: "DM Sans",
  fontSize: "16px",
  lineHeight: "24px",
  width:"320px",
  padding: "8px 20px",
  color: "#30353B"
})


const styles = {
  iconClose: {
    marginRight: '36px',
    height: "20px",
    width: "20px",
    padding: "10.39px 10.39px",
    color:"#684EF3"
  },

  headerText: {
    padding: "24px 0px 28px 42px",
    width: '141.05px',
    height: '28px', 
    gap: '2.8px '
  },
  downloadIcon: {
    padding: "0px 0px 28px 42px",
    width: '44px',
    height: '44px', 
    gap: '2.8px ',
    cursor:"pointer"
  },

  privacyPolicy: {
    fontWeight: "bold",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#30353B",
    lineHeight: "24px"
  },
  backtosignup: {
    fontWeight: "400",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#684EF3",
    lineHeight: "24px",
    cursor:"pointer"
  },
  heading: {
    fontWeight: "700",
    fontFamily: "DM Sans",
    fontSize: "20px",
    color: "#30353B",
    lineHeight: "30px"
  },
  revisedTime: {
    fontWeight: "400",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#5E6671",
    lineHeight: "24px"
  },
  headerspt: {
    fontWeight: "700",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#30353B",
    lineHeight: "24px"
  },
  parapt: {
    fontWeight: "400",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#30353B",
    lineHeight: "24px"
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    // maxWidth: "1376px",
    borderRadius: 24,
    width: "100%",
    backgroundColor: "#fff",
    border: '1px #E2E8F0',
    height: '100%',
    boxShadow: "5px",
    '@media (max-width: 600px)': {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      borderRadius: 0,
      padding: '16px',
    }
  },
  modal: {
    display: 'flex',
    padding: "8px",
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: "1376px",
    borderRadius: 24,
    width: "100%",
    backgroundColor: "#fff",
    border: '1px #E2E8F0',
    boxShadow: "5px",
    padding: "16px 32px 24px",
    '@media (max-width: 600px)': {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      borderRadius: 0,
      padding: '16px',
    }
  },
  header: {
    width: '100%',
    // height: '80px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position:"sticky"
  },
  body: {
    width: '100%',
    height: 'calc(100% - 80px)',
    display: 'flex',
    // gap: "32px",  
    padding: "0px 32px !important",
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      padding: '0px 16px',
    }
  },
  leftSide: {
    width: '343px',
    position:"sticky",
    height: '100%',
    padding: '24px 32px 0px 32px',
    borderRight: '1px solid #E2E8F0',
    '@media (max-width: 600px)': {
      width: '100%',
      borderRight: 'none',
      borderBottom: '1px solid #E2E8F0',
    }
  },
  rightSide: {
    width: '93%',
    height: '65vh',
    padding: '24px 32px',
    '@media (max-width: 600px)': {
      width: '100%',
    }
  },
};
// Customizable Area End
