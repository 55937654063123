import React from "react";
// Customizable Area Start
import {
    Box, Typography, AppBar, Toolbar, InputBase, createTheme, ThemeProvider,
    Breadcrumbs,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    styled,
    IconButton,
    Snackbar,
    ListItemText,
    ListItemIcon,
    MenuItem,
    Menu
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ConfirmationDialog from '../../../../packages/blocks/accountgroups/src/ConfirmationDialog.web';
import { deleteIcon } from "../../accountgroups/src/assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { CustomSeparator } from "../../../../packages/components/src/DashboardComponent/TableComponent.web";

const theme = createTheme({
    palette: {
        primary: {
            main: '#E2E8F0',
        },
    },
    shape: {
        borderRadius: 16,
    },
});
export const headTheme = createTheme({
    overrides: {
        MuiTableCell: {
            alignRight: {
                textAlign: "start"
            },
            root: {
                padding: "16px 16px 15px 24px"
            },
        }, 
    }
});
const StyledPagination = styled(Pagination)({
    color: '#684EF3',
    fontFamily: 'DM Sans',
    fontWeight: 400,
    fontSize:"12px",
    padding:"20px",
    '& .Mui-selected': {
      color: '#684EF3',
      fontWeight: 700,
    },
  });
const cellThemes = createTheme({
    overrides: {
        MuiAvatar: {
            root: {
                borderRadius: '0px',
            },
        },
        MuiTableCell: {
            alignRight: {
                textAlign: "start"
            },
            root: {
                padding: "15px 16px 12px 28px"
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "16px "
            }, 
            elevation1:{
                boxShadow:"0px"
            }
        }, 
    },
})
import { Alert } from "@material-ui/lab";

export const paginationWithResults = (data: Data[], page: number,itemsPerPage: number, handleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void) => {
    const totalResults = data.length;
    const totalPages = Math.ceil(totalResults / itemsPerPage); 
    const startIndex = (page - 1) * itemsPerPage + 1;
    const endIndex = Math.min(page * itemsPerPage, totalResults);
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box flexGrow={1} />
        <StyledPagination
          data-test-id="table"
          count={totalPages}
          page={page}
          onChange={handleChangePage}
        />
        <Box flexGrow={1} textAlign="right" style={{paddingRight:"20px",color:"#5E6671",fontFamily:"DM Sans",fontSize:"12px",fontWeight:400}}>
          <Typography variant="body2">{`${startIndex} - ${endIndex} of ${totalResults} results`}</Typography>
        </Box>
      </Box>
    );
  };
// Customizable Area End
import DashboardAllFilesController, {
    Data,
    Props,
    webConfigJSON
} from "./DashboardAllFilesController.web";

export default class DashboardAllFiles extends DashboardAllFilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start 
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { page, rowsPerPage, data } = this.state;
        const totalResults = data.length;
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = Math.min(page * rowsPerPage, totalResults);
        const currentData = data.slice(startIndex, endIndex);
        return (
            <>
                <div style={{flexDirection: "row", height: "100vh",backgroundColor: "#E2E8F0",display: "flex", }}>
                    <div style={{ position: "sticky" }}>
                        <NavigationMenu navigation={this.props.navigation} id="dashboard" />
                    </div>
                    <div style={{width: "100%" ,overflowY: "auto"}}>
                        <div style={{ flexGrow: 1, }}>
                            <ThemeProvider theme={theme}>
                                <AppBar position="static">
                                    <Toolbar style={{ margin: "0px 16px" }}>
                                        <Breadcrumbs separator={<CustomSeparator />} style={webStyles.titles}>
                                            <Typography style={webStyles.dashboardText} onClick={this.goToDashboard} data-test-id="btnAllFiles">Dashboard</Typography>
                                            <Typography style={webStyles.allFiles}>{webConfigJSON.allFilesText}</Typography>
                                        </Breadcrumbs>
                                        <div style={webStyles.searchs}>
                                            <div style={webStyles.searchIcons}>
                                                <SearchIcon style={webStyles.searchIcon} />
                                            </div>
                                            <InputBase
                                                placeholder="Search…"
                                                style={{
                                                    transition: 'width 0.3s ease',
                                                    width: '100%',
                                                    fontSize: "16px",
                                                    fontFamily: "DM sans",
                                                    padding: '8px 8px 8px 0',
                                                    paddingLeft: 'calc(1em + 32px)',
                                                }}
                                                inputProps={{ 'aria-label': 'search' }}
                                            />
                                        </div>
                                        <NotificationsNoneIcon style={webStyles.notification} />
                                    </Toolbar>
                                </AppBar>
                            </ThemeProvider>
                            <Box style={webStyles.tableContainers}>
                                <Box style={webStyles.containerBoxs}>
                                    <Typography style={webStyles.filesCss}>All Files</Typography>
                                </Box>
                                <ThemeProvider theme={cellThemes}>
                                    <TableContainer style={{marginBottom:"24px"}} component={Paper}>
                                        <Table>
                                            <ThemeProvider theme={headTheme}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={webStyles.cellNaming}>File Name</TableCell>
                                                        <TableCell style={webStyles.cellNaming}>Submitted by</TableCell>
                                                        <TableCell style={webStyles.cellNaming}>Workspace</TableCell>
                                                        <TableCell style={webStyles.cellNaming}>File size</TableCell>
                                                        <TableCell style={webStyles.cellNaming}>Last modified</TableCell>
                                                        <TableCell style={webStyles.cellNaming}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            </ThemeProvider>
                                            <TableBody>
                                                {currentData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Box display="flex" alignItems="center">
                                                                <div style={webStyles.typeText}>{row.type.toUpperCase()}</div>
                                                                <span style={{
                                                                    marginLeft: "12px" , 
                                                                    color: "#14101E",
                                                                    fontSize: "14px",
                                                                    lineHeight: "21px",
                                                                    fontWeight: 700,
                                                                    fontFamily: "DM Sans"
                                                                }}>{row.fileName}</span>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell style={webStyles.fileSubmit}>{row.submittedBy}</TableCell>
                                                        <TableCell style={webStyles.fileSubmit}>{row.workspace}</TableCell>
                                                        <ThemeProvider theme={cellThemes}>
                                                            <TableCell style={webStyles.fileSubmit}>{row.size}</TableCell>
                                                            <TableCell style={webStyles.fileSubmit}>{row.dateModified}</TableCell>
                                                        </ThemeProvider>
                                                        <TableCell style={webStyles.fileSubmit}>
                                                        <IconButton
          data-test-id={`member-${row.id}`}
          onClick={(event) => this.handleClick1(event, row.id, row.filename)}
        >
          <MoreVertIcon />
        </IconButton>
        </TableCell>
        <Menu
      anchorEl={
        this.state.anchorEl2} open={
            Boolean(this.state.anchorEl2)}
      onClose={
        this.handleClose2} PaperProps={{
        elevation: 0,
        style: {
          boxShadow: 'none',border: "1px solid #E2E8F0"
        }
      }}
    >
          <MenuItem onClick={
            this.confirmdeleteFiles}>
            <ListItemIcon>
              <img
                src={
                    deleteIcon}
                alt="Remove File"
                style={{ height: "20px", 
                width: "18px" } as React.CSSProperties}
              />
            </ListItemIcon>
            <ListItemText
              primary="Remove File"
              style={{ color: 
                "#DC2626", fontFamily: 
                'DM Sans', fontWeight: 400,
                 fontSize: "14px"
                 } as React.CSSProperties}
            />
          </MenuItem>
    </Menu>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {paginationWithResults(data, page, rowsPerPage, this.handleChangePage)}
                                    </TableContainer>
                                </ThemeProvider>
                            </Box>
                        </div>

                    </div>
                    <Snackbar
            anchorOrigin={{ vertical: 'top',
             horizontal: 'center' }}
            open={
                this.state.isSnackbarOpen}
            autoHideDuration={3000}
            onClose=
            {this.handleClose}
          ><Alert
                  icon={
                    <svg 
                    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path 
                      d="M15.4933 7.28613L11.9575 10.8211L8.42247 7.28613L7.24414 8.46447L10.7791 11.9995L7.24414 15.5345L8.42247 16.7128L11.9575 13.1778L15.4933 16.7128L16.6716 15.5345L13.1366 11.9995L16.6716 8.46447L15.4933 7.28613Z" fill="white"/>
                    </svg>
                  }
                  style={
                    webStyles.viewAlert}
              >
                  {
                  this.state.snackbarContent}
              </Alert>
          </Snackbar>
                    <ConfirmationDialog
                        id="ConfirmationDialog"
                        isDialogOpen={
                            this.state.isOpenDeleteFiles} onCloseDialog={this.onCloseCancelDialog}
                        title={webConfigJSON.deleteFiles}
                        description={webConfigJSON.deleteFliesDescription1
                             + "<span style='font-weight: bold'>"+this.state.fileName+"</span>" +
                              webConfigJSON.deleteFilesDescription2}
                        titleCancelButton={webConfigJSON.cancelDelete}
                        titleOkButton={
                            webConfigJSON.confirmDelete}
                        onOkCloseDialog={
                            this.onCloseAll}
                        checkConfirmationTitle=""
                    />
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    viewAlert: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        padding: "8px",
        borderRadius: "16px",
        color: "#fff",
        backgroundColor: "#33BC55"
      },
    typeText: {
        width: "40px",
        lineHeight: "40px",
        borderRadius: "4px",
        textAlign: "center",
        backgroundColor: "#684EF3",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "DM Sans"
    } as React.CSSProperties,
    searchIcon:{
        width: "18.71px",
         height: "18.71px" 
    },
    allFiles: {
        color: "#30353B",
        fontWeight: 500,
        lineHeight: "21px"
    },
    dashboardText: {
        color: "#5E6671",
        fontWeight: 400,
        lineHeight: "21px",
        cursor: "pointer"
    },
    cellNaming: {
        color: "#5E6671",
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: 700,
        fontFamily: "DM Sans"
    },
    notification: {
        padding: "10px 10px 10px 21px",
        height: "20px",
        width: "18px"
    },
    seeAll: {
        color: "#684EF3",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        fontFamily: "DM Sans"
    }, 
    filesCss: {
        color: "#14101E",
        lineHeight: "33.6px",
        fontWeight: 400,
        fontSize: "24px",
        fontFamily: "DM Sans"
    },
    fileImg: {
        width: "89px",
        padding: "6.52px 50.13",
        height: "107px",
    },
    containerBoxs: {
        margin: "20px 0px",  
        display: "flex",
        justifyContent: "space-between",
    },
    fileSubmit: {
        fontWeight: 400,
        fontFamily: "DM sans",
        fontSize: "14px",
        lineHeight: "21px", 
        color:"#30353B"
    },
    uploadFiles: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        gap: "20px"
    },
    tableContainers: {
        height: "100%",
        flex: "1",
        margin: "0px 40px",
        maxHeight: "447px",
    },
    pdfContainer: {
        gap: "20px",
        display: "flex",
        maxHeight: "272px",
        height: "100%",
        margin: "24px 40px 26px 40px"
    },
    titles: {
        flexGrow: '1',
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        '@media (min-width: 600px)': {
            display: 'block',
        },
    },
    searchs: {
        position: 'relative',
        borderRadius: '8px',
        maxHeight: "44px",
        height: "100%",
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
        marginLeft: 0,
        width: '100%',
        maxWidth: "304px",
        '@media (min-width: 600px)': {
            marginLeft: '8px',
            width: 'auto',
        },
    } as const,
    searchIcons: {
        padding: "0px 16px",
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    } as const,
    nameFiles: {
        marginLeft: "6px",
        lineHeight: "21px",
        fontWeight: "bold",
        fontFamily: "DM sans",
        fontSize: "14px",
    },
};
// Customizable Area End
