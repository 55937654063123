import React from "react";

// Customizable Area Start
import { Container, TextField, Button,  Typography, Box, Snackbar, IconButton} from '@material-ui/core';
import { ThemeProvider, createTheme} from '@material-ui/core/styles';
import { logoText, backgroundImage,ForgetButtonLock,emailImg } from './assets';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
const theme = createTheme();
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <div style={{
        background: `url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover',width: '100%',
        height: '1000px',paddingBottom: '52px'
      }}>
      <div 
      style={{ paddingTop: '48px', paddingLeft: '80px' }}>
        <img 
        style={{ width: '141.05px', height: '28px', gap: '2.8px ' }} src={logoText} />
      </div>
      <div 
      style={{ display: 'flex', justifyContent: 'center' }}>
        <ThemeProvider 
        theme={theme}>
          <Container 
          maxWidth="sm" style={{ margin: '92px 0 100px 0' }}>
            <Box 
            style={{  height: '620px', width: '464px' }} display="flex" flexDirection="column" alignItems="center"  >
             { !this.state.isButtonClicked ?(  
                <>
            <img style={{ width: '72px', height: '72px', gap: '2.8px ' }} src={ForgetButtonLock} />
            <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '32px', fontWeight: 500,alignContent:"center",lineHeight:"41.6px",color:"#14101E" }} > 
                   Forgot your password?
                  </Typography>

            <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400,textAlign:"center",lineHeight:"28px",color:"#30353B" }} > 
            Enter the email you used to create the account and we will send you instructions to reset it.
                  </Typography>
                  <Box style={{ width: '100%',marginTop:"20px" }}>
                  <Typography style={{ fontFamily: 'DM Sans', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }}>Email</Typography>
                  <TextField
                    data-test-id="txtinputemailAddress"
                    style={{
                      borderRadius: '8px',
                      border: '1px  #E2E8F0',
                      backgroundColor: 'rgba(255, 255, 255, 1)',

                    }}
                    placeholder="Enter your email"
                    name="email"
                    value={this.state.emailValue}
                    onChange={this.handleChange}
                    variant="outlined" fullWidth  required
                    error={this.state.emailError}
                  />
                 {this.state.emailError && this.state.emailValue ? (
          <Box sx={{ marginTop:"5px",color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}>          
            <Typography style={{ fontFamily: 'DM Sans', fontSize: '12px'}}>Please enter a valid email address or This email not registered within EmergeGen</Typography>
          </Box>
        ) : null}

                </Box>

  

                  <Button
                    data-test-id="btnsendRecoveryemail"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop:"50px",color: 'rgba(255, 255, 255, 1)',  backgroundColor: this.state.isButtonDisabled ? '#94A3B8' : '#684EF3', fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px' ,textTransform: 'none' }}
                    onClick={this.handleSendRecoveryLink}
                    disabled={this.state.isButtonDisabled}
                  >
                    Send a recovery link
                  </Button></>):( <>
            <img style={{ width: '72px', height: '72px', gap: '2.8px ' }} src={emailImg} />
            <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '32px', fontWeight: 500,alignContent:"center",lineHeight:"41.6px",color:"#14101E" }} > 
            Check your email
                  </Typography>

            <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400,textAlign:"center",lineHeight:"28px",color:"#30353B" }} > 
            We have sent an email with the password reset link to
                  </Typography>
                  <Typography variant="body2" style={{ marginTop:"5px",fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 700,textAlign:"center",lineHeight:"28px",color:"#30353B" }} > 
            {this.state.emailValue}
                  </Typography>
            
                  <Typography variant="body2" style={{marginTop: '50px' , fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400,marginBottom:"130px" }} > 
                    <span data-test-id="btnresendEmail" style={{ left:"10px", textDecoration: 'none', color: 'rgba(104, 78, 243, 1)', cursor:"pointer" }} onClick={this.handleReSendRecoveryLink} >Resend email</span>
                  </Typography></>)}
                 
                  <Box style={{ marginTop: '320px' }}>
                  <Typography  variant="body2" style={{ fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400 }} > 
                    <span data-test-id="btngotologin" style={{ left:"10px", textDecoration: 'none', color: 'rgba(104, 78, 243, 1)', cursor:"pointer" }} onClick={this.goToLogin}>Back to Log In</span>
                  </Typography>
                </Box>
              </Box>
              <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={this.state.isresendClicked}
          onClose={this.handleClose}
          key={this.state.vertical + this.state.horizontal}
        >
          <SnackbarContent
            message="Email has been resent"
            style={{ backgroundColor: '#33BC55',borderRadius:"8px" }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
            </Container>
          </ThemeProvider>
        </div>
      </div>
      // Customizable Area End
    );
  }

}

