import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConditions {
  id: string;
  attributes: {
    created_at: string;
    description: string;
    accepted_by: [
      {
        account_id: string;
        accepted_on: string;
        email: string;
      }
    ];
  };
}
interface Section {
  id: string;
  header: string;
  content: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  termsCondsId: string;
  termsConds: ITermsConditions | null;
  termsConditions:any;
  name:string;
  reviseddate:any;
  pdfURL:string;
  isLoading: boolean;
  isOpen: boolean;
  isTearmsOpen: boolean;
  showIcon: boolean;
  showIconTearms:boolean;
  isActive: boolean;
  sections: Section[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPrivacyApiCallId:any;
  getTermsCondsCallId = '';
  // Customizable Area Ends

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      termsCondsId: "",
      termsConds: null,
      termsConditions:[],
      name:"Privacy Policy",
      pdfURL:"",
      isLoading: false,
      isOpen: false,
      isTearmsOpen:false,
      reviseddate:"",
      showIcon: true,
      showIconTearms:true,
      isActive: false,
      sections: []
   
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getPrivacy()
    this.getToken();
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  navigateToTermsCondsUsers = (termsConds: ITermsConditions) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsUsers"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsCondsUsers: termsConds.attributes?.accepted_by,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToTermsCondsEdit = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsEdit"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsConds: this.state.termsConds?.attributes?.description,
      termsCondsId: this.state.termsConds?.id,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  parseContent = () => {
    const { termsConditions } = this.state;
    const sections: Section[] = [];

    const regex = /<h3>(.*?)<\/h3>([\s\S]*?)(?=<h3>|$)/g;
    let match;
    let index = 0;
  
    while ((match = regex.exec(termsConditions)) !== null) {
      sections.push({
        id: `section-${index}`,
        header: match[1].trim(),  
        content: match[2].trim(),
      });
      index++;
    }

    this.setState({ sections });
  };
  
  



  handlePrivacy = () => {
   if(this.state.isOpen===true){
    this.setState({isOpen:false,showIcon:true})
   }else{
    this.setState({isOpen:true,showIcon:false})
   }
   this.getPrivacy()
  };

  backToSignup = () => {
    const messageType: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    messageType.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
    messageType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData),
     {});
    messageType.addData(getName(MessageEnum.NavigationRaiseMessage),
     raiseMessage);
    this.send(messageType);
  };

  handleTearms = () => {
    if(this.state.isTearmsOpen===true){
     this.setState({isTearmsOpen:false,showIconTearms:true})
    }else{
     this.setState({isTearmsOpen:true,showIconTearms:false})
    }
    this.getTearmsAndCondition()
  };
  

  getPrivacy = () => {
    this.setState({name:"Privacy Policy"})
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getTearmsAndCondition = () => {
    this.setState({name:"Terms and Conditions"})
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTearmsEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleScroll = (id:any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };


  
  getTermsCondsDetails = (token: string, termsCondsId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllTermsCondsApiEndPoint}/${termsCondsId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
        if (apiRequestCallId === this.getPrivacyApiCallId) {
          if (responseJson?.data) {
            this.setState({
              reviseddate: responseJson.data.attributes.created_at,
              pdfURL: responseJson.data.attributes.pdf_file.pdf_file_url,
              termsConditions: responseJson.data.attributes.pdf_file.description
            })
            this.parseContent()
            
          }
        }
      
    }

    // Customizable Area End
  }
}
