// Customizable Area Start
import React, { ReactNode } from "react";
import CreateWorkspaceDialogController, { UserResponse, configJSON } from "./CreateWorkspaceDialogController.web";
import { Dialog, DialogContent, TextField, FormControl, MenuItem, Avatar, InputAdornment, Popper, Box, Typography, Button, Paper, Grid } from "@material-ui/core";
import { SelectComponent } from "./WorkspaceList.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete, FilterOptionsState } from "@material-ui/lab";
import { CategoryObject } from "./WorkspaceListController.web";

export const filterOptions = (options: UserResponse[], state: FilterOptionsState<UserResponse>) => {
    let newOptions: UserResponse[] = [];
    options.forEach((element) => {
        const fullName = element.attributes?.first_name ?? "" + element.attributes?.last_name ?? ""
        if (fullName !== "" && fullName.toLowerCase().includes(state.inputValue.toLowerCase())) {
            newOptions.push(element)
        } else {
            if (element.attributes.email.toLowerCase().includes(state.inputValue.toLowerCase())) {
                newOptions.push(element)
            }
        }
    });
    return newOptions;
};

export class CreateWorkspaceDialog extends CreateWorkspaceDialogController {
    renderInput = (
        title: string,
        value: string,
        placeholder: string,
        onChangeValue: (value: string) => void,
        error?: string
    ) => {
        return (
            <div className={this.props.classes?.inputView}>
                <div className={this.props.classes?.titleText}>{title}</div>
                <TextField
                    className={this.props.classes?.inputText}
                    data-test-id={`inputText_${title}`}
                    value={value}
                    onChange={(event) => onChangeValue(event.target.value)}
                    placeholder={placeholder}
                    InputProps={{
                        disableUnderline: true,
                        className: this.props.classes?.searchStyle
                    }}
                />
                {
                    error !== "" && <span className={this.props.classes?.errorText}>{error}</span>
                }
            </div>
        )
    }

    getColorPlaceholder = (isPlaceholder: boolean) => {
        if (!isPlaceholder) return "#30353B"
        else return "#94A3B8" 
    }

    renderSelectComponent = (
        title: string,
        currentValue: string,
        placeholder: string,
        onChangeValue: (value: string) => void,
        listItem: Array<any>
    ) => {
        return (
            <div className={this.props.classes?.inputView}>
                <div className={this.props.classes?.titleText}>{title}</div>
                <FormControl variant="filled">
                    <SelectComponent
                        id="SelectComponent"
                        value={currentValue}
                        className={this.props.classes?.customdropdown}
                        IconComponent={() => (
                            <ExpandMoreIcon style={webStyles.iconExpand} />
                        )}
                        displayEmpty={true}
                        renderValue={value => (currentValue !== "" ? value : placeholder) as ReactNode}
                        style={{
                            color: this.getColorPlaceholder(currentValue === ""),
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                        {listItem.map((item: CategoryObject) =>
                            <MenuItem
                                className={this.props.classes?.dropdownitem}
                                key={item.id}
                                value={item.attributes.name}
                                data-test-id={`selectItem_${item.attributes.name}`}
                                onClick={() => onChangeValue(item.attributes.name)}
                            >
                                {item.attributes.name}
                            </MenuItem>
                        )}
                    </SelectComponent>
                </FormControl>
            </div>
        )
    }

    renderAutocompleteList = () => {
        return (
            <div className={this.props.classes?.inputView}>
                <div className={this.props.classes?.titleText}>{configJSON.addMemberTitle}</div>
                <TextField
              style={{
                borderRadius: '8px',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                marginRight: '16px',
                width: "100%",
                paddingBottom:"20px"
              }as React.CSSProperties}
              name='search'
              placeholder="Search member to add by email or name"
              data-test-id="inputName2"
              type='text'
              variant="outlined"
              value={this.state.searchText}
              onChange={(e)=>this.handleSearchChange(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ paddingLeft: "5px", color: "#94A3B8" }as React.CSSProperties} />
                  </InputAdornment>
                ),
                style: {
                  height: "100%",
                  padding: 0,
                  border: 'none',
                },
              }}
              inputRef={this.anchorRef}
            />
            <Popper
              open={this.state.listUser?.length > 0}
              anchorEl={this.anchorRef.current}
              style={{ zIndex: 99999 }as React.CSSProperties}
              placement="bottom-start"
            >
              <Box
                style={{
                  backgroundColor: '#fff',
                  maxHeight: "200px",
                  overflowY: 'auto',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  width: this.anchorRef.current ? this.anchorRef.current.offsetWidth : 'auto',
                  border: '1px solid #D6DEEA',
                  padding: '8px 8px',
                }as React.CSSProperties}
              >
                {this.state?.listUser?.map((result:any, index:any) => (
                  <MenuItem
                    key={index}
                    onClick={() => this.handleUserSelect(result)}
                    style={{
                      padding: '8px 16px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontFamily: 'DM Sans ',
                      fontSize: '14px',
                      color: 'rgba(48, 53, 59, 1)',
                    }as React.CSSProperties}
                  >
                    <Typography variant="body2" color="textPrimary">
                      {result.attributes.email}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Popper>
            <Box
            style={{
              marginTop: '16px',
              maxHeight: '130px',
              overflowY: 'auto',
              width: '100%',
              paddingBottom:"16px"
            }as React.CSSProperties}
          >
            <Grid container spacing={2}>
              {this.state.selectedUser.map((user:any, index:any) => (
                <Grid item xs={12} key={index}>
                  <Paper
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '6px',
                      backgroundColor: "#F6F5F9"
                    }as React.CSSProperties}
                  >
                    <Avatar alt={user.attributes.name} src={user.attributes.avatar} />
                    <Box style={{ marginLeft: '16px', flexGrow: 1 }as React.CSSProperties}>
                      <Typography
                        style={{
                          fontFamily: 'DM Sans',
                          fontWeight: 700,
                          fontSize: "14px"
                        }as React.CSSProperties}
                      >
                        {user.attributes.first_name} {user.attributes.last_name}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'DM Sans',
                          color: 'rgba(48, 53, 59, 1)',
                          fontWeight: 400,
                          fontSize: "14px"
                        }as React.CSSProperties}
                      >
                        {user.attributes.email}
                      </Typography>
                    </Box>
                    <Button
                      data-test-id="btnCloseGroup"
                      variant="contained"
                      style={{
                        color: '#000',
                        borderColor: '#fff',
                        backgroundColor: "#fff",
                        fontSize: '14px',
                        fontWeight: 400,
                        borderRadius: '8px',
                        gap: '8px',
                        padding: '16px',
                        textTransform: 'none',
                        width: "133px",
                        height: "24px",
                        fontFamily: 'DM Sans '
                      }as React.CSSProperties}
                      onClick={()=>this.removeUser(user.id)}
                    >
                      X Remove
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
                </div>
           
        )
    }

    renderUserOption = (user: UserResponse, showRemoveButton: boolean) => {
        return (
            <div key={`user_${user.id}`} className={this.props.classes?.optionAutocomplete}>
                <Avatar alt="avatar" src={user.attributes?.profile_photo || ""} className={this.props.classes?.optionAvaAutocomplete}>
                    {user.attributes?.first_name?.charAt(0)}
                </Avatar>
                <div className={this.props.classes?.viewContentOption}>
                    <p className={this.props.classes?.titleDropDownAutocomplete}>{`${user.attributes?.first_name ?? ""} ${user.attributes?.last_name ?? ""}`}</p>
                    <p className={this.props.classes?.descriptionDropDownAutocomplete}>{user.attributes?.email}</p>
                </div>
                {
                    showRemoveButton && (
                        <div data-test-id={`option_${user.id}`} className={this.props.classes?.removeButton} onClick={() => this.onRemoveUser(user)}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7946 4.37622L7.96598 7.20422L5.13798 4.37622L4.19531 5.31889L7.02331 8.14689L4.19531 10.9749L5.13798 11.9176L7.96598 9.08955L10.7946 11.9176L11.7373 10.9749L8.90931 8.14689L11.7373 5.31889L10.7946 4.37622Z" fill="#30353B"/>
                            </svg>
                            {configJSON.remove}
                        </div>
                    )
                }
            </div>
        )
    }

    renderViewButton = () => {
        return (
            <div className={this.props.classes?.viewButton}>
                <div data-test-id='closeCreateWorkspace' className={this.props.classes?.cancelButton} onClick={this.closeDialog}>{configJSON.cancel}</div>
                <div
                    data-test-id='createWorkspace'
                    className={this.props.classes?.createButton}
                    style={{ backgroundColor: this.getCurrentCreateColor() }}
                    onClick={this.createAndCloseDialog}
                >
                    {configJSON.create}
                </div>
            </div>
        )
    }

    render() {
        const { isDialogOpen, } = this.props
        return (
            <Dialog
                onClose={() => this.closeDialog()}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                    <div style={webStyles.textTitle}>{configJSON.createWorkspace}</div>
                    {this.renderInput(
                        configJSON.workspaceName,
                        this.state.name,
                        configJSON.createWorkspaceName,
                        this.onChangeName,
                        this.state.errorName
                    )}
                    {this.renderInput(
                        configJSON.descriptionTitle,
                        this.state.description,
                        configJSON.descriptionPlaceholder,
                        this.onChangeDescription
                    )}
                    {this.renderSelectComponent(
                        configJSON.category,
                        this.state.category,
                        configJSON.categoryPlaceholder,
                        this.onChangeCategory,
                        this.state.listCategory
                    )}
                    {this.renderSelectComponent(
                        configJSON.subCategory,
                        this.state.subCategory,
                        configJSON.subCategoryPlaceholder,
                        this.onChangeSubCategory,
                        this.state.listSubCategory
                    )}
                    {this.renderAutocompleteList()}
                    {this.renderViewButton()}
                </DialogContent>
            </Dialog>
        );
    }
}

const webStyles = {
    viewDialog: {
        borderRadius: '24px',
        border: '0px solid #D0D2DA',
        height: "85%",
        backgroundColor: "#fff"
    },
    textTitle: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "33.6px",
        position: "sticky",
        top: 0,
        padding: "24px 32px",
        zIndex: 2,
        borderBottom: '1px solid #F6F5F9',
        backgroundColor: "#fff"
    } as React.CSSProperties,
    iconExpand: { color: "#30353B" },
    textInput: {
        width: "100%",
        height: 48,
        color: "#30353B",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        padding: "0px 20px 0px 10px"
    }
}

export default CreateWorkspaceDialog;
// Customizable Area End