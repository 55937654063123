// Customizable Area Start
import React from "react";
import { Box, Button, IconButton, InputBase } from '@material-ui/core';
import ChatBoxCopilotController from "./ChatBoxCopilotController.web";

const chatRun = require("./svg-icons/chatRun.svg");
const chatAdd = require("./svg-icons/chatAdd.svg");
const chatReload = require("./svg-icons/chatReload.svg");
const chatSending = require("./svg-icons/chatSending.svg");

export class ChatBoxCopilot extends ChatBoxCopilotController {

    render() {
		const { inputText, messages } = this.state;
        return (
            <Box display="flex" flexDirection="column" width={"92%"} height={"calc(100vh - 236px)"} p={2} padding={"22px"} bgcolor={"#F4F3FB"}>
				<Box 
				  overflow="auto" 
				  border={1} 
				  borderColor="#E2E8F0" 
				  fontFamily={"DM Sans"}
				  fontSize={"16px"}
				  borderRadius={16} 
				  p={2} 
				  mb={2} 
				  bgcolor="white"
				  height={"65%"}
				>
				  	{messages.map((msg, index) => (
						<span style={{maxWidth: "80%"}}>
							<Box 
								key={index} 
								style={{ 
									margin: '5px 5px 5px 56px',
									textAlign: 'right',
									display: 'flex',
									justifyContent: 'flex-end'
								}}
							>
								<div className={"msgDataTest"+index}
									style={{
										width: "fit-content",
										padding: "10px",
										backgroundColor: "#F4F3FB",
										borderRadius:"12px", 
										fontFamily:"DM Sans", 
										fontWeight: 400,
										textAlign: 'left',
									}}
								>
									{msg}
								</div>
							</Box>
						</span>
					))}
				</Box>

				<Box display="flex" mb={2}>
				  <Button 
				  	style={chatBoxStyle.runButton}
					startIcon={<img src={chatRun}/>}
				  >Run</Button>
				  <Button 
				  	style={chatBoxStyle.addButton}
					startIcon={<img src={chatAdd}/>}
				  >Add</Button>
				  <div style={chatBoxStyle.reloadButton} onClick={this.sendMessage}>
					<IconButton size="small">
						<img src={chatReload} />
					</IconButton>
				  </div>
				  
				</Box>

				<div style={chatBoxStyle.typeText}>
					<InputBase
						data-test-id="typeMsgTestChange"
						className="dcmnhacm"
						fullWidth
						placeholder="Ask a question about your data. Use @ to find tables and columns."
						multiline
						style={{
							border: 'none',
							paddingTop: '10px',
							fontFamily: 'DM Sans',
							fontSize: '16px',
							color: "#94A3B8",
						}}
						value={inputText}
						onChange={this.handleInputChange}
            			onKeyPress={this.handleKeyPress}
					/>
					<IconButton className="sendMsgTest"  size="small" style={{paddingTop: '10px'}} onClick={this.sendMessage}>
						<img src={chatSending} />
					</IconButton>
				</div>
			</Box>
        );
    }
}

const chatBoxStyle = {
	runButton: {
        color: 'white', 
        backgroundColor: '#684EF3', 
        fontSize: '16px', 
        fontWeight: 400,
		fontFamily: 'DM Sans', 
        borderRadius: '8px', 
        gap: '8px', 
        textTransform: 'none',
        width: "76px",
        height: "32px",
        padding: "6px 10px",
        '&:hover': {
            backgroundColor: '#684EF3',
            boxShadow: 'none', 
        } ,
    } as React.CSSProperties,
	addButton: {
        color: '#684EF3', 
        backgroundColor: 'white', 
        fontSize: '16px', 
        fontWeight: 400,
		fontFamily: 'DM Sans',
		border: '1px solid #684EF3', 
        borderRadius: '8px', 
        gap: '8px', 
        textTransform: 'none',
        width: "76px",
        height: "32px",
        padding: "6px 10px",
		marginLeft: "16px",
		marginRight: "16px",
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: 'none', 
        } ,
    } as React.CSSProperties,
	reloadButton: {
        color: '#684EF3', 
        backgroundColor: 'white', 
        fontSize: '16px', 
        fontWeight: 400,
		fontFamily: 'DM Sans', 
		border: '1px solid #684EF3', 
        borderRadius: '8px', 
        textTransform: 'none',
        width: "30px",
        height: "30px",
		cursor: 'pointer',
    } as React.CSSProperties,
	typeText : {
		display: 'flex', 
		alignItems: 'start', 
		padding: '0 8px', 
		height: '88px',
		borderRadius: '16px',
		fontFamily: 'DM Sans',
		backgroundColor: 'white',
		fontSize: '16px',
		color: '#94A3B8',
	} as React.CSSProperties,
}

export default ChatBoxCopilot;
// Customizable Area End
