// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import createRequestMessage from "../../utilities/src/create-request-message";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config.js");

interface DataItem {
    Index: number;
    "Data Category": string;
    Description: string;
    "Amount(USD)": string;
}

export type Props = {
    classes?: Record<string, string>
    workspaceId: number
    currentUserId: string
}

interface S {
    jsonData: string;
}

interface SS {
    id: string | number;
}

class JsonTabController extends BlockComponent<Props, S, SS> {
    getJsonDataId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            jsonData: "",
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    receive = (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getJsonDataId) {

                if(responseJson?.error){
                    this.setState({ jsonData: JSON.stringify(this.getSampleData(), null, 2) });
                } else {
                    this.setState({ jsonData: JSON.stringify(responseJson?.data, null, 2) })
                }
            }
        }
    }


    getjsonDataData = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getJsonDataId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.getJsonData}`
        });
    }

    getSampleData = (): DataItem[] => {
        return [
          {
            Index: 1,
            "Data Category": "Business Insurance",
            Description: "- General liability insurance: Covers third-party claims for bodily",
            "Amount(USD)": "50,000/year",
          },
          {
            Index: 2,
            "Data Category": "Commercial Property",
            Description: "- Property insurance: Covers physical assets (buildings, equipment, inventory)",
            "Amount(USD)": "$2 million (property coverage)",
          },
        ];
    };

    handleCopyToClipboard = () => {
        navigator.clipboard.writeText(this.state.jsonData);
    };

    async componentDidMount(): Promise<void> {
        this.getjsonDataData()
    }
}

export default JsonTabController;
// Customizable Area End
