// Customizable Area Start
import React from "react";
import { Avatar, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HistoryTabController, { configJSON } from "./HistoryTabController.web";

export class HistoryTab extends HistoryTabController {

    render() {
        return (
            <div>
                <TableContainer className="historyQueryHub" style={styles.viewTable}>
                    <Table data-test-id="historyRowBodyTestTest">
                        <TableHead>
                            <TableRow style={styles.tableRowHeader} data-test-id="tableTestHeader">
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_dateandtime}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_actionDesc}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_action}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody data-test-id="historyRowBodyTest">
                            {this.state.historyList?.map((historyData, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        style={styles.contentText}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {historyData.updated_at}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                             <div style={{display: "flex", alignItems: 'center'}}>
                                                <Avatar src={""} style={{marginRight: 5}}/>
                                                <span style={{color: '#684EF3',maxWidth:"300px"}}>{historyData.action_type}</span>
                                                <span style={{marginLeft: 3}}>{historyData.description}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            <IconButton onClick={this.handleClick} data-test-id="alchorBtn">
												<MoreVertIcon />
											</IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

const styles = {
    headerViewFilterStyle: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
    } as React.CSSProperties,
    container: {
        overflowY: "auto",
        display: "flex",
        width: '100%',
    } as React.CSSProperties,
    
    headerViewStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    } as React.CSSProperties,
    contentText: {
        color: "#0F172A",
        padding: "10px 0px 10px 16px",
        verticalAlign: "middle",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        textAlign: 'left',

    } as React.CSSProperties,
    headerText: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        color: "#475569"
    },
    
    viewTable: {
        backgroundColor: "#fff",
        height: 'calc(100vh - 195px)',
        borderRadius: "16px"
    },
    infoCell: {
        padding: "2px 8px",
        color: "#334155",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
        backgroundColor: "#F6F5F9",
    } as React.CSSProperties,

    tableRowHeader: {
        border: "1px solid #ECEDF0",
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        verticalAlign: "top",
    }as React.CSSProperties,
    statusCell: {
        padding: "2px 8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
    } as React.CSSProperties,
};

export default HistoryTab;
// Customizable Area End
