// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import createRequestMessage from "../../utilities/src/create-request-message";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { WorkspaceProps } from "./WorkspaceListController.web";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config.js");

export type Props =  {
    navigation: any;
    classes: Record<string, string>;
    currentUserId: string;
    workspaceId: number;
}

interface S {
  view: number;
  currentWorkspace?: WorkspaceProps;
  anchorEl?:HTMLElement | null;
  isChatOpen: boolean;
}

interface SS {
  id: string | number;
}

class QueryHubTabViewController extends BlockComponent<Props, S, SS> {
    getDetailWorkspaceId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            view: 0,
            isChatOpen: false,
            anchorEl: null,
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    toggleChat = () => {
        this.setState(prevState => ({
          isChatOpen: !prevState.isChatOpen,
        }));
    };

    receive = (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getDetailWorkspaceId) {
                    this.setState({ currentWorkspace: responseJson })
                }
            }
        }
    }

    async componentDidMount(): Promise<void> {
        this.getDetailWorkspace()
    }

    getDetailWorkspace = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        
        const idWorkspace = this.props.navigation.getParam("id")
        this.getDetailWorkspaceId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.detailWorkspace}/${idWorkspace}`
        });
    }
    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ view: newValue });
    };

    handleClick = (event:any) => {
        this.setState({anchorEl:event.currentTarget});
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

}

export default QueryHubTabViewController;
// Customizable Area End
