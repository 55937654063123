// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config.js");

const avatarTpm = require("./svg-icons/avatar.svg");

export type Props = {
    classes?: Record<string, string>
    workspaceId: number
    currentUserId: string
    isReload: boolean
}

interface S {
    inputText: string,
    messages: string[],
}

interface SS {
    id: string | number;
}

class ChatBoxCopilotController extends BlockComponent<Props, S, SS> {
    getHistoryListId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            inputText: '',
            messages: [],
            
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(){
        super.componentDidMount();
    }

    handleInputChange = (event: any) => {
        this.setState({ inputText: event.target.value });
    };
    
    handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            this.sendMessage();
            event.preventDefault();
        }
    };

    sendMessage = () => {
        const { inputText, messages } = this.state;

        if (inputText.trim()) {
            this.setState({
            messages: [...messages, inputText],
            inputText: '',
            });
        }
    };
}

export default ChatBoxCopilotController;
// Customizable Area End
