// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../../packages/framework/src/Message";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";

import { UserInfoResponse } from "./UserProfileBasicController.web";
import Emitter from "../../../../packages/components/src/EventEmitter";


export const configJSON = require("./config");


export type Props = {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: () => void
    classes?: Record<string, string>
    isEdit?: boolean
    onSuccessDialog?: () => void
}

interface S {
    files: Array<any>
    isExceeded:boolean;
    firstName:string;
    lastName:string;
    company:string;
    position:string;
    userData:any
    currentUser?: UserInfoResponse
    anchorEl: Element | ((element: Element) => Element) | null | undefined
}

interface SS {
}

class UserProfileDialogController extends BlockComponent<Props, S, SS> {
    updateUserDataId: string = ""
    getUserId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            files: [],
            isExceeded:false,
            firstName:"",
            lastName:"",
            company:"",
            position:"",
            userData:[],
            currentUser: undefined,
            anchorEl: null,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    onDrop = (files: any) => {
        const newFile = files[0]; 
        if (newFile.size > 1048576) {
            this.setState({ isExceeded: true });
            return;
        }
        this.setState({ files: [newFile], isExceeded: false });
    };
    

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.updateUserDataId) {
                    this.setState({ userData: responseJson?.data?.attributes }, () => {
                        setStorageData("new_account", "false")
                        const userName = `${this.state.userData.first_name} ${this.state.userData.last_name}`
                        setStorageData('username', userName)
                        setStorageData('profile_photo',this.state.userData.profile_photo)
                        Emitter.emit("userName", userName)
                        Emitter.emit("profile_photo", this.state.userData.profile_photo)
                    })
                }
                if (apiRequestCallId === this.getUserId) {
                    this.handleUserData(responseJson.data)
                }
            }
        }
        // Customizable Area End
    }

    handleUserData = (userInfo: UserInfoResponse) => {
        if (userInfo?.attributes.profile_photo) {
            this.setState({
                files: [userInfo?.attributes?.profile_photo]
            })
        }
        this.setState({
            currentUser: userInfo,
            firstName: userInfo?.attributes?.first_name ?? "",
            lastName: userInfo?.attributes?.last_name ?? "",
            company: userInfo?.attributes?.company ?? "",
            position: userInfo?.attributes?.position ?? "",
        })
    }

    updateUserData = async (formData:any) => {
        const token = await getStorageData("authToken")
    
        const updateUserdate = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.updateUserDataId = updateUserdate.messageId;
    
        updateUserdate.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/account_block/accounts/update`
        );
    
        const header = {
          token,
        }
    
        updateUserdate.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
    
        updateUserdate.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        updateUserdate.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
    
        runEngine.sendMessage(updateUserdate.id, updateUserdate);
      }

    closeDialog = () => {
        this.props.onCloseDialog()
        if (!this.props.isEdit) {
            this.setState({
                files: [],
                firstName:"",
                lastName:"",
                company:"",
                position:"",
                anchorEl: null
            })
        }
    }

    onChangefirstnameAction = (firstName: string) => {
        this.setState({ firstName })
    }
    onChangelastnameAction = (lastName: string) => {
        this.setState({ lastName })
    }
    onChangecomanynameAction = (company: string) => {
        this.setState({ company })
    }
    onChangepositionAction = (position: string) => {
        this.setState({ position })
    }

    onRemoveFile = (currentIndex: number) => {
        const newList = [...this.state.files]
        const filterList = newList.filter((item, index) => index !== currentIndex)
        this.setState({ files: filterList }) 
    }
   
    openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    closeMenu = () => {
        this.setState({ anchorEl: null })
    }

    validationButton = () => {
        const hasValidFirstName = this.state.firstName.trim().length > 0;
        const hasValidLastName = this.state.lastName.trim().length > 0;
        const hasValidCompany = this.state.company.trim().length > 0;
        const hasValidPosition = this.state.position.trim().length > 0;
        return hasValidFirstName && hasValidLastName && hasValidCompany && hasValidPosition;
    }
    
    onContinue = () => {
        if (this.validationButton()) {
            const formData = new FormData();
            if (this.state.files.length > 0 && typeof this.state.files[0] !== "string") {
                formData.append(`profile_image`, this.state.files[0])
            }
            formData.append('data[attributes][first_name]',this.state.firstName);
            formData.append('data[attributes][last_name]',  this.state.lastName);
            formData.append('data[attributes][company]', this.state.company);
            formData.append('data[attributes][position]', this.state.position);
            this.updateUserData(formData)
            this.props.onSuccessDialog?.();
            this.props.onCloseDialog();
            if (!this.props.isEdit) {
                this.setState({
                    files: [],
                    firstName:"",
                    lastName:"",
                    company:"",
                    position:"",
                    anchorEl: null
                })
            } else {
                this.getUser()
            }
        }
    }

    async componentDidMount() {
        super.componentDidMount()
        this.getUser()
    }

    getUser = async () => {
        if (this.props.isEdit) {
            const token = await getStorageData("authToken")
            const accountId = await getStorageData("account_id");
            const request = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            )

            this.getUserId = request.messageId;
            createRequestMessage({
                token,
                requestMessage: request,
                method: "GET",
                endPoint: `${configJSON.getCurrentUserAPI}/${accountId}`
            });
        }
    }
}

export default UserProfileDialogController;
// Customizable Area End
