// Customizable Area Start
import { ChangeEvent } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import { getStorageData, removeStorageData } from "../../../../packages/framework/src/Utilities";
import { debounce } from "@material-ui/core";

export interface WorkspaceProps {
    workspace_id: number
    workspace_name: string
    workspace_members_count: number
    category: string
    subcategory: string
    description: string
    workspace_members: Array<WorkspaceMember>
}

export interface WorkspaceMember {
    id: number
    first_name: string
    last_name: string
    full_name: string
    email: string
    account_id: number
    adding_date: string
    workspace_id: number
    created_at: string
    updated_at: string
    avatar: string | null
    permission: string | null
    status: string | null
    team_id: number | null
    team_name: string | null
}

export interface CreateWorkspaceObject {
    name: string
    description: string
    category: string
    subcategory: string
    account_ids: Array<number>
}

export interface CategoryObject {
    id: string
    attributes: {
        name: string
        id: number
        sub_categories?: Array<DetailCategoryObject>
    }
}

export interface DetailCategoryObject {
    name: string
    id: number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: Record<string, string>
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    searchInput: string
    anchorEl: Element | ((element: Element) => Element) | null | undefined
    chosenCategory: string
    listCategories: Array<CategoryObject>
    totalCategory: Array<DetailCategoryObject>
    listSubCategories: Array<CategoryObject>
    workspaceList: Array<any>
    isCreateDialogOpen: boolean
    loading: boolean
    showProfile:boolean
    workspaceId:any
    isAcceptWorksapce:boolean
    urlToken:any
    isError:boolean
    isSuccess:boolean
    isSuccessMessage:string;
    isErrorMessage:string;
    searchValue:any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WorkspaceListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getListWorkspaceId: string = ""
    createNewWorkspaceId: string = ""
    getListCategoryId: string = ""
    acceptWorkSpaceInvitationApiCallId:string = ""
    getListSubCategoryId: string = ""
    getUsersApiCallId:string= ""
    debouncedSearch: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            searchInput: "",
            anchorEl: null,
            workspaceId:"",
            chosenCategory: "",
            listCategories: [],
            listSubCategories: [],
            totalCategory: [],
            isSuccess:false,
            isSuccessMessage:"",
            isError:false,
            isErrorMessage:"",
            workspaceList: [],
            isAcceptWorksapce:false,
            isCreateDialogOpen: false,
            urlToken:"",
            loading: false,
            showProfile:false,
            searchValue:""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.debouncedSearch = debounce(this.getListWorkspace.bind(this), 300);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.createNewWorkspaceId) {
                this.getListWorkspace()
            }
            if(apiRequestCallId == this.acceptWorkSpaceInvitationApiCallId){
                const token = await getStorageData("authToken")
                if(token){
                   
                  this.handlAcceptInvitationTeamRes(responseJson)
                }else{
               this.handleAcceptInvitationTeamRes(responseJson)
                }

            }
            this.handleListWorkspace(message)
            if (responseJson && responseJson.data) {
                if (apiRequestCallId === this.getListCategoryId) {
                    this.setState({ listCategories: responseJson.data })
                    this.getListSubCategory()
                }
                if (apiRequestCallId === this.getListSubCategoryId) {
                    const duplicateCategories = [...this.state.listCategories]
                    const listTotalAllCategory = duplicateCategories.concat(responseJson.data)
                    this.setState({ 
                        listSubCategories: responseJson.data,
                        totalCategory: listTotalAllCategory.map(item => ({
                            name: item.attributes.name,
                            id: item.attributes.id,
                        })),
                        loading: false
                    })
                }
               
            } else {
                this.handleError(message)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const id = urlParams.get('id')
        if (token) {
            this.setState({ workspaceId:id,isAcceptWorksapce:true,urlToken:token })
        }else{
            this.getListWorkspace()
            this.getListCategory()
        }    
    }

    handleSuccessClose = () => {
        this.setState({isSuccess:false});
      };
      handleErrorClose = () => {
        this.setState({isError:false});
      };


    onCloseRemoveDialog = ()=>{
        this.setState({isAcceptWorksapce:false})
        this.inviteRequest('Decline')
      }
      onOkCloseDialog =()=>{
        this.setState({isAcceptWorksapce:false})
        this.inviteRequest('Accept')
      }

      handlAcceptInvitationTeamRes = (responseJson:any)=>{
        if (responseJson.message) {
          this.setState({isSuccess:true, isSuccessMessage:responseJson.message }) 
        }else{
          this.setState({isError:true,isErrorMessage:"Something went wrong" });
        }
        this.navigateToWorkspace()
        this.getListWorkspace();
      }
      handleAcceptInvitationTeamRes = (responseJson:any)=>{
        if (responseJson.message) {
          this.setState({isSuccess:true, isSuccessMessage:"Invitation Accepted successfully" }) 
        }else{
          this.setState({isError:true,isErrorMessage:"Something went wroung" });
        }
        this.navigateToHome()
      }

      inviteRequest = async (status:string) => {
        const request = {
          
        }
        const header = {
          "Content-Type": configJSON.apiContentType,
          token:this.state.urlToken
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.acceptWorkSpaceInvitationApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/bx_block_workspace/workspaces/accept_workspace_invitation?status=${status}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(request)
      );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

    handleListWorkspace = (message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
            if (apiRequestCallId === this.getListWorkspaceId) {
                this.setState({ workspaceList: responseJson || [], loading: false })
            }
        } else {
            this.handleError(message)
        }
    }

    handleError = (message: Message) => {
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if ((responseJson?.errors?.[0].token === "Token has Expired" || responseJson?.errors?.[0].token === "Invalid token") && (
            apiRequestCallId === this.getListWorkspaceId ||
            apiRequestCallId === this.getListCategoryId ||
            apiRequestCallId === this.getListSubCategoryId
        )) {
            this.navigateToHome()
        }
    }

    navigateToHome = () => {
        removeStorageData("account_id")
        removeStorageData("authToken")
        const msgType: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msgType.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgType);
    }

    navigateToWorkspace = () => {
        const msgType: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msgType.addData(getName(MessageEnum.NavigationTargetMessage), "WorkspaceList");
        msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgType);
    }

    getListWorkspace = async () => {
        this.setState({ loading: true })
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        let endPoint = `${baseURL}/${configJSON.listWorkspace}`
        if (this.state.searchInput !== "") {
            endPoint += `?query=${this.state.searchInput}`
            if (this.state.chosenCategory !== "") {
                endPoint += `&category_or_subcategory=${this.state.chosenCategory}`
            }
        } else {
            if (this.state.chosenCategory !== "") {
                endPoint += `?category_or_subcategory=${this.state.chosenCategory}`
            }
        }
        this.getListWorkspaceId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint
        });
    }

    getListCategory = async () => {
        this.setState({ loading: true })
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getListCategoryId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.listCategories}`
        });
    }

    getListSubCategory = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getListSubCategoryId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.listSubCategories}`
        });
    }

    createNewWorkspaceAPI = async (dataNewWorkspace: CreateWorkspaceObject) => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.createNewWorkspaceId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "POST",
            body: JSON.stringify(dataNewWorkspace),
            endPoint: `${baseURL}/${configJSON.createWorkspaceAPI}`
        });
    }

    onChangeSearchInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.setState({ searchInput: event.target.value.trim() }, () => {
            this.debouncedSearch()
        })
    };

    openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    closeMenu = () => {
        this.setState({ anchorEl: null })
    }

    onCreateWorkspace = () => {
        this.setState({ isCreateDialogOpen: true })
        this.closeMenu()
    }

    onCreateTeam = () => {
        this.props.navigation.navigate("AccountGroups")
        this.closeMenu()
    }

    onCloseCreateDialog = (dataNewWorkspace?: CreateWorkspaceObject) => {
        this.setState({ isCreateDialogOpen: false })
        if (dataNewWorkspace) {
            this.createNewWorkspaceAPI(dataNewWorkspace)
        }
    }

    changeDropdown = (value: string) => {
        this.setState({ chosenCategory: value }, () => {
            this.debouncedSearch()
        })
    }

    onClickWorkspace = (item: WorkspaceProps) => {
        this.props.navigation.navigate("DetailWorkspaceTabView", { id: item.workspace_id })
    }

    clearCategory = () => {
        this.setState({ chosenCategory: '' }, () => {
            this.debouncedSearch()
        })
    }


  toWorkspaceShowProfile=()=>{
    this.setState({showProfile:!this.state.showProfile})
  }
    // Customizable Area End
}
