// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../../packages/framework/src/Message";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";


export const configJSON = require("./config");


export type Props = {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: () => void
    classes?: Record<string, string>
    account_id:any;
}

interface S {
    userData:any;
    currentTime:string;
    isEmailCopied:boolean;
}

interface SS {
}

class ViewProfileDrawerController extends BlockComponent<Props, S, SS> {
    getUserDataId: string = ""
    timerId:any ='';

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            userData:[],
            currentTime:'',
            isEmailCopied:false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getUserDataId) {
                    this.setState({ userData: responseJson?.data?.attributes })
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        this.getUserData();
        this.updateTime();
        this.timerId = setInterval(this.updateTime, 60000);
    }
   async componentWillUnmount() {
        clearInterval(this.timerId);
      }


  updateTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; 
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    this.setState({ currentTime: `${formattedHours}:${formattedMinutes} ${ampm}` });
  };

  handleCopyToClipboard = () => {
    navigator.clipboard.writeText(this.state.userData.email);
    this.setState({isEmailCopied:true})
};
    
    getUserData = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.getUserDataId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/account_block/accounts/${this.props?.account_id}`
        });
    }

    closeDialog = () => {
        this.props.onCloseDialog()
        this.setState({
            userData:[],
        })
    }

    handleClose =()=>{
        this.setState({isEmailCopied:false})
    }

}

export default ViewProfileDrawerController;
// Customizable Area End
