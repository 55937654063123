import React from "react";

// Customizable Area Start
import { Container, TextField, Checkbox, Button, FormControlLabel, Typography, Box, IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { styled } from '@material-ui/core/styles';
import { imageLogo, imageBackground, tick } from './assets';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";


// Customizable Area Start
// Customizable Area End
export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  isValidateEmailMsg = () => {
    if (this.state.emailErrorMsg) {
      return <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}>{this.state.emailErrorMsg}</Box>
    } else if (this.state.emailInvalidErrorMsg) {
      return <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}>{this.state.emailInvalidErrorMsg}</Box>
    }
    else {
      return null
    }
  }

  showPassword=()=>{
    return  this.state.showPassword ? <VisibilityOff /> : <Visibility />
  }


  showConfirmPassword=()=>{
    return  this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />
  }

  handleColorCheck = ()=>{
    if(!this.state.password.length&&!this.state.confirmPassword.length){
      return "rgba(148, 163, 184, 1)"
    }else if(this.state.passwordError&&this.state.confirmPasswordError){
      return "red"
    }else{
      return "green"
    }
  }

  render() {    
    return (
      <div style={{   background: `url(${imageBackground}) no-repeat center center`,
      backgroundSize: 'cover',
      width: '100%',
      height: '1000px',}}>
        <div style={{ paddingTop: '48px', paddingLeft: '80px' }}>
          <img style={{ width: '141.05px', height: '28px', gap: '2.8px ' }} src={imageLogo} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Container maxWidth="sm" style={{ margin: '106px 0px 100px' }}>
            <Box style={{ height: '620px', width: '464px' }} display="flex" flexDirection="column" alignItems="center"  >
              <Typography variant="h4" gutterBottom style={{ color: 'rgba(20, 16, 30, 1)', fontFamily: "DM Sans", fontWeight: 500, marginBottom: '17px' }}>Create an account</Typography>

              <Box style={{ width: '100%' }}>
                <Typography style={{ fontFamily: 'DM Sans', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }}>Email</Typography>
                <TextField
                  style={{
                    borderRadius: '8px',
                    border: '1px  rgba(226, 232, 240, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 1)',

                  }}
                  placeholder="Enter your email"
                  name="email"
                  value={this.state.email}
                  data-test-id="handleChange"
                  onChange={(e: any) => this.handleChange(e)}
                  variant="outlined" fullWidth required
                  error={this.state.emailError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                       <CheckIcon  style={{color:"#684EF3", paddingRight:"0.5rem"}} />
                      </InputAdornment>

                    ),
                  }}
                />
                {this.isValidateEmailMsg()}
              </Box>

              <Box style={{ width: '100%' }}>
                <Typography style={{ marginTop: '15px', fontFamily: 'DM Sans', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }}>Create password</Typography>
                <TextField
                  style={{
                    borderRadius: '8px',
                    border: '1px  rgba(226, 232, 240, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                  }}
                  name='password'
                  value={this.state.password}
                  data-test-id="handleChangepassword"
                  placeholder="Create password"
                  type={this.state.showPassword ? 'password' : 'text'}
                  onChange={(e: any) => this.handleChange(e)}
                  variant="outlined" fullWidth required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.handleClickShowPassword.bind(this, "password")}
                        >
                          {this.showPassword()}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {this.state.passwordError &&
                  <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}>{this.state.passwordError}</Box>}

              </Box>

              <Box style={{ width: '100%' }}>
                <Typography style={{ marginTop: '15px', marginBottom: '4px', fontFamily: 'DM Sans', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }}>Password confirmation</Typography>
                <TextField
                  style={{
                    borderRadius: '8px',
                    border: '1px  rgba(226, 232, 240, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 1)',

                  }}
                  name='confirmPassword'
                  value={this.state.confirmPassword}
                  data-test-id="handleconfirmPassword"
                  placeholder="Enter password confirmation"
                  type={this.state.showConfirmPassword ? 'password' : 'text'}
                  onChange={(e: any) => this.handleChange(e)}
                  variant="outlined" fullWidth required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          data-test-id='showPassword'
                          onClick={this.handleClickShowPassword.bind(this, 'confirmpassword')}
                        >
                          {this.showConfirmPassword()}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {this.state.confirmPasswordReqError ?
                  <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}>Confirm password is required</Box> :
                  null}

                {this.state.confirmPasswordError ?
                  <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}>{this.state.confirmPasswordError}</Box> :
                  null}
              </Box>
              <div style={styles.root}>
                <Typography variant="h6" gutterBottom style={{ marginTop: "8px", fontSize: '16px', fontWeight: 400, color: 'rgba(48, 53, 59, 1)', fontFamily: 'DM Sans' }}>
                  Your password must contain
                </Typography>
                <div style={styles.listItem}>
                  {this.state.passwordCheckUppercase ? <CheckCircleIcon style={{ ...styles.icon, color: this.handleColorCheck() }} /> : <CheckCircleIcon style={styles.icon} />}
                  <Typography style={styles.typo}>At least one capital letter</Typography>
                </div>
                <div style={styles.listItem}>
                  {this.state.passwordCheckLowercase ? <CheckCircleIcon style={{ ...styles.icon, color: this.handleColorCheck() }} /> : <CheckCircleIcon style={styles.icon} />}
                  <Typography style={styles.typo}>At least one lowercase letter</Typography>
                </div>
                <div style={styles.listItem}>
                  {this.state.passwordCheckNumber ? <CheckCircleIcon style={{ ...styles.icon, color: this.handleColorCheck() }} /> : <CheckCircleIcon style={styles.icon} />}
                  <Typography style={styles.typo}>At least one number</Typography>
                </div>
                <div style={styles.listItem}>
                  {this.state.passwordCheckMinLength ? <CheckCircleIcon style={{ ...styles.icon, color: this.handleColorCheck() }} /> : <CheckCircleIcon style={styles.icon} />}
                  <Typography style={styles.typo}>Minimum character length is 8 characters</Typography>
                </div>
              </div>
              <FormControlLabel
                control={
                  <StyledCheckbox
                    data-test-id="check"
                    checked={this.state.agreed}
                    onChange={this.handleCheckboxChange}
                    name="agreed"
                  />
                }
                label={
                  <Typography variant="body2" style={{ marginLeft: '8px' }}>
                    I agree with <a style={{ textDecoration: 'none', color: 'rgba(104, 78, 243, 1)' }} data-test-id="navigatetoPrivacy" onClick={()=>this.navigateTotearmsAndConditions()}>Privacy Policy</a> and <a style={{ textDecoration: 'none', color: 'rgba(104, 78, 243, 1)' }} onClick={()=>this.navigateTotearmsAndConditions()}>Terms and Conditions</a>

                  </Typography>
                }

                style={{ width: '100%', fontSize: '14px', color: 'rgba(48, 53, 59, 1)', marginBottom: '9px', alignItems: 'center' }}
              />
              {this.state.termsError &&
                <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400, padding: "0px 6rem 5px 0px" , marginBottom:"2rem"}}>{this.state.termsError}</Box>}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ color: 'rgba(255, 255, 255, 1)', background: this.state.isColorBtn ? "#684EF3" : "rgba(148, 163, 184, 1)" ,fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px', textTransform:"none" }}
                onClick={this.handleAccount}
                data-test-id="submit"
              >
                Create an account
              </Button>
              <Box style={{ marginTop: '18px' }}>
                <Typography variant="body2" style={{ fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400 }} >
                  <Button data-test-id="backtologin" style={{ textDecoration: 'none', color: 'rgba(104, 78, 243, 1)' }} onClick={this.backToLogin} >Log In</Button>
                </Typography>
              </Box>
            </Box>
          </Container>
        </div>
      </div>

    );
  }
  // Customizable Area End

}

// Customizable Area Start
export const StyledCheckbox = styled(Checkbox)(() => ({
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  border: '1px',
  padding: '0',
  marginLeft: '15px',
  "& .MuiSvgIcon-root": {
    color: '#CDD7E6',
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    color: 'black', 
    backgroundColor: 'white',
  }
}));

const styles = {
  root: {
    borderRadius: '4px',
    width: '100%',
    margin:'30px 0px'
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'rgba(148, 163, 184, 1)',
    marginRight: '8px',
    heigth: '14px',
    width: '14px',
    paddingTop:'8px',
  },
  typo: {
    marginTop: '6px',
    fontSize: '14px',
    fontWeight: 400,
    color: 'rgba(48, 53, 59, 1)'
  }
};
// Customizable Area End