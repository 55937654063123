// Customizable Area Start
import React from "react";
import AddMemberDialogController, { configJSON } from "./AddMemberDialogController.web";
import { Dialog, DialogContent, TextField, Avatar, Box, Grid, Paper, Button, Typography, Popper, MenuItem, InputAdornment } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from '@material-ui/icons/Search';

import { UserResponse } from "./CreateWorkspaceDialogController.web";
import { filterOptions } from "./CreateWorkspaceDialog.web";

export class AddMemberDialog extends AddMemberDialogController {
    renderAutocompleteList = () => {
        return (
            <div className={this.props.classes?.inputView}>
                <div className={this.props.classes?.titleText} style={{fontFamily:'DM Sans'}}>{configJSON.addMemberTitle}</div>
                <TextField
              style={{
                borderRadius: '8px',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                marginRight: '16px',
                width: "100%",
                paddingBottom:"20px"
              }as React.CSSProperties}
              name='search'
              placeholder="Search member to add by email or name"
              data-test-id="inputName2"
              type='text'
              variant="outlined"
              value={this.state.searchText}
              onChange={(e)=>this.handleSearchChange(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ paddingLeft: "5px", color: "#94A3B8" }as React.CSSProperties} />
                  </InputAdornment>
                ),
                style: {
                  height: "100%",
                  padding: 0,
                  border: 'none',
                },
              }}
              inputRef={this.anchorRef}
            />
            <Popper
              open={this.state.userList?.length > 0}
              anchorEl={this.anchorRef.current}
              style={{ zIndex: 99999 }as React.CSSProperties}
              placement="bottom-start"
            >
              <Box
                style={{
                  backgroundColor: '#fff',
                  maxHeight: "200px",
                  overflowY: 'auto',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  width: this.anchorRef.current ? this.anchorRef.current.offsetWidth : 'auto',
                  border: '1px solid #D6DEEA',
                  padding: '8px 8px',
                }as React.CSSProperties}
              >
                {this.state?.userList?.map((result:any, index:any) => (
                  <MenuItem
                    key={index}
                    onClick={() => this.handleUserSelect(result)}
                    style={{
                      padding: '8px 16px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontFamily: 'DM Sans ',
                      fontSize: '14px',
                      color: 'rgba(48, 53, 59, 1)',
                    }as React.CSSProperties}
                  >
                    <Typography variant="body2" color="textPrimary">
                      {result.attributes.email}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Popper>
            <Box
            style={{
              marginTop: '16px',
              maxHeight: '130px',
              overflowY: 'auto',
              width: '100%',
              paddingBottom:"16px"
            }as React.CSSProperties}
          >
            <Grid container spacing={2}>
              {this.state.selectedUser.map((user:any, index:any) => (
                <Grid item xs={12} key={index}>
                  <Paper
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '6px',
                      backgroundColor: "#F6F5F9"
                    }as React.CSSProperties}
                  >
                    <Avatar alt={user.attributes.first_name} src={user.attributes.avatar} />
                    <Box style={{ marginLeft: '16px', flexGrow: 1 }as React.CSSProperties}>
                      <Typography
                        style={{
                          fontFamily: 'DM Sans',
                          fontWeight: 700,
                          fontSize: "14px"
                        }as React.CSSProperties}
                      >
                        {user.attributes.first_name} {user.attributes.last_name}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'DM Sans',
                          color: 'rgba(48, 53, 59, 1)',
                          fontWeight: 400,
                          fontSize: "14px"
                        }as React.CSSProperties}
                      >
                        {user.attributes.email}
                      </Typography>
                    </Box>
                    <Button
                      data-test-id={`btnCloseGroup-${index}`}
                      variant="contained"
                      style={{
                        color: '#000',
                        borderColor: '#fff',
                        backgroundColor: "#fff",
                        fontSize: '14px',
                        fontWeight: 400,
                        borderRadius: '8px',
                        gap: '8px',
                        padding: '16px',
                        textTransform: 'none',
                        width: "133px",
                        height: "24px",
                        fontFamily: 'DM Sans '
                      }as React.CSSProperties}
                      onClick={()=>this.removeUser(user.id)}
                    >
                      X Remove
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
            </div>
        )
    }
    
    renderViewButton = () => {
        const actionType = this.props.actionType;
        return (
            <div className={this.props.classes?.viewButton}>
                <div data-test-id='closeAddMember' className={this.props.classes?.cancelButton} onClick={this.closeDialog}>{configJSON.cancel}</div>
                <div
                    data-test-id='addMember'
                    className={this.props.classes?.createButton}
                    style={{ backgroundColor: this.getCurrentCreateColor()}}
                    onClick={this.createAndCloseDialog}
                >
                    {actionType==="invite"? configJSON.inviteMemberBtnOk:configJSON.add}
                </div>
            </div>
        )
    }

    render() {
        const { isDialogOpen, actionType} = this.props
        return (
            <Dialog
                onClose={() => this.closeDialog()}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                    <div style={webStyles.textTitle}>
                        {actionType==="invite"? configJSON.inviteMemberTitle:configJSON.addMembers}
                    </div>
                    {this.renderAutocompleteList()}
                    {this.renderViewButton()}
                </DialogContent>
            </Dialog>
        );
    }
}

const webStyles = {
    viewDialog: {
        borderRadius: '24px',
        border: '0px solid #D0D2DA',
        backgroundColor: "#fff"
    },
    textTitle: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "33.6px",
        position: "sticky",
        top: 0,
        padding: "24px 32px",
        zIndex: 2,
        borderBottom: '1px solid #F6F5F9',
        backgroundColor: "#fff"
    } as React.CSSProperties,
    iconExpand: { color: "#30353B" },
    textInput: {
        width: "100%",
        height: 48,
        color: "#30353B",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        padding: "0px 20px 0px 10px"
    }
}

export default AddMemberDialog;
// Customizable Area End