// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { Message } from "../../../../packages/framework/src/Message";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";

export const configJSON = require("./config");

export const listAction = [
    {
        name: "Upload file with no action required",
        value: "Upload file with no action required",
    },
    {
        name: "Upload file with AI processing",
        value: "Upload file with AI processing",
    },
]

export type Props = {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: (data?: { workspace_id: number; files: Array<File>; uploadChoice: string }) => void
    classes?: Record<string, string>
}

interface S {
    uploadChoice: string
    files: Array<File>
    workspaceChoice: string
    listWorkspace: Array<{ id: number; name: string }>
}

interface SS {
}

class BulkUploadDashboardDialogController extends BlockComponent<Props, S, SS> {
    getListWorkspaceId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            listWorkspace: [],
            files: [],
            uploadChoice: listAction[0].name,
            workspaceChoice: ""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    onDrop = (files: any) => {
        const newListFile = [...this.state.files]
        const newList = newListFile.concat(files)
        this.setState({ files: newList })
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getListWorkspaceId) {
                    this.setState({ listWorkspace: responseJson })
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        this.getListWorkspace()
    }
    
    getListWorkspace = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.getListWorkspaceId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.getListWorkspace}`
        });
    }

    closeDialog = () => {
        this.props.onCloseDialog()
        this.setState({
            files: [],
            uploadChoice: listAction[0].name,
            workspaceChoice: ""
        })
    }

    onChangeAction = (uploadChoice: string) => {
        this.setState({ uploadChoice })
    }

    onReplaceFile = (acceptedFile: any, currentIndex: number) => {
        const newList = [...this.state.files]
        newList.splice(currentIndex, 1, acceptedFile[0])
        this.setState({ files: newList }) 
    }

    onRemoveFile = (currentIndex: number) => {
        const newList = [...this.state.files]
        const filterList = newList.filter((item, index) => index !== currentIndex)
        this.setState({ files: filterList }) 
    }
   
    byteConverter = (size: number) => {
        const units = ['bytes', 'KB', 'MB', 'GB'];
        let countIndex = 0;
        while(size >= 1024 && ++countIndex){
            size = size/1024;
        }
        
        return(size.toFixed(size < 10 && countIndex > 0 ? 1 : 0) + ' ' + units[countIndex]);
    }

    onChangeWorkspace = (workspaceChoice: { id: number; name: string }) => {
        this.setState({ workspaceChoice: workspaceChoice.name })
    }

    validationButton = () => {
        if (this.state.uploadChoice === "Upload file with no action required") {
            if (this.state.files.length === 0 || this.state.workspaceChoice === "") {
                return false
            } else return true
        } else {
            if (this.state.files.length === 0) {
                return false
            } else return true
        }
    }

    onContinue = () => {
        if (this.validationButton()) {
            const currentWorkspace = this.state.listWorkspace.find((item) => item.name === this.state.workspaceChoice)
            const data = {
                workspace_id: currentWorkspace?.id ?? 0,
                files: this.state.files,
                uploadChoice: this.state.uploadChoice
            }
            this.props.onCloseDialog(data)
            this.setState({
                files: [],
                uploadChoice: listAction[0].name,
                workspaceChoice: ""
            })
        }
    }
}

export default BulkUploadDashboardDialogController;
// Customizable Area End
