import React from "react";
// Customizable Area Start
import {
    Typography, AppBar, Toolbar, createTheme, ThemeProvider,
    Breadcrumbs,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { headTheme, paginationWithResults } from "../../../../packages/blocks/dashboard/src/DashboardAllFiles.web";
// @ts-ignore
import FileViewer from 'react-file-viewer';

const theme = createTheme({
    palette: {
        primary: {
            main: '#E2E8F0',
        },
    },
    shape: {
        borderRadius: 16,
    },
});
const cellThemes = createTheme({
    overrides: {
        MuiAvatar: {
            root: {
                borderRadius: '0px',
            },
        },
        MuiTableCell: {
            alignRight: {
                textAlign: "start"
            },
            root: {
                padding: "15px 16px 12px 28px"
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "16px "
            },
            elevation1: {
                boxShadow: "0px"
            }
        },
    },
});

const CustomSeparator = () => {
    return <span style={{ color: '#5E6671', height: "9.51px", width: "5.93", margin: " 0px 7px 17px 7px" }}>&gt;</span>
}
// Customizable Area End
import GeneralTabAllFilesController, {
    Props,
    webConfigJSON
} from "./GeneralTabAllFilesController";

export default class GeneralTabAllFiles extends GeneralTabAllFilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderDialog = () => {
      const type = this.state.currentFile?.filename?.split(".") ?? ""
      return (
            <Dialog
                data-test-id="dialog"
                onClose={() => this.closeDialog()}
                open={this.state.isDialogOpen}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                    <div style={webStyles.viewHeader}>
                        <div style={webStyles.headerText}>{this.state.currentFile?.filename ?? ""}</div>
                        <svg style={webStyles.closeIcon} onClick={this.closeDialog} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.192 10.3438L15.949 14.5858L11.707 10.3438L10.293 11.7578L14.535 15.9998L10.293 20.2418L11.707 21.6558L15.949 17.4137L20.192 21.6558L21.606 20.2418L17.364 15.9998L21.606 11.7578L20.192 10.3438Z" fill="#30353B"/>
                        </svg>
                    </div>
                    <div style={webStyles.viewDownload}>
                        <svg data-test-id="downloadIcon" style={webStyles.closeIcon} onClick={this.downloadFile} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" fill="white"/>
                            <path d="M16 20L20 15H17V8H15V15H12L16 20Z" fill="#30353B"/>
                            <path d="M24 22H8V15H6V22C6 23.103 6.897 24 8 24H24C25.103 24 26 23.103 26 22V15H24V22Z" fill="#30353B"/>
                        </svg>
                    </div>
                    <FileViewer
                        fileType={type[type.length - 1]}
                        filePath={this.state.currentFile?.url ?? ""}
                    />
                </DialogContent>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { page, rowsPerPage, lastInteractions } = this.state;
        const totalResults = lastInteractions.length;
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = Math.min(page * rowsPerPage, totalResults);
        const currentData = lastInteractions.slice(startIndex, endIndex);
        return (
            <>
                <div style={{ flexDirection: "row", height: "100vh", backgroundColor: "#E2E8F0", display: "flex", }}>
                    <div style={{ position: "sticky" }}>
                        <NavigationMenu id="dashboard" navigation={this.props.navigation} />
                    </div>
                    <div style={{ width: "100%", overflowY: "auto" }}>
                        <div style={{ flexGrow: 1, }}>
                            <ThemeProvider theme={theme}>
                                <AppBar position="static">
                                    <Toolbar style={{ margin: "0px 16px" }}>
                                        <Breadcrumbs separator={<CustomSeparator />} style={webStyles.titles}>
                                            <Typography style={webStyles.dashboardText} onClick={this.goToWorkspaceList} data-test-id="btngoto-workspace-list">{webConfigJSON.workSpace}</Typography>
                                            <Typography style={webStyles.dashboardText} onClick={this.goToWorkspaceListById} data-test-id="btngoto-workspace-list-by-id">{this.state.currentName}</Typography>
                                            <Typography style={webStyles.allFiles}>{webConfigJSON.allFilesText}</Typography>
                                        </Breadcrumbs>
                                    </Toolbar>
                                </AppBar>
                            </ThemeProvider>
                            <div style={{ padding: "20px 40px 24px 40px" }}>
                                <ThemeProvider theme={cellThemes}>
                                    <TableContainer style={{marginBottom:"24px"}} component={Paper}>
                                        <Table>
                                            <ThemeProvider theme={headTheme}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={webStyles.cellTime}>Date/Time</TableCell>
                                                        <TableCell style={webStyles.cellAction}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            </ThemeProvider>
                                            <TableBody>
                                                {currentData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell style={webStyles.timeText}>{row.lastUpdate}</TableCell>
                                                        <TableCell
                                                            style={webStyles.actionText} 
                                                            data-test-id={`action_${index}`}
                                                            onClick={() => this.onChooseAction(row)}
                                                        >
                                                            {row.actionType}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {paginationWithResults(lastInteractions, page, rowsPerPage, this.handleChangePage)}
                                    </TableContainer>
                                </ThemeProvider>
                            </div>
                        </div>

                    </div>
                    {this.renderDialog()}
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    viewDialog: {
        borderRadius: '24px',
        border: '0px solid #D0D2DA',
        height: "80%",
        width: "66%",
        maxWidth: "unset",
        backgroundColor: "#fff"
    },
    allFiles: {
        color: "#30353B",
        fontWeight: 500,
        lineHeight: "21px"
    },
    dashboardText: {
        color: "#5E6671",
        fontWeight: 400,
        lineHeight: "21px",
        cursor: "pointer"
    },
    cellTime: {
        lineHeight: "21px",
        color: "#5E6671",
        fontWeight: 700,
        fontFamily: "DM Sans",
        fontSize: "14px",
        width: "110px"
    },
    cellAction: {
        lineHeight: "21px",
        color: "#5E6671",
        fontWeight: 700,
        fontFamily: "DM Sans",
        fontSize: "14px",
        width: "100%"
    },
    timeText: {
        fontFamily: "DM Sans",
        color: "#5E6671",
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: 400,
    } as React.CSSProperties,
    actionText: {
        fontFamily: "DM Sans",
        color: "#30353B",
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: 400,
    } as React.CSSProperties,
    titles: {
        flexGrow: '1',
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        '@media (min-width: 600px)': {
            display: 'block',
        },
    },
    headerText: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: "20px",
        lineHeight: "28px",
        fontWeight: 700,
        maxWidth: "80%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    viewHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "26px 32px",
        borderBottom: "1px solid #E2E8F0"
    } as React.CSSProperties,
    closeIcon: {
        width: "32px",
        height: "32px",
        cursor: 'pointer'
    } as React.CSSProperties,
    viewDownload: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "26px 32px"
    } as React.CSSProperties,
};
// Customizable Area End
