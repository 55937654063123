// Customizable Area Start
import React from "react";
import FactorAuthenticateController, { Props } from "./FactorAuthenticateController";
export const configJSON = require("./config");
import { Box, Button, Container, IconButton, Snackbar, SnackbarContent, ThemeProvider, Typography, createTheme} from "@material-ui/core";
import OtpInput from "react-otp-input";
import CloseIcon from '@material-ui/icons/Close';
import { styled } from "@material-ui/core/styles";
import { imageLogo, imageBackground, sheildIcon } from './assets';
const theme = createTheme();

export default class FactorAuthentication extends FactorAuthenticateController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div style={{
                background: `url(${imageBackground}) no-repeat center center`,
                backgroundSize: 'cover',width: '100%',
                height: '1000px',paddingBottom: '52px'
              }}>
              <div 
              style={{ paddingTop: '48px', paddingLeft: '80px' }}>
                <img 
                style={{ width: '141.05px', height: '28px', gap: '2.8px ' }} src={imageLogo} />
              </div>
              <div 
              style={{ display: 'flex', justifyContent: 'center' }}>
                <ThemeProvider 
                theme={theme}>
                  <Container 
                  maxWidth="sm" style={{ margin: '92px 0 100px 0' }}>
                    <Box 
                    style={{  height: '620px', width: '464px' }} display="flex" flexDirection="column" alignItems="center"  >
                    <img style={{ width: '72px', height: '72px', gap: '2.8px ' }} src={sheildIcon} />
                    <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '32px', fontWeight: 500,alignContent:"center",lineHeight:"41.6px",color:"#14101E" }} > 
                    Two-Factor Authentication
                          </Typography>
        
                    <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400,textAlign:"center",lineHeight:"28px",color:"#30353B" }} > 
                    Enter the 5-digit code that we sent to your email
                          </Typography>
                          <BoxOtp>
                        <OtpInput
                            value={this.state.otpFactNumber}
                            inputType="tel"
                            onChange={this.handleFactorAuthenticationOtp}
                            containerStyle={{ outline: "none" }}
                            data-test-id="otpinput"
                            numInputs={5}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{
                                width: '80px',
                                height: '56px',
                                padding: '10px 12px 10px 12px',
                                margin: '8px',
                                borderRadius: '8px',
                                border: this.state.otpMessage ? '1px solid red' : '1px solid rgba(205, 215, 230, 1)',
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                            }}
                        />
                    </BoxOtp>
                    {this.state.otpMessage&&
                    <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}>{this.state.otpMessage}</Box>}
          
        
                          <Button
                            data-test-id="Verify Button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ marginTop:"50px",color: 'rgba(255, 255, 255, 1)',  backgroundColor: this.state.isOtpError ? '#94A3B8' : '#684EF3', fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px' ,textTransform: 'none' }}
                            onClick={this.verifyFactOtp}
                          >
                            Verify
                          </Button>

                          <Typography variant="body2" style={{marginTop: '50px' , fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400,marginBottom:"130px" }} > 
                    <span data-test-id="btnresendOTP" style={{ left:"10px", textDecoration: 'none', color: 'rgba(104, 78, 243, 1)', cursor:"pointer" }} onClick={this.handleReSendOtp}>Resend code</span>
                  </Typography>
                         
                          <Box style={
                            { marginTop: '160px' }}>
                          <Typography  variant="body2" style={
                            { fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400 }} > 
                            <span data-test-id="btngotologin" style={
                              { left:"10px", textDecoration: 'none', color: 'rgba(104, 78, 243, 1)', cursor:"pointer" }} onClick={this.goToLogin}>Back to Log In</span>
                          </Typography>
                        </Box>
                      </Box>
                      <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }} open={this.state.isresendClicked}
                  onClose={this.handleClose}
                  key={"top" + "center"}
                >
                  <SnackbarContent
                    message="Code has been resent"
                    style={{ backgroundColor: '#33BC55',borderRadius:"8px" }}
                    action={
                      <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                  />
                </Snackbar>
                    </Container>
                  </ThemeProvider>
                </div>
              </div>
        );
    }
}








const BoxOtp = styled(Box)({
    marginTop: "16px",
    display: 'flex',
    gap: '8px',
    "@media only screen and (max-width: 502px)": {
        marginTop: "96px",
    },
});

const ButtonVerified = styled(Button)({
    color: "rgba(255, 255, 255, 1)",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "DM Sans",
    textTransform: "none" as "none",
    backgroundColor: "rgba(148, 163, 184, 1)",
    height: "56px",
    borderRadius: "8px",
    marginTop: "16px",
    widht: "464px",
    "@media only screen and (max-width: 502px)": {
        marginBottom: "48px",
        marginTop: "145px",
    },
});

const SubHeadingTypo = styled(Typography)({
    color: "rgba(48, 53, 59, 1)",
    fontWeight: 700,
    marginTop: "16px",
    lineHeight: "24px",
    fontFamily: "DM Sans",
    textAlign: "center",
    fontSize: "16px",

    "@media only screen and (max-width: 502px)": {
        fontSize: "14px",
        lineHeight: "22px",
        marginTop: "8px",
    },
});
const InnerBox = styled(Box)({
    width: "384px",

    "@media only screen and (max-width: 439px)": {
        width: "327px",
    },
    "@media only screen and (max-width: 341px)": {
        width: "278",
    },
});

const webStyle = {
    createBtn: {
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Inter",
        textTransform: "none" as "none",
        background: "#395169",
        height: "56px",
        borderRadius: "8px",
        marginTop: "42px",
        widht: "100%",
    },
    optText: {
        color: "rgba(104, 78, 243, 1)",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "center" as "center",
        marginTop: "28px",
        cursor: "pointer",
    },
    buttonText: {
        color: "rgba(104, 78, 243, 1)",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "center" as "center",
        marginTop: "146px",
        cursor: "pointer",
    },

    popup: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        color: "#1E293B",
        borderRadius: "8px",
        height: "42px",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Inter",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    poperror: {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        backgroundColor: "#FEE2E2",
        color: "DC2626",
        borderRadius: "4px",
        borderLeft: "4px solid #DC2626",
        height: "42px",
        fontFamily: "Inter",
        paddingLeft: "10px",
        fontWeight: 400,
        paddingRight: "10px",
        lineHeight: "24px",
        marginTop: "40px"
    },
};
// Customizable Area End