// Customizable Area Start
import React from "react";
import EmailVerificationController, { Props } from "./EmailVerificationController";
export const configJSON = require("./config");
import { styled } from "@material-ui/core/styles";
import { conf, textLable, imageBackground } from './assets';
import { Box, Button, Typography, Container } from "@material-ui/core";

export default class Confirmation extends EmailVerificationController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Root>
                <BoxContainer>
                <Logo src={conf} alt="Logo" />
                <Typography variant="h4" component="h1" style={{marginTop:'16px'}}>
                    <img src={textLable} alt="Text Label" />
                </Typography>
                <BackTypography variant="h6">
                    Welcome Aboard! Start your journey 
                </BackTypography>
                <TextTypography>
                    with a new generation tools of data governance
                </TextTypography>
                <StyledButton variant="contained" color="primary" style={{cursor:"pointer"}} onClick={this.handleLetsLogin.bind(this)}>
                    Let's start
                </StyledButton>
                </BoxContainer>
            </Root>
        );
    }
}
const Root = styled(Container)({
    background: `url(${imageBackground}) no-repeat center center/cover`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    marginTop: '0', 
    '@media (min-width: 1280px)':{
        maxWidth: 'none'
    }
});

const BoxContainer = styled(Box)({
    width: '630px',
    height: '396px',
    opacity: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'

    
});

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(6),
    width: '530px',
    height: '56px',
    gap: '8px',
    borderRadius: '8px 0 0 0',
    background:'rgba(104, 78, 243, 1)',
    color:'rgba(255, 255, 255, 1)',
    fontSize:'16px',
    fontWeight:400,
    fontFamily:'DM Sans',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
}));

const Logo = styled('img')(({ theme }) => ({
    width: 80,
    height: 80,
    marginBottom: theme.spacing(2),
}));

const BackTypography = styled(Typography)({
    fontFamily: 'DM Sans',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '33.6px',
    textAlign: 'center',
    color: 'rgba(20, 16, 30, 1)',
    marginTop: '32px',
    "@media only screen and (max-width: 622px)": {
        display: "none",
    },
});

const TextTypography = styled(Typography)({
    fontFamily: 'DM Sans',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '33.6px',
    textAlign: 'center',
    color: 'rgba(20, 16, 30, 1)',
});
// Customizable Area End
