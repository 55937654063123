import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  emailError: boolean;
  isButtonDisabled:boolean;
  isButtonClicked:boolean;
  isresendClicked:boolean;
  vertical: string,
  horizontal: string,
  passwordError: boolean;
  confirmPasswordError: boolean;
  confirmPassword: string;
  showPassword: boolean;
  showPasswordConfirm:boolean;
  urlToken:string;
  passwordCheckUppercase:boolean;
  passwordCheckLowercase:boolean;
  passwordCheckNumber:boolean;
  passwordCheckMinLength:boolean;
  password:string;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  apiPasswordResetCallId:any;
  otpToken: any;
  isChangePassword: boolean = false;


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      emailError: false,
      password:"",
      isButtonClicked:false,
      isresendClicked:false,
      vertical: 'top',
      horizontal: 'center',
      confirmPassword: '',
      showPassword: true,
      passwordError: false,
      confirmPasswordError: false,
      passwordCheckUppercase:false,
      passwordCheckLowercase:false,
      passwordCheckNumber:false,
      passwordCheckMinLength:false,
      passwordRules: "",
      urlToken:"",
      showPasswordConfirm:true,
      token: "",
      enablePasswordField: true,
      isButtonDisabled:true,
      btnConfirmPasswordShowHide: true,
    };
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email')
    if (token) {
        this.setState({ urlToken: token, emailValue:email });
    }
  }



  async receive(from: string, message: Message) {
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

  
        if (apiRequestCallId != null) {           
          if (apiRequestCallId === this.apiPasswordResetCallId) {
            if (responseJson && responseJson.meta && responseJson.meta.message) {
              this.setState({isButtonClicked:true})
            } else {
              this.setState({passwordError:true});
            }
          }
        }
      }

       
       
   
       
  }


  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleClickShowPasswordConfirm = () => {
    this.setState({ showPasswordConfirm: !this.state.showPasswordConfirm });
  };

  handlePasswordChange = (e: any) => {
    const { name, value } = e.target;   
    let passwordCheckUppercase = /[A-Z]/.test(value);
    let passwordCheckLowercase = /[a-z]/.test(value);
    let passwordCheckNumber = /\d/.test(value);
    let passwordCheckMinLength = value.length >= 8;
  
    switch (name) {
      case 'password':
        this.setState({
          passwordCheckUppercase,
          passwordCheckLowercase,
          passwordCheckNumber,
          passwordCheckMinLength,
          password: value,
        }, this.updateButtonState);
  
        break;
      case 'confirmPassword':
        this.setState({
          confirmPassword: value,
        }, this.updateButtonState);
  
        break;
      default:
        break;
    }
  };
  
  updateButtonState = () => {
    const { password, confirmPassword, passwordCheckUppercase, passwordCheckLowercase, passwordCheckNumber, passwordCheckMinLength } = this.state;
    
    const isPasswordValid = passwordCheckUppercase && passwordCheckLowercase && passwordCheckNumber && passwordCheckMinLength;
    const isConfirmPasswordValid = password === confirmPassword && confirmPassword !== '';
    
    this.setState({
      isButtonDisabled: !(isPasswordValid && isConfirmPasswordValid),
    });
  };
  

  handleResetPassword = () => {
    const { password, confirmPassword } = this.state;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const passwordError = !passwordRegex.test(password) || password === "";
    const confirmPasswordError = confirmPassword !== password || confirmPassword === "";

    this.setState({ passwordError, confirmPasswordError });
    if (passwordError || confirmPasswordError) {
      this.setState({passwordError:passwordError,confirmPasswordError:confirmPasswordError})
    }else{
    const requestBody = {
        data: {
            
            token: this.state.urlToken,
            new_password: password
            
        }
    };
    
    this.handleResetPasswordApi(requestBody);
    }
  };
  handleResetPasswordApi = async (requestBody:any) => {  
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

 
    let requestUrl = configJSON.ResetPasswordEndPoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPasswordResetCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(requestBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod);

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  };
  
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin=()=> {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  // Customizable Area End
}
