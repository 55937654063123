// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  firstName: string;
  lastName: string;
  email: string;
  otpMessage: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  isButtonDisabled:boolean;
  isresendClicked:boolean;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  otpNumber: string;
  isOpen: boolean;
  isOtpError: boolean;
  time: number;
  isTimerRunning: boolean;
  isError: boolean;
  isBtnColor:boolean;
}

export interface SS {
  id: any;
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  arrayholder: any[];
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  verifyOtpCallId: string = "";
  imgPasswordInVisible: any;
  reSendCodeEmailCallId: string="";
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  verifyID: string = ""
  timerId: number | NodeJS.Timeout | null = null;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      otpNumber: '',
      isButtonDisabled:true,
      isresendClicked:false,
      isOtpError: false,
      otpMessage: "",
      isOpen: false,
      isError: false,
      time: 30,
      isTimerRunning: true,
      isBtnColor:false
    };

    this.arrayholder = [];
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.verifyOtpCallId) {
          this.handleVerifyResp(responseJson)
        }
        else if(apiRequestCallId === this.reSendCodeEmailCallId){
          this.handleResendCodeResp(responseJson)
        }
      }
    }

  }

  handleVerifyResp = (responseJson: any) => {
    if (responseJson?.message === "Email verified successfully.") {
      this.setState({ isOtpError: false, otpMessage: "" })
      this.confirmationPage()
    }
    else if (responseJson?.errors[0]?.otp) {
      this.setState({ isOtpError: true, otpMessage: "Code is not correct, please try one more time" })
      return
    }
  }
  handleResendCodeResp = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState({ isOtpError: false, otpMessage: "",isresendClicked:true })
    }
    else if (responseJson?.errors) {
      this.setState({ isOtpError: true, otpMessage: "Invalid account" })
      return
    }
  }
  async componentDidMount(): Promise<void> {
    const email = localStorage.getItem("email")
    if (email) {
      this.setState({ email })
    }
  }


  handleOtp = (otpnumber: string) => {
    if(otpnumber.trim().length == 5) {
      this.setState({isBtnColor:true});
    } else {
      this.setState({isBtnColor:false});
    }
    this.setState({ otpMessage: "", isOtpError: false })
    const otpRegex = /^[a-zA-Z0-9]+$/;
    if (otpnumber.match(otpRegex)) {
      this.setState({ otpNumber: otpnumber });      
    } else {
      this.setState({ otpNumber: '' });
    }

  }


  verifyOtp = () => {
    if (!this.state.otpNumber.length) {
      this.setState({ otpMessage: "Please enter the OTP", isOtpError: true , isBtnColor:true})
      return;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpbody = {
      email: this.state.email,
      otp: this.state.otpNumber,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpbody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }
  handleReSendCodeEmail = () => {
    this.reSendCodeEmail()
  }

  reSendCodeEmail = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const httpbody = {
      email: this.state.email,
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.reSendCodeEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendcodeEmailEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpbody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }

  backToSignup = () => {
    const messageType: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    messageType.addData(
      getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration"
    );
    messageType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    messageType.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageType);
  };

  confirmationPage = () => {
    const msgType: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msgType.addData(
      getName(MessageEnum.NavigationTargetMessage), "Confirmation"
    );
    msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    msgType.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgType);
  };

  handleLetsLogin = () => {
    window.location.replace("/")
  }

  handleClose =()=>{
    this.setState({isresendClicked:false})
  }
  goToLogin=()=> {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

};
// Customizable Area End
