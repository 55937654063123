import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Account, Group, GroupAccount } from "./types";
import { getStorageData, removeStorageData} from "../../../framework/src/Utilities";
import React from "react";
import { debounce } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  name: string;
  createdAt: string;
  updatedAt: string;
  memberID:number;
  editMode: boolean;
  token: string;
  groupList: Group[];
  isVisibleModal: boolean;
  anchorEl:any,
  anchorEl2:any,
  isAdmin:any;
  teamMemberId:any;
  isAcceptTeam:boolean;
  isOpenViewDialog:boolean;
  accountId:string;
  users:any;
  teams:any;
  userIds:any;
  isTeamEmpty:boolean;
  isSuccess:boolean;
  isError:boolean;
  urlToken:string;
  isSuccessMessage:string;
  isErrorMessage:string;
  isCreateTeamClicked:boolean;
  isTeamsClicked:boolean;
  individualTeam:any;
  teamMembers:any;
  teamMembersOriginal:any;
  searchValue:any;
  searchResults: any;
  isEditTeamClicked: boolean;
  isVisibleAddAccountModal: boolean;
  isVisibleDeleteAccountModal: boolean;
  accountsData: Account[];
  modalAccData: Account[];
  currentUserId:any;
  selectedmemberID:number;
  selectedAccounts: GroupAccount[];
  dropdownAccountStatus: boolean;
  fieldError: boolean;
  teamName:string;
  teamNameError:boolean;
  isEditButtondisabled:boolean;
  searchTeamName:string;
  searchMember:string;
  teamsOriginal:any;
  showProfile:boolean;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  getUsersApiCallId = "";
  getTeamsApiCallId = "";
  deleteTeamApiCallId = "";
  removeTeamMemberApiCallId = "";
  resendarequestTeamMemberApiCallId = "";
  createTeamApiCallId = "";
  acceptTeamInvitationApiCallId = "";
  updateTeamApiCallId = "";
  addMemberApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  getIndividualTeamApiCallId = "";
  anchorRef:any;
  debouncedSearch:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      name: "",
      createdAt: "",
      memberID:0,
      updatedAt: "",
      isAdmin:false,
      isTeamEmpty:true,
      currentUserId:"",
      editMode: false,
      teamMemberId:"",
      isAcceptTeam:false,
      searchMember:"",
      urlToken:"",
      accountId:'',
      token: "",
      isOpenViewDialog:false,
      groupList: [],
      isSuccess:false,
      isError:false,
      isSuccessMessage:"",
      isErrorMessage:"",
      users:[],
      isTeamsClicked:false,
      selectedmemberID:0,
      teamMembers:[],
      teamMembersOriginal:[],
      individualTeam:[],
      searchResults: [],
      teamsOriginal:[],
      searchValue:"",
      searchTeamName:"",
      teamName:"",
      teamNameError:false,
      isVisibleModal: false,
      isVisibleAddAccountModal: false,
      anchorEl:null,
      anchorEl2:null,
      isEditTeamClicked:false,
      teams:[],
      userIds:[],
      isCreateTeamClicked:false,
      isVisibleDeleteAccountModal: false,
      accountsData: [],
      modalAccData: [],
      dropdownAccountStatus: false,
      selectedAccounts: [],
      fieldError: false,
      isEditButtondisabled:true,
      showProfile:false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.anchorRef = React.createRef();
    this.debouncedSearch = debounce(this.getUsers.bind(this), 300);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const id = urlParams.get('id')
    if (token) {
        this.setState({ teamMemberId:id,isAcceptTeam:true,urlToken:token })
    }else{
      this.getTeams();
      this.getCurrentUserId();
    }
  }

  checkIsAdmin = () => {
    const { individualTeam, currentUserId } = this.state;
  
    const foundMember = individualTeam?.team_members?.find((member: any) => 
      member?.account_id?.toString() === currentUserId
    );
  
    const isAdmin = foundMember?.permission === "Admin";
  
    this.setState({ 
      isAdmin: isAdmin, 
      memberID: foundMember?.id 
    });
  };
  
  onCloseRemoveDialog = ()=>{
    this.setState({isAcceptTeam:false})
    this.inviteRequest('Decline')
  }
  onOkCloseDialog =()=>{
    this.setState({isAcceptTeam:false})
    this.inviteRequest('Accept')
  }
  
getCurrentUserId = async () => {
  const account_id = await getStorageData("account_id");
  this.setState({ currentUserId: account_id || "" })
}
onCloseViewDialog =()=>{
  this.setState({isOpenViewDialog:false,accountId:""})
}

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (
      this.getUsersApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handlegetUserRes(responseJson)
     
    }
    else if (
      this.createTeamApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

     this.handleCreateTeamRes(responseJson)
    }else if (
      this.acceptTeamInvitationApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const token = await getStorageData("authToken")
      if(token){
        this.handlAcceptInvitationTeamRes(responseJson)
      }else{
     this.handleAcceptInvitationTeamRes(responseJson)
      }
    }
    else if (
      this.updateTeamApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleUpdateTeamRes(responseJson)
    }
    else if (
      this.getTeamsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

     this.handlegetTeamRes(responseJson)
    }
    else if (
      this.getIndividualTeamApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleIndividalteamRes(responseJson)
    }
    else if (
      this.addMemberApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleaddMemberRes(responseJson)
    }
   else if (
      this.deleteTeamApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    this.handleDeleteTeamres(responseJson)
  }
    else if (
    this.removeTeamMemberApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.state.isAdmin){
      this.handleRemoveMemberRes(responseJson)
    }else{
      this.handleRegularUserRemoveMemberRes(responseJson)
    }
}else if (
      this.resendarequestTeamMemberApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
      const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleReSendaRequestRes(responseJson)
      }
};


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  handlegetUserRes =(responseJson:any)=>{
    if (responseJson.data) {
      this.setState({ searchResults: responseJson.data });
    }else{
      this.setState({searchResults:[]})
    }
  }

  handleCreateTeamRes = (responseJson:any)=>{
    if (responseJson.message) {
      this.setState({ teamName:"",searchValue:"",searchResults:[],users:[],userIds:[],isVisibleAddAccountModal:false ,isSuccess:true, isSuccessMessage:"Team created successfull" },()=>this.getTeams());
    }else{
      this.setState({anchorEl:null,anchorEl2:null,isError:true,isErrorMessage:"Unable to Create Team" });
    }
  }
  handlAcceptInvitationTeamRes = (responseJson:any)=>{
    if (responseJson.message) {
      this.setState({isSuccess:true, isSuccessMessage:responseJson.message }) 
    }else{
      this.setState({isError:true,isErrorMessage:"Something went wrong" });
    }
    this.navigateToAccounts();
    this.getTeams();
  }
  handleAcceptInvitationTeamRes = (responseJson:any)=>{
    if (responseJson.message) {
      this.setState({isSuccess:true, isSuccessMessage:"Invitation Accepted successfully" }) 
    }else{
      this.setState({isError:true,isErrorMessage:"Something went wroung" });
    }
    this.navigateToHome()
  }

  navigateToHome = () => {
    removeStorageData("account_id")
    removeStorageData("authToken")
    const msgType: Message = new Message(
        getName(MessageEnum.NavigationMessage)
    );
    msgType.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgType);
}

navigateToAccounts = () => {
  const msgType: Message = new Message(
      getName(MessageEnum.NavigationMessage)
  );
  msgType.addData(getName(MessageEnum.NavigationTargetMessage), "AccountGroups");
  msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msgType);
}

  handleRemoveMemberRes = (responseJson:any)=>{
    if (responseJson.message) {
      this.setState({anchorEl:null,anchorEl2:null,isSuccess:true,memberID:0,isSuccessMessage:"Successfully Removed Member" },()=>this.getIndividualTeam(this.state?.individualTeam?.id));
    }else{
      this.setState({anchorEl:null,anchorEl2:null,isError:true,isErrorMessage:"Unable to remove member" });
    }
  }

  handleRegularUserRemoveMemberRes = (responseJson:any)=>{
    if (responseJson.message) {
      this.setState({anchorEl:null,anchorEl2:null,isSuccess:true,memberID:0,isTeamsClicked:false,isSuccessMessage:"Successfully Left team" },()=>this.getTeams());
    }else{
      this.setState({anchorEl:null,anchorEl2:null,isError:true,isErrorMessage:"Unable to leave Team" });
    }
  }

  handleReSendaRequestRes = (responseJson:any)=>{
    if (responseJson.message) {
      this.setState({anchorEl:null,anchorEl2:null,isSuccess:true,isSuccessMessage:"Request Sent Successfully" },()=>this.getIndividualTeam(this.state?.individualTeam?.id));
    }else{
      this.setState({anchorEl:null,anchorEl2:null,isError:true,isErrorMessage:"Unable to send the Request" });
    }
  }

  handleDeleteTeamres = (responseJson:any)=>{
    if (responseJson.message) {
      this.setState({isTeamsClicked:false,teams:[],anchorEl:null,anchorEl2:null,isSuccess:true,isSuccessMessage:"Successfully Deleted Team" });
      this.getTeams()
    }else{
      this.setState({anchorEl:null,anchorEl2:null,isError:true,isErrorMessage:"Unable to deleted team" });
  }
  }

  handlegetTeamRes = (responseJson:any)=>{
    if (responseJson?.message) {
      this.setState({ teams:responseJson?.data,teamsOriginal:responseJson.data,isTeamEmpty:true});
    } else {
      this.setState({isTeamEmpty:false})
    }
  }

  handleaddMemberRes = (responseJson:any)=>{
    if (responseJson.message) {
      this.setState({ searchValue:"",searchResults:[],users:[],userIds:[],isVisibleAddAccountModal:false,isSuccess:true,isSuccessMessage:"Add Member Successfully"  },()=>this.getIndividualTeam(this.state.individualTeam.id));
    } else {
      this.setState({ searchValue:"",searchResults:[],users:[],isVisibleAddAccountModal:false,isError:true,isErrorMessage:"Members unable to add"  });
    }
  }

  handleIndividalteamRes= (responseJson:any)=>{
    if (responseJson.data) {
      this.setState({individualTeam:responseJson.data, teamMembers:responseJson.data.team_members,teamMembersOriginal:responseJson.data.team_members});
      this.getCurrentUserId();
      this.checkIsAdmin();
    }
  }

  handleUpdateTeamRes = (responseJson:any)=>{
    if (responseJson.message) {
      this.setState({ isEditTeamClicked:false ,isSuccess:true,isSuccessMessage:"Team Name Updated successfully" },()=>this.getIndividualTeam(responseJson.data.id));
    } else {
      this.setState({ searchValue:"",searchResults:[],users:[],isVisibleAddAccountModal:false,isError:true,isErrorMessage:"Members unable to Update"});
    }
  }

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  handleSearchChange(event:any) {
    this.setState({ searchValue:event.target.value},()=>this.debouncedSearch(this.state.searchValue));
  }

  handleSearchTeamNameChange = (event: any) => {

    const searchQuery = event.target.value.toLowerCase();

    this.setState((prevState: any) => {
      const filteredTeams = prevState.teamsOriginal.filter((team: any) =>
        team.team_name.toLowerCase().includes(searchQuery)
      );

      return {
        teams: filteredTeams,
        searchTeamName:searchQuery,
      };
    });
  };

  handleSearchMemberChange = (event: any) => {
    const searchQuery = event.target.value.toLowerCase();

    this.setState((prevState: any) => {
        const filteredMembers = prevState.teamMembersOriginal.filter((member: any) =>
            (member.first_name && member.first_name.toLowerCase().includes(searchQuery)) ||
            (member.last_name && member.last_name.toLowerCase().includes(searchQuery)) ||
            (member.email && member.email.toLowerCase().includes(searchQuery))
        );

        return {
          teamMembers: filteredMembers,
            searchMember: searchQuery,
        };
    });
};

  getUsers = async (search: string) => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUsersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/search?term=${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteTeam = async () => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTeamApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_team/teams/${this.state.individualTeam.id}/delete_team`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  removeMemberFromTeam = async (id:number) => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      team_id:this.state.individualTeam.id,
      team_member_id: id,
    };

    this.removeTeamMemberApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_team/teams/remove_team_member`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  resendaRequest = async (id:number) => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.resendarequestTeamMemberApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_team/teams/request_raised?team_member_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCreateTeam=()=>{
    if(this.state.teamName == ""){
      this.setState({teamNameError:true})
    }
    else{
      this.setState({teamNameError:false,isCreateTeamClicked:false})
      this.createTeam()
    }
}

  handleAddmember=()=>{
    if(this.state.userIds.length === 0){
      this.setState({isVisibleAddAccountModal:!this.state.isVisibleAddAccountModal})
    }
    else{
      this.addMember()
    }
  }

  getTeams = async () => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeamsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_team/teams/get_teams_and_teams_members`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createTeam = async () => {
    const token = await getStorageData("authToken")
    const request = {
      team_name:this.state.teamName,
      account_ids:this.state.userIds
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createTeamApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_team/teams/create_team_and_team_members`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(request)
  );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  inviteRequest = async (status:string) => {
    const request = {
      status:status
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:this.state.urlToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptTeamInvitationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_team/teams/accept_team_invitation`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(request)
  );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateTeamName = async () => {
    const token = await getStorageData("authToken")
    const request = {
      team_name:this.state.teamName,
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTeamApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_team/teams/${this.state.individualTeam.id}/edit_team`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(request)
  );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addMember = async () => {
    const token = await getStorageData("authToken")
    const request = {team_id: this.state.individualTeam.id,account_ids:this.state.userIds}
    const header = {"Content-Type": configJSON.apiContentType,token:token};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addMemberApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`/bx_block_team/teams/add_team_members`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(request)
  );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUserSelect = (user:any) => {
    this.setState((prevState) => ({
      users: [...prevState.users, user],
      userIds: [...prevState.userIds, user.id],
      searchValue: '',
      searchResults: [],
      anchorEl: null ,
    }));
  };

  removeUser = (userId:any) => {
    this.setState((prevState) => ({
      users: prevState.users.filter((user: { id: any; }) => user.id !== userId),
      userIds: prevState.userIds.filter((id: any) => id !== userId),
    }));
  };

  hideAddAccountModal = () => {
    this.setState({ isCreateTeamClicked: false ,searchResults:[]});
  };

  hideDeleteAccountModal = () => {
    this.setState({ isVisibleDeleteAccountModal: false });
  };

  toTeamShowProfile=()=>{
    this.setState({showProfile:!this.state.showProfile})
  }
  
  showAddModal = () => {
    this.setState({
      name: "",
      editMode: false,
      fieldError: false,
      isVisibleModal: !this.state.isVisibleModal,
    });
  };

  handleInputName = (name: string) => {
    this.setState({ name, fieldError: false });
  };

  showHideAddAccountModal = () => {
    this.setState({
      isVisibleAddAccountModal: !this.state.isVisibleAddAccountModal,
      searchResults:[],
      editMode: true,
    });
  };

  handleClick = (event:any,memberID:number) => {
    this.setState({anchorEl:event.currentTarget,selectedmemberID:memberID});
  };

  handleTeamClick = ()=>{
    this.setState({isTeamsClicked:!this.state.isTeamsClicked})
    this.getTeams()
  }
  handleViewTeamClick = (id:number)=>{
    this.setState({isTeamsClicked:!this.state.isTeamsClicked})
    this.getIndividualTeam(id)
  }

  handleCreateTeamClick = () => {
    this.setState({isCreateTeamClicked:true});
  };


  handleChange = (event:any) => {
    this.setState({teamName:event.target.value,teamNameError:false});
  };

 handleClose = () => {
    this.setState({anchorEl:null,selectedmemberID:0});
  };

  handleViewProfileClose = (id:string) => {
    this.setState({anchorEl:null,selectedmemberID:0,accountId:id,isOpenViewDialog:true});
  };

  handleSuccessClose = () => {
    this.setState({isSuccess:false});
  };
  handleErrorClose = () => {
    this.setState({isError:false});
  };
  handleremoveClose = () => {
    this.setState({anchorEl:null});
  };

  handleClickEditTeamNameClose = () => {
    this.setState({anchorEl:null,isEditTeamClicked:true});
    };
  handleEditTeam = () => {
    this.setState({anchorEl:null,isEditTeamClicked:true,teamName:this.state?.individualTeam?.team_name});
    };
  handleClick2 = (event:any) => {
    this.setState({anchorEl2:event.currentTarget});
  };

 handleClose2 = () => {
  this.setState({anchorEl2:null});
  };

  handleCloseEditTeam = () => {
    this.setState({isEditTeamClicked:false});
  };

  handleUpdateTeamname =()=>{
    if(this.state.teamName == ""){
      this.setState({teamNameError:true,isEditButtondisabled:true})
    }
    else{
      this.updateTeamName()
    }
  }

  handleAddAccounts = (group: Group) => {
    let accArr: any[] = [];
    if (group.attributes.accounts.length > 0) {
      accArr = this.state.accountsData.filter(
        (ar) =>
          !group.attributes.accounts.find((rm) => rm.id.toString() === ar.id)
      );
    } else {
      accArr = this.state.accountsData;
    }

    this.setState({
      id: group.id,
      name: group.attributes.name,
      modalAccData: accArr,
    });
    this.showHideAddAccountModal();
  };

  showHideDeleteAccountModal = () => {
    this.setState({
      isVisibleDeleteAccountModal: !this.state.isVisibleDeleteAccountModal,
      editMode: true,
    });
  };

  handleDeleteAccounts = (group: Group) => {
    const accData = group.attributes.accounts.map((account: GroupAccount) => {
      return { ...account, isSelected: false };
    });
    this.setState({
      id: group.id,
      name: group.attributes.name,
      selectedAccounts: accData,
    });
    this.showHideDeleteAccountModal();
  };

  handleEditGroup = (group: Group) => {
    this.setState({ id: group.id, name: group.attributes.name });
    this.showEditModal();
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  expandAccountsView = () => {
    this.setState({ dropdownAccountStatus: !this.state.dropdownAccountStatus });
  };

  handleAccountSelect = (accountId: string) => {
    const newData = this.state.modalAccData.map((account: Account) => {
      if (account.id === accountId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ modalAccData: newData });
  };

  handleDeleteAccountSelect = (groupAccountId: number) => {
    const newData = this.state.selectedAccounts.map((account: GroupAccount) => {
      if (account.id === groupAccountId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ selectedAccounts: newData });
  };

  getIndividualTeam = async (id:number) => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIndividualTeamApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_team/teams/${id}/show_individual_team`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetGroups = () => {
    this.getGroups(this.state.token);
    this.getAccounts(this.state.token);
  };

  // Function to fetch the group list from API
  getGroups = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to add new group and send it to API
  addGroup = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({ fieldError: true });
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        name: this.state.name,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.groupsApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to edit group and send it to API
  editGroup = (groupId: string) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({ fieldError: true });
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        name: this.state.name,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.groupsApiEndPoint + "/" + `${groupId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to delete group and send it to API
  deleteGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch the accounts list from API
  getAccounts = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksAccountsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to save accounts to the group and send it to API
  handleSaveAccountsToGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const accountIds: string[] = [];
    this.state.modalAccData.map((account: Account) => {
      if (account.isSelected) {
        accountIds.push(account.id);
      }
    });
    const httpBody = {
      account_ids: accountIds,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAddAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}` + "/add_accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to remove accounts from the group and send it to API
  handleRemoveAccountsToGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const accountIds: string[] = [];
    this.state.selectedAccounts.map((account: GroupAccount) => {
      if (account.isSelected) {
        accountIds.push(account.id.toString());
      }
    });
    const httpBody = {
      account_ids: accountIds,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRemoveAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}` + "/remove_accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
