import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import { Button, Menu, MenuItem } from "@material-ui/core";

interface myProps {
    index: number
    classes: any
    configJSON: any
    onReplaceFile: (acceptedFile: any, index: number) => void
    onRemoveFile: (index: number) => void
}

export const MenuFilterItem = ({ index, classes, configJSON, onReplaceFile, onRemoveFile }: myProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleRemove = (index: number) => {
        handleClose()
        onRemoveFile(index)
    }

    return (
        <Dropzone multiple={false} noDrag noKeyboard onDrop={(acceptedFile) => {
            handleClose()
            onReplaceFile(acceptedFile, index)
        }}>
            {({ getInputProps, getRootProps }) => (
                <div>
                    <Button
                        data-test-id={`open-menu-${index}`}
                        className={classes?.openMenuButton}
                        onClick={handleClick}
                    >
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" rx="8" fill="white"/>
                            <path d="M16 14C14.9 14 14 14.9 14 16C14 17.1 14.9 18 16 18C17.1 18 18 17.1 18 16C18 14.9 17.1 14 16 14ZM16 8C14.9 8 14 8.9 14 10C14 11.1 14.9 12 16 12C17.1 12 18 11.1 18 10C18 8.9 17.1 8 16 8ZM16 20C14.9 20 14 20.9 14 22C14 23.1 14.9 24 16 24C17.1 24 18 23.1 18 22C18 20.9 17.1 20 16 20Z" fill="#30353B"/>
                        </svg>
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: webStyles.menuStyle
                        }}
                    >
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <MenuItem data-test-id="replace-file">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={webStyles.iconMenu}>
                                    <path d="M7.5 8.24981H5.32575L5.3265 8.24306C5.42443 7.76353 5.6161 7.30807 5.8905 6.90281C6.29884 6.30117 6.87161 5.82968 7.5405 5.54456C7.767 5.44856 8.00325 5.37506 8.244 5.32631C8.74342 5.22505 9.25808 5.22505 9.7575 5.32631C10.4752 5.47338 11.1338 5.82837 11.6512 6.34706L12.7133 5.28806C12.2346 4.80929 11.6683 4.42709 11.0452 4.16231C10.7275 4.02778 10.3971 3.92527 10.059 3.85631C9.36138 3.71456 8.64237 3.71456 7.94475 3.85631C7.60639 3.92556 7.27576 4.02831 6.95775 4.16306C6.02065 4.56063 5.21845 5.22062 4.64775 6.06356C4.26367 6.63195 3.99507 7.2703 3.85725 7.94231C3.83625 8.04356 3.825 8.14706 3.81 8.24981H1.5L4.5 11.2498L7.5 8.24981ZM10.5 9.74981H12.6742L12.6735 9.75581C12.4775 10.7171 11.9109 11.5626 11.0963 12.1093C10.6911 12.3839 10.2356 12.5756 9.756 12.6733C9.25683 12.7746 8.74242 12.7746 8.24325 12.6733C7.76373 12.5754 7.30826 12.3837 6.903 12.1093C6.70397 11.9747 6.51814 11.8215 6.348 11.6518L5.2875 12.7123C5.76638 13.1909 6.33294 13.5729 6.95625 13.8373C7.27425 13.9723 7.6065 14.0751 7.9425 14.1433C8.63986 14.2851 9.35864 14.2851 10.056 14.1433C11.4004 13.8643 12.583 13.0719 13.3522 11.9346C13.736 11.3666 14.0043 10.7288 14.142 10.0573C14.1623 9.95606 14.1743 9.85256 14.1893 9.74981H16.5L13.5 6.74981L10.5 9.74981Z" fill="#30353B"/>
                                </svg>
                                {configJSON.replaceFilesText}
                            </MenuItem>
                        </div>
                        <MenuItem data-test-id="remove-file" onClick={() => handleRemove(index)}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={webStyles.iconMenu}>
                                <path d="M3.75 15C3.75 15.3978 3.90804 15.7794 4.18934 16.0607C4.47064 16.342 4.85218 16.5 5.25 16.5H12.75C13.1478 16.5 13.5294 16.342 13.8107 16.0607C14.092 15.7794 14.25 15.3978 14.25 15V6H15.75V4.5H12.75V3C12.75 2.60218 12.592 2.22064 12.3107 1.93934C12.0294 1.65804 11.6478 1.5 11.25 1.5H6.75C6.35218 1.5 5.97064 1.65804 5.68934 1.93934C5.40804 2.22064 5.25 2.60218 5.25 3V4.5H2.25V6H3.75V15ZM6.75 3H11.25V4.5H6.75V3ZM12.75 6V15H5.25V6H12.75Z" fill="#DC2626"/>
                                <path d="M6.75 7.5H8.25V13.5H6.75V7.5ZM9.75 7.5H11.25V13.5H9.75V7.5Z" fill="#DC2626"/>
                            </svg>
                            {configJSON.removeFilesText}
                        </MenuItem>
                    </Menu>
                </div>
            )}
        </Dropzone>
    )
}

const webStyles = {
    menuStyle: {
        transform: 'translateX(10px) translateY(50px)',
        borderRadius: "8px",
    },
    iconMenu: {
        width: "18px",
        height: "18px",
        marginRight: "8px"
    },
}
