import React from "react";

// Customizable Area Start
import { Container, TextField, Button,  Typography, Box, IconButton, InputAdornment} from '@material-ui/core';
import { ThemeProvider, createTheme} from '@material-ui/core/styles';
import { logoText, backgroundImage,successIcon,imgVisbilityOff,imgVisbility} from './assets';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const theme = createTheme();
// Customizable Area End

import NewPasswordController, {
  Props,
} from "./NewPasswordController";

export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPasswordCheck=()=>{
    return(
      <div style={styless.root}>
                  <Typography variant="h6" gutterBottom style={{ marginTop:"32px",fontSize: '16px', fontWeight: 400, color: 'rgba(48, 53, 59, 1)', fontFamily: 'DM Sans' }}>
                    Your password must contain
                  </Typography>
                  <div style={styless.listItem}>
                  {this.state.passwordCheckUppercase ? <CheckCircleIcon style={{...styless.icon,color:"green"}} /> : <CheckCircleIcon style={styless.icon} />}
                    <Typography style={styless.typo}>At least one capital letter</Typography>
                  </div>
                  <div style={styless.listItem}>
                  {this.state.passwordCheckLowercase ? <CheckCircleIcon style={{...styless.icon,color:"green"}} /> : <CheckCircleIcon style={styless.icon} />}
                    <Typography style={styless.typo}>At least one lowercase letter</Typography>
                  </div>
                  <div style={styless.listItem}>
                  {this.state.passwordCheckNumber ? <CheckCircleIcon style={{...styless.icon,color:"green"}} /> : <CheckCircleIcon style={styless.icon} />}
                    <Typography style={styless.typo}>At least one number</Typography>
                  </div>
                  <div style={styless.listItem}>
                    {this.state.passwordCheckMinLength ? <CheckCircleIcon style={{...styless.icon,color:"green"}} /> : <CheckCircleIcon style={styless.icon} />}
                    <Typography style={styless.typo}>Minimum character length is 8 characters</Typography>
                  </div>
                </div>
    )
  }
  // Customizable Area End
  render() {
  // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
      <div style={{
        background: `url(${backgroundImage}) no-repeat center center`,backgroundSize: 'cover',width: '100%',height: '1000px',paddingBottom: '52px'
      }}>
      <div style={{ paddingTop: '48px', 
      paddingLeft: '80px' }}>
        <img style={{ width: '141.05px', 
        height: '28px', gap: '2.8px ' }} src={logoText} />
      </div>
      <div style={{ display: 'flex', 
      justifyContent: 'center' }}>
        <ThemeProvider theme={theme}>
          <Container maxWidth="sm" 
          style={{ margin: '42px 0 100px 0' }}>
            <Box style={{  height: '620px', 
            width: '464px' }} display="flex" flexDirection="column" alignItems="center"  >
             { !this.state.isButtonClicked ?(  
                <>
            <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '32px', fontWeight: 500,alignContent:"center",lineHeight:"41.6px",color:"#14101E" }} > 
            New password
                  </Typography>

            <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400,textAlign:"center",lineHeight:"28px",color:"#30353B" }} > 
            Enter a new password for <span style={{fontWeight: 700}}>{this.state.emailValue}</span>
                  </Typography>
                  <Box style={{ width: '100%' }}>
                  <Typography style={{ marginTop:'32px' , fontFamily: 'DM Sans', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }}>New password</Typography>
                  <TextField
                  data-test-id="txtinputPassword"
                  style={{
                    borderRadius: '8px',
                    border: '1px  rgba(226, 232, 240, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 1)',  
                  }}
                    name='password'
                    placeholder="Enter your password"
                    value={this.state.password}
                    type={this.state.showPassword ? 'password' : 'text'}
                    onChange={this.handlePasswordChange}
                    variant="outlined" fullWidth required
                    InputProps={{endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.handleClickShowPassword}
                          >
                              <img 
            src={this.state.showPassword ? imgVisbilityOff : imgVisbility} 
            alt="toggle visibility" 
            style={{ width: 24, height: 24 }} 
          />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {(this.state.passwordError&&this.state.password) ?
                    <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans !important', fontWeight: 400 }}>The new password cannot be the same as the old password or Use at least 8 characters. Include an uppercase, lowercase and a number </Box> :
                    null}

                </Box>

                <Box style={{ width: '100%' }}>
                  <Typography style={{marginTop:'32px' , marginBottom: '4px', fontFamily: 'DM Sans !important', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }}>Password confirmation</Typography>
                  <TextField
                  style={{
                    borderRadius: '8px',
                    border: '1px  rgba(226, 232, 240, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 1)',

                  }}
                    name='confirmPassword'
                    data-test-id="txtinputConfirmPassword"
                    placeholder="Enter password confirmation"
                    value={this.state.confirmPassword}
                    type={this.state.showPasswordConfirm ? 'password' : 'text'}
                    onChange={this.handlePasswordChange}
                    variant="outlined" fullWidth required
                    InputProps={{ endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.handleClickShowPasswordConfirm}
                          >
                            <img 
            src={this.state.showPasswordConfirm ? imgVisbilityOff : imgVisbility} 
            alt="toggle visibility" 
            style={{ width: 24, height: 24 }} 
          />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
               {this.renderPasswordCheck()}
                <Button
                data-test-id="btnresetPassword"
  variant="contained"
  color="primary"
  fullWidth
  style={{
    marginTop: "10px",
    color: 'rgba(255, 255, 255, 1)',
    backgroundColor: this.state.isButtonDisabled ? '#94A3B8' : '#684EF3',
    fontSize: '16px',
    fontWeight: 400,
    borderRadius: '8px',
    gap: '8px',
    padding: '16px',
    textTransform: 'none',
  }}
  onClick={this.handleResetPassword}
  disabled={this.state.isButtonDisabled}
>
  Set new password
</Button>

                  <Box style={{ marginTop: '100px' }}>
                  <Typography variant="body2" style={{ fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400 }} > 
                    <span data-test-id="btngoToLogin"style={{ left:"10px", textDecoration: 'none', color: 'rgba(104, 78, 243, 1)', cursor:"pointer" }} onClick={this.goToLogin}>Cancel and go to Log In screen</span>
                  </Typography>
                </Box></>):( <div style={{marginTop:"100px",display:"flex",flexDirection:"column", width:"100%"}}>
            <img style={{ width: '72px', height: '72px', gap: '2.8px ', alignSelf:"center" }} src={successIcon} />
            <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '32px', fontWeight: 500,alignSelf:"center" ,lineHeight:"41.6px",color:"#14101E" }} > 
            Password reset!
                  </Typography>

            <Typography variant="body2" style={{ marginTop:"10px",fontFamily: 'Dm Sans', fontSize: '16px', fontWeight: 400,textAlign:"center",lineHeight:"28px",color:"#30353B", alignSelf:"center" }} > 
            Your password has been successfully reset
                  </Typography>
                  <Button
                    data-test-id="btngotologin2"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop:"50px",color: 'rgba(255, 255, 255, 1)',  backgroundColor:"#684EF3", fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px' ,textTransform: 'none' }}
                    onClick={this.goToLogin}
                  >
                    Continue log in
                  </Button></div>)}
                 
               
              </Box>
            </Container>
          </ThemeProvider>
        </div>
      </div>
      // Customizable Area End
    );
  }

}

const styless = {
    root: {
    //   backgroundColor: '#f5f5f5',
      borderRadius: '4px',
      width: '100%',
      marginBottom: '24px'
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    icon: {
      color: 'rgba(148, 163, 184, 1)',
      marginRight: '8px',
      heigth: '14px',
      width: '14px'
    },
    typo: {
        marginTop: '8px',
        fontSize: '14px',
        fontWeight: 400,
        color: 'rgba(48, 53, 59, 1)'
      }
    };
