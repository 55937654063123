// Customizable Area Start
// @ts-ignore
import DatePicker from "react-datepicker";
import React from "react";
import { Box, Typography, Button, TextField, Modal, MenuItem, withStyles, Switch, FormControl, InputAdornment, Snackbar } from "@material-ui/core";
import { backgroundName, notificationDisabled } from './assets';
import UserProfileBasicControllerWeb, { Props } from "./UserProfileBasicController.web";
import Avatar from '@material-ui/core/Avatar';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Select from '@material-ui/core/Select';
import ConfirmationDialog from "../../../../packages/blocks/accountgroups/src/ConfirmationDialog.web";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import TimezoneSelect from "react-timezone-select"
import { SelectComponent } from "../../../../packages/blocks/accountgroups/src/WorkspaceList.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from "@material-ui/lab";
import "react-datepicker/dist/react-datepicker.css";
import UserProfileDialog from "./UserProfileDialog.web";
import Loader from "../../../../packages/components/src/Loader.web";
import { ItemNotiWorkspaceSetting } from "../../../../packages/components/src/ItemNotiWorkspaceSetting.web";

const CustomTimeInput = React.forwardRef(
    ({ value, onClick }: any, ref: any) => (
      <button style={webStyles.inputTime} onClick={onClick} ref={ref}>
        {value}
        <svg style={webStyles.dropDownTime} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.793 8.30957L12.5 12.6026L8.20697 8.30957L6.79297 9.72357L12.5 15.4306L18.207 9.72357L16.793 8.30957Z" fill="#30353B"/>
        </svg>
      </button>
    ),
);

export class UserProfileBasicBlockWeb extends UserProfileBasicControllerWeb {
    constructor(props: Props) {
        super(props);

    }

    renderButtonScreen = (
        testId: string,
        input: 'general' | 'notifications' | 'accounts',
        title: string,
        isChosen: boolean
    ) => {
        return (
            <div
                data-test-id={testId}
                onClick={() => this.handlePageChange(input)}
                style={isChosen ? webStyles.chosenPage : webStyles.normalPage}
            >
                {title}
            </div>
        )
    }

    renderInputField = (
        id: string,
        value: string,
        placeholder: string,
        onChangeValue: (value: string) => void,
        isMultiline: boolean,
        error?: string,
    ) => {
        return (
            <div style={webStyles.inputView}>
                <TextField
                    className={this.props.classes?.inputText}
                    data-test-id={`inputText_${id}`}
                    value={value}
                    onChange={(event) => onChangeValue(event.target.value)}
                    style={{
                        minHeight: isMultiline ? "72px" : "24px"
                    }}
                    multiline={isMultiline}
                    placeholder={placeholder}
                    InputProps={{
                        disableUnderline: true,
                        className: this.props.classes?.inputStyle
                    }}
                />
                {
                    error !== "" && <span className={this.props.classes?.errorText}>{error}</span>
                }
            </div>
        )
    }

    renderTimePicker = (
        id: string,
        value: Date,
        onChangeValue: (date: Date | null) => void
    ) => {
        return (
            <div style={{ position: "relative", width: "46%" }}>
                <DatePicker
                    wrapperClassName="datePicker" 
                    data-test-id={id}
                    selected={value}
                    onChange={(date: Date | null) => onChangeValue(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    customInput={<CustomTimeInput />}
                />
            </div>
        )
    }

    renderActive = () => {
        return (
            <FormControl variant="filled">
                <SelectComponent
                    data-test-id={`renderDropdown`}
                    className={this.props.classes?.customdropdown}
                    value={this.state.active}
                    IconComponent={() => <div />}
                    endAdornment={(
                        <InputAdornment position="end" className={this.props.classes?.viewIconDropdown}>
                            <ExpandMoreIcon style={webStyles.iconExpand} />
                        </InputAdornment>
                    )}
                    displayEmpty={true}
                    MenuProps={{
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    },
                    }}
                    onChange={this.handleChangeActive}
                >
                    <MenuItem
                        className={this.props.classes?.dropdownitem}
                        value="Active"
                        data-test-id="Active"
                    >
                        Active
                    </MenuItem>
                    <MenuItem
                        className={this.props.classes?.dropdownitem}
                        value="Inactive"
                        data-test-id="Inactive"
                    >
                        Inactive
                    </MenuItem>
                </SelectComponent>
            </FormControl>
        )
    }

    renderDialogButton = () => {
        if (this.state.showButtonDialog) {
            return (
                <div style={webStyles.viewDialogButton}>
                    <div data-test-id="discardButton" style={webStyles.buttonDiscardChange} onClick={this.onDiscardChanges}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.192 6.84375L11.949 11.0858L7.70697 6.84375L6.29297 8.25775L10.535 12.4998L6.29297 16.7418L7.70697 18.1558L11.949 13.9137L16.192 18.1558L17.606 16.7418L13.364 12.4998L17.606 8.25775L16.192 6.84375Z" fill="#684EF3"/>
                        </svg>
                        Discard changes
                    </div>
                    <div  data-test-id="updateButton" style={webStyles.buttonUpdateChange} onClick={this.onUpdateChanges}>Update profile</div>
                </div>
            )
        } else return <div />
    }

    renderGeneralPage = () => {
        if (this.state.currentPage === 'general') {
            const fullName = `${this.state.currentUser?.attributes?.first_name ?? ""} ${this.state.currentUser?.attributes?.last_name ?? ""}`
            return (
                <Box style={webStyles.containerPage}>
                    <Box style={{ padding: "20px 0 20px 24px" }}>
                        <Box style={webStyles.viewInfoName}>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Avatar key={this.state.currentUser?.attributes?.profile_photo ?? ""} alt="avatar" src={this.state.currentUser?.attributes?.profile_photo || ""} style={webStyles.avatar}>
                                    {this.state.currentUser?.attributes?.first_name?.charAt(0) ?? ""}
                                </Avatar>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                               <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
    <Typography style={webStyles.nameText}>{fullName}</Typography>
    
    <Typography style={webStyles.positionText}>
        {this.state.currentUser?.attributes?.position || ""}
        {this.state.currentUser?.attributes?.company ? ` at ${this.state.currentUser.attributes.company}` : ""}
    </Typography>
    
    {this.renderActive()}
</Box>
                                <svg data-test-id="editIcon" onClick={this.handleEditShowProfile} style={webStyles.editIcon} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.9987 30.9997C14.0784 31.0093 14.159 31.0093 14.2387 30.9997L18.2387 29.9997C18.4161 29.9575 18.5786 29.8676 18.7087 29.7397L30.9987 17.4097C31.3712 17.0349 31.5803 16.528 31.5803 15.9997C31.5803 15.4713 31.3712 14.9644 30.9987 14.5897L29.4187 12.9997C29.2329 12.8137 29.0123 12.6662 28.7696 12.5655C28.5268 12.4649 28.2665 12.4131 28.0037 12.4131C27.7408 12.4131 27.4806 12.4649 27.2378 12.5655C26.995 12.6662 26.7744 12.8137 26.5887 12.9997L14.2987 25.2897C14.1695 25.4203 14.0764 25.5822 14.0287 25.7597L13.0287 29.7597C12.9927 29.889 12.9832 30.0244 13.0007 30.1575C13.0182 30.2906 13.0623 30.4189 13.1304 30.5346C13.1986 30.6504 13.2893 30.7512 13.3972 30.8311C13.5052 30.911 13.6281 30.9683 13.7587 30.9997C13.8384 31.0093 13.919 31.0093 13.9987 30.9997ZM27.9987 14.4097L29.5887 15.9997L27.9987 17.5897L26.4187 15.9997L27.9987 14.4097ZM15.9087 26.5097L24.9987 17.4097L26.5887 18.9997L17.4887 28.0997L15.3787 28.6197L15.9087 26.5097Z" fill="#5E6671"/>
                                </svg>
                            </Box>
                        </Box>
                    </Box>

                    <Box style={webStyles.viewMainInfo}>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box  >
                                    <Typography style={webStyles.titleField}>Displayed Name</Typography>
                                    <Typography style={webStyles.descriptionField}>How your name will appear to user</Typography>
                                </Box>
                                {this.renderInputField(
                                    "displayName",
                                    this.state.displayNameValue,
                                    "Please fill your display name",
                                    this.onChangeDisplayName,
                                    false
                                )}
                            </Box>

                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box >
                                    <Typography style={webStyles.titleField}>Bio</Typography>
                                </Box>
                                {this.renderInputField(
                                    "bio",
                                    this.state.bioValue,
                                    "Please fill your bio",
                                    this.onChangeBio,
                                    false
                                )}
                            </Box>

                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box  >
                                    <Typography style={webStyles.titleField}>Email</Typography>
                                    <Typography style={webStyles.descriptionField}>Alternative email to contact you.Will be shown for <br />other users. Can be differnt from an email registered<br /> for your account.</Typography>
                                </Box>
                                {this.renderInputField(
                                    "email",
                                    this.state.emailValue,
                                    "Please fill your alternate email",
                                    this.onChangeEmail,
                                    true
                                )}
                            </Box>
                        </Box>

                        <hr style={webStyles.divider} />

                        <Box style={webStyles.viewPicker}>
                            <Box >
                                <Typography style={webStyles.titleField}>Time zone</Typography>
                            </Box>
                            <Box style={webStyles.dropdownView}>
                                <div style={{ width: "100%" }}>
                                    <TimezoneSelect
                                        // @ts-ignore
                                        menuPortalTarget={document.body} 
                                        // @ts-ignore
                                        menuPosition={'fixed'}
                                        value={this.state.timezone}
                                        onChange={this.onChangeTimezone}
                                    />
                                </div>
                            </Box>
                        </Box>
                        <div style={{ height: "24px" }} />
                        <Box style={webStyles.viewPicker}>
                            <Box >
                                <Typography style={webStyles.titleField}>Working Hours</Typography>
                            </Box>
                            <Box style={webStyles.dropdownView}>
                                {this.renderTimePicker("startDate", this.state.startDate, this.onChangeStartDate)}
                                <span>-</span>
                                {this.renderTimePicker("endDate", this.state.endDate, this.onChangeEndDate)}
                            </Box>
                        </Box>
                    </Box>
                    {this.renderDialogButton()}
                </Box>
            )
        } else return <div />
    }

    renderListNotiSetting = () => {
        if (this.state.showNotiList) {
            return (
                <div style={{ marginTop: "24px", marginBottom: "7px", gap: "24px" }}>
                    {
                        this.state.listWorkspaceNoti.map((item) => (
                            <ItemNotiWorkspaceSetting item={item} />
                        ))
                    }
                </div>
            )
        } else return <div />
    }

    renderNotificationPage = () => {
        if (this.state.currentPage === 'notifications') {
            return (
                <Box style={webStyles.containerPage}>
                    <Box style={{ ...webStyles.viewNotiTitle, ...{ borderBottomWidth: this.state.isNotificationsEnabled ? 1 : 0 }}}>
                        <Box>
                            <Typography style={webStyles.headerNotiTitle}>Notifications & Alerts</Typography>
                            <Typography style={webStyles.descriptionField}> I want to show notifications and alerts</Typography>
                        </Box>
    
                        <Box>
                            <BlueSwitch data-test-id='shownotification' checked={this.state.isNotificationsEnabled} onChange={this.handleNotificationsToggle} />
                        </Box>
                    </Box>
    
                    {this.state.isNotificationsEnabled ? (
                        <>
                            <Box style={webStyles.viewNotiDetail}>
                                <Box>
                                    <Typography style={{ fontSize: "16px", fontWeight: 700 }}>Always send an email notification</Typography>
                                    <Typography style={{ color: "grey" }}> Receives email about activity in your workspace,even you are active on the app</Typography>
                                </Box>
    
                                <Box>
                                    <BlueSwitch />
                                </Box>
    
                            </Box>
    
                            <Box style={webStyles.viewNotiList}>
                                <div style={webStyles.viewHeaderNotiList}>
                                    <Box>
                                        <Typography style={webStyles.titleField}>Activity in your workspaces</Typography>
                                        <Typography style={webStyles.descriptionField}>Receives email when you get comments, mentions, invites, access reuquest and property changes</Typography>
                                    </Box>
                                    <Box>
                                        <BlueSwitch data-test-id='showNotiList' checked={this.state.showNotiList} onChange={this.handleNotificationsListToggle} />
                                    </Box>
                                </div>
                                {this.renderListNotiSetting()}
                            </Box>
                            <Box style={webStyles.viewNotiDetail}>
                                <Box>
                                    <Typography style={webStyles.titleField}>Security Alerts</Typography>
                                    <Typography style={webStyles.descriptionField}> Receives email about activity in your workspace,even you are active on the app</Typography>
                                </Box>
    
                                <Box>
                                    <BlueSwitch />
                                </Box>
                            </Box>
                            <Box style={webStyles.viewNotiDetail}>
                                <Box>
                                    <Typography style={webStyles.titleField}>Announcement and update email</Typography>
                                    <Typography style={webStyles.descriptionField}>Receive occasional emails about product launches and new features from EmergeGen</Typography>
                                </Box>
    
                                <Box>
                                    <Switch />
                                </Box>
                            </Box>
                        </>
                    ) : (
                        !this.state.isNotificationsEnabled && (
                            <img src={notificationDisabled} alt="nonotification" style={webStyles.emptyNoti} />
                        )
                    )}
    
                </Box>
            )
        } else return <div />
    }

    renderPage = () => {
        if (!this.state.showPage) {
            return (
                <Box style={{ background: "#f6f5f9", width: "100%", height: "100%" }}>
                    <Box style={webStyles.viewHeaderApp}>
                        <Typography style={webStyles.headerAppProfile}>Profile settings</Typography>
                        <svg style={webStyles.notiIcon} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29 23.586V20C29 16.783 26.815 14.073 23.855 13.258C23.562 12.52 22.846 12 22 12C21.154 12 20.438 12.52 20.145 13.258C17.185 14.074 15 16.783 15 20V23.586L13.293 25.293C13.2 25.3857 13.1262 25.4959 13.0759 25.6172C13.0256 25.7386 12.9998 25.8687 13 26V28C13 28.2652 13.1054 28.5196 13.2929 28.7071C13.4804 28.8946 13.7348 29 14 29H30C30.2652 29 30.5196 28.8946 30.7071 28.7071C30.8946 28.5196 31 28.2652 31 28V26C31.0002 25.8687 30.9744 25.7386 30.9241 25.6172C30.8738 25.4959 30.8 25.3857 30.707 25.293L29 23.586ZM29 27H15V26.414L16.707 24.707C16.8 24.6143 16.8738 24.5041 16.9241 24.3828C16.9744 24.2614 17.0002 24.1313 17 24V20C17 17.243 19.243 15 22 15C24.757 15 27 17.243 27 20V24C27 24.266 27.105 24.52 27.293 24.707L29 26.414V27ZM22 32C22.6193 32.0008 23.2235 31.8086 23.7285 31.4502C24.2335 31.0917 24.6143 30.5849 24.818 30H19.182C19.3857 30.5849 19.7665 31.0917 20.2715 31.4502C20.7765 31.8086 21.3807 32.0008 22 32Z" fill="#14101E"/>
                        </svg>
                    </Box>
                    <Box style={{ display: "flex", height: "89.55vh" }}>
                        <Box style={webStyles.viewLeftContent}>
                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                {this.renderButtonScreen("generalButtonScreen", "general", "General", this.state.currentPage === "general")}
                                {this.renderButtonScreen("notificationsButtonScreen", "notifications", "Notfications and alerts", this.state.currentPage === "notifications")}
                                {this.renderButtonScreen("accountsButtonScreen", "accounts", "Accounts and security", this.state.currentPage === "accounts")}
                            </Box>

                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                <div style={webStyles.viewTutorialSwitch}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={webStyles.textTutorial}>Tutorial guide</div>
                                        <div style={webStyles.textDescriptionTutorial}>Activate tutorials guidance to use EmergeGen as a Pro </div>
                                    </div>
                                    <BlueSwitch data-test-id='tutorialSwitch' checked={this.state.isTutorialEnabled} onChange={this.handleTutorialToggle} />
                                </div>
                                <Box style={webStyles.normalPage}>
                                    <ChatOutlinedIcon />
                                    <Typography style={webStyles.helpButton}>Help & support</Typography>
                                </Box>
                                <Box data-test-id='logout' onClick={this.handleLogoutModal} style={webStyles.viewLogOutButton}>
                                    <ArrowBackOutlinedIcon style={{ color: "red" }} />
                                    <Typography style={webStyles.logOutButton}>Log out</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {this.renderGeneralPage()}

                        {this.renderNotificationPage()}

                        {this.state.currentPage === 'accounts' && (

                            <Box style={{ width: "100%", flexDirection: "column", display: "flex", gap: "6px" }}>

                                <Box style={{ padding: "1.4rem 3rem" }}>
                                    <Typography style={{ fontSize: "24px", fontWeight: 400 }}>
                                        Security settings
                                    </Typography>
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Box style={{ display: "flex", flexDirection: "column", paddingLeft: "3rem" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Tutorial guide
                                        </Typography>
                                        <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                                            Activate tutorials guidance to use Emergen as a Pro
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "flex", paddingRight: "4rem" }}>
                                        <BlueSwitch />
                                    </Box>
                                </Box>
                                <hr style={{ width: "93%", color: "#E2E8F0" }} />
                                <Box data-test-id='changeemail' onClick={this.showChangeEmail} style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Box style={{ display: "flex", flexDirection: "column", paddingLeft: "3rem" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Change email address
                                        </Typography>
                                        <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                                            {this.state.currentUser?.attributes?.email ?? ""}
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "flex", paddingRight: "4rem" }}>
                                        <NavigateNextIcon />
                                    </Box>
                                </Box>
                                <hr style={{ width: "93%", color: "#E2E8F0" }} />

                                <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Box style={{ display: "flex", flexDirection: "column", paddingLeft: "3rem" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Two-factor authenication
                                        </Typography>
                                        <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                                            Additional verification to your account
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "flex", paddingRight: "4rem" }}>
                                        <NavigateNextIcon />
                                    </Box>
                                </Box>
                                <hr style={{ width: "93%", color: "#E2E8F0" }} />

                                <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Box style={{ display: "flex", flexDirection: "column", paddingLeft: "3rem" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Change Password
                                        </Typography>
                                        <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                                            User a strong password
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "flex", paddingRight: "4rem" }}>
                                        <NavigateNextIcon />
                                    </Box>
                                </Box>
                                <hr style={{ width: "93%", color: "#E2E8F0" }} />

                                <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Box style={{ display: "flex", flexDirection: "column", paddingLeft: "3rem" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Who can see your activity status?
                                        </Typography>

                                    </Box>
                                    <Box style={{ display: "flex", paddingRight: "4rem" }}>
                                        <NavigateNextIcon />
                                    </Box>
                                </Box>
                                <hr style={{ width: "93%", color: "#E2E8F0" }} />

                                <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Box style={{ display: "flex", flexDirection: "column", paddingLeft: "3rem" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Who can see your contact information?
                                        </Typography>

                                    </Box>
                                    <Box style={{ display: "flex", paddingRight: "4rem" }}>
                                        <Select style={{ width: "100px" }}><option>Ten</option>
                                            <option>Two</option>
                                        </Select>
                                    </Box>
                                </Box>
                                <hr style={{ width: "93%", color: "#E2E8F0" }} />

                                <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Box style={{ display: "flex", flexDirection: "column", paddingLeft: "3rem" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700 }}>
                                            Terms and Conditions
                                        </Typography>

                                    </Box>
                                    <Box style={{ display: "flex", paddingRight: "4rem" }}>
                                        <Select style={{ width: "100px" }} ><option>Ten</option>
                                            <option>Two</option>
                                        </Select>
                                    </Box>
                                </Box>
                                <hr style={{ width: "93%", color: "#E2E8F0" }} />
                                <Box style={{ display: "flex", flexDirection: "column", height: "100%", paddingTop: "6rem" }}>
                                    <Box style={{ display: 'flex', background: "#FEE2E2", justifyContent: "space-between", alignItems: "end", height: "100%", padding: "1rem 0rem" }}>
                                        <Box data-test-id='previous' onClick={this.handleBackToModal} style={{ display: "flex", flexDirection: "column", paddingLeft: "3rem" }}>
                                            <Typography style={{ fontSize: "16px", fontWeight: 700, color: 'red' }}>
                                                Delete an account
                                            </Typography>
                                            <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                                                Delete your account and all account data
                                            </Typography>
                                        </Box>
                                        <Box style={{ display: "flex", paddingRight: "4rem" }}>
                                            <NavigateNextIcon />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )
        } else return <div />
    }

    render() {
        return (
            <Box style={{ width: "100%", display: "flex", justifyContent: "center" }} >
                {this.renderPage()}

                <ConfirmationDialog
                    id="DeleteAccountDialog"
                    isDialogOpen={this.state.showModal}
                    onCloseDialog={this.handleBackToModal}
                    title={"Are you sure you want to delete an account?"}
                    description={"Please confirm you want to delete an account. This action will delete all your personal data."}
                    titleCancelButton={"Back to Profile Settings"}
                    titleOkButton={"Delete account and data"}
                    onOkCloseDialog={this.onOkDeleteDialog}
                    checkConfirmationTitle="I confirm I want to delete my account and data"
                />

                <ConfirmationDialog
                    id="ConfirmationDialog"
                    isDialogOpen={this.state.showLogoutModal}
                    onCloseDialog={this.handleLogoutModal}
                    title={"Are you sure you want to log out?"}
                    description={"Please confirm you want to log out."}
                    titleCancelButton={"Back to Profile Settings"}
                    titleOkButton={"Log out"}
                    onOkCloseDialog={this.onOkLogOutDialog}
                />

                <UserProfileDialog
                    id="UserProfileDialog"
                    isDialogOpen={this.state.showEditModal}
                    onCloseDialog={this.handleEditShowProfile}
                    classes={this.props?.classes}
                    isEdit
                    onSuccessDialog={this.reloadProfile}
                />
                <Loader loading={this.state.loading} />

                <Modal open={this.state.showChangeEmail} >
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
                        <Box style={{ width: "496px", height: "396px", position: "absolute", left: "35%", background: "white", borderRadius: "24px" }}>

                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "82px", borderBottom: "0.5px solid lightgray" }}>
                                <Typography style={{ fontSize: "24px", fontWeight: 400 }}>Change email address</Typography>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                                <Box style={{ width: "100%", height: "50px", display: "flex", flexDirection: "column", padding: "1rem 2rem" }}>
                                    <Typography style={{ fontSize: "16px", fontWeight: 400 }}>
                                        New email
                                    </Typography>
                                    <TextField variant="outlined" style={{ height: "44px", width: "400px", borderRadius: "20px" }} />
                                </Box>

                                <Box style={{ width: "100%", height: "50px", display: "flex", flexDirection: "column", padding: "1rem 2rem" }}>
                                    <Typography style={{ fontSize: "16px", fontWeight: 400 }}>
                                        Confirm new email
                                    </Typography>
                                    <TextField variant="outlined" style={{ height: "44px", width: "400px", borderRadius: "20px" }} />
                                </Box>
                            </Box>

                            <Box style={{ display: "flex", alignItems: "center", gap: "2rem", justifyContent: "space-evenly", paddingTop: "3rem", width: "100%" }}>
                                <Button onClick={this.showChangeEmail} style={{ color: "#684EF3", width: "104px", height: "44px", padding: "1.2rem", border: "1px solid #684EF3", textTransform: "none" }}>
                                    Cancel
                                </Button>
                                <Button style={{ background: "#94A3B8", color: "white", width: "312px", height: "44px", padding: "1.2rem", textTransform: "none" }}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.isSnackbarOpen}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                >
                    <Alert
                        icon={
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.4933 7.28613L11.9575 10.8211L8.42247 7.28613L7.24414 8.46447L10.7791 11.9995L7.24414 15.5345L8.42247 16.7128L11.9575 13.1778L15.4933 16.7128L16.6716 15.5345L13.1366 11.9995L16.6716 8.46447L15.4933 7.28613Z" fill="white"/>
                            </svg>
                        }
                        style={webStyles.viewAlert}
                    >
                        {this.state.snackbarContent}
                    </Alert>
                </Snackbar>
            </Box>
        );
    }
}

const webStyles = {
    emptyNoti: {
        height: "70%",
        margin: "0 40px"
    },
    containerPage: {
        width: "100%",
        flexDirection: "column",
        display: "flex",
        position: 'relative',
        overflowY: "auto"
    } as React.CSSProperties,
    viewNotiTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "32px 0",
        margin: "0 40px",
        borderBottom: "1px solid #E2E8F0"
    } as React.CSSProperties,
    viewNotiDetail: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "17px 0",
        margin: "0 40px",
        borderBottom: "1px solid #E2E8F0"
    } as React.CSSProperties,
    viewNotiList: {
        display: "flex",
        flexDirection: "column",
        margin: "0 40px",
        padding: "17px 0",
        borderBottom: "1px solid #E2E8F0",
    } as React.CSSProperties,
    viewHeaderNotiList: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    } as React.CSSProperties,
    itemNotiSetting: {

    } as React.CSSProperties,
    headerAppProfile: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '24px',
        lineHeight: '33.6px',
        fontWeight: 400
    },
    notiIcon: {
        width: "44px",
        height: "44px",
    },
    viewHeaderApp: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#f6f5f9",
        padding: "18px 40px",
        borderBottom: "1px solid #E2E8F0"
    },
    chosenPage: {
        backgroundColor: "#EAE6FF",
        border: "1px solid #D9D1EF",
        borderRadius: "12px",
        padding: "12px",
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        cursor: "pointer",
        fontWeight: 700
    } as React.CSSProperties,
    normalPage: {
        backgroundColor: "transparent",
        padding: "12px",
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: "8px"
    } as React.CSSProperties,
    viewLogOutButton: {
        width: "100%",
        display: "flex",
        gap: "8px",
        cursor: "pointer",
        padding: "12px"
    } as React.CSSProperties,
    viewLeftContent: {
        display: "flex",
        flexDirection: "column",
        width: "26%", 
        background: "#f6f5f9",
        borderRight: "1px solid #E2E8F0",
        padding: "20px 28px",
        justifyContent: "space-between"
    } as React.CSSProperties,
    helpButton: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400
    },
    headerNotiTitle: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: '24px',
        lineHeight: '33.6px',
        fontWeight: 400
    },
    logOutButton: {
        color: "#DC2626",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400
    },
    titleField: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
    },
    descriptionField: {
        color: "#5E6671",
        fontFamily: "DM Sans",
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 400,
    },
    viewInfoName: {
        background: `url(${backgroundName}) no-repeat center center fixed`,
        backgroundColor: "#e2e8f0",
        padding: "12px",
        gap: "28px",
        borderTopLeftRadius: "150px",
        borderBottomLeftRadius: "150px",
        display: "flex",
        alignItems: "center"
    } as React.CSSProperties,
    viewMainInfo: {
        height: "100%",
        background: "#FFFFFF",
        padding: "32px 40px"
    },
    avatar: {
        height: "132px",
        width: "132px",
        borderRadius: "132px",
        border: "4px solid #fff",
    },
    editIcon: {
        height: "44px",
        width: "44px",
        cursor: "pointer"
    },
    nameText: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '24px',
        lineHeight: '33.6px',
        fontWeight: 400,
        minHeight: "34px"
    },
    positionText: {
        color: "#5E6671",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        minHeight: "24px",
        marginBottom: "28px"
    },
    divider: {
        width: "100%",
        color: "#E2E8F0",
        marginTop: "24px",
        marginBottom: "24px",
    },
    viewPicker: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    } as React.CSSProperties,
    inputView: {
        display: "flex",
        flexDirection: "column",
        gap: "2px",
        width: "50%"
    } as React.CSSProperties,
    dropDownTime: {
        position: "absolute",
        zIndex: 10,
        right: 0,
        top: "12px"
    } as React.CSSProperties,
    inputTime: {
        backgroundColor: "#fff",
        border: "1px solid #D6DEEA",
        padding: '12px',
        borderRadius: "8px",
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        width: "100%",
        textAlign: "left"
    } as React.CSSProperties,
    viewTutorialSwitch: {
        display: "flex",
        backgroundColor: "#e2e8f0",
        marginBottom: "40px",
        borderRadius: "16px",
        padding: "12px"
    } as React.CSSProperties,
    textTutorial: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
    },
    textDescriptionTutorial: {
        color: "#5E6671",
        fontFamily: "DM Sans",
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 400,
    },
    dropdownView: {
        display: "flex",
        justifyContent: "space-between",
        width: "50%",
        alignItems: "center"
    } as React.CSSProperties,
    iconExpand: {
        color: "#fff",
        width: "16px",
        height: "16px",
    },
    viewDialogButton: {
        backgroundColor: "#edeaff",
        display: "flex",
        justifyContent: "flex-end",
        gap: "16px",
        zIndex: 10,
        width: "100%",
        paddingTop: "24.5px",
        paddingBottom: "24.5px",
        marginTop: "20px"
    } as React.CSSProperties,
    buttonDiscardChange: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        padding: "10px 16px",
        color: "#684EF3",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        backgroundColor: "#fff"
    } as React.CSSProperties,
    buttonUpdateChange: {
        padding: "10px 16px",
        borderRadius: "8px",
        color: "#fff",
        backgroundColor: "#684EF3",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        textAlign: "center",
        marginRight: "32px"
    } as React.CSSProperties,
    viewAlert: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        padding: "8px",
        borderRadius: "16px",
        color: "#fff",
        backgroundColor: "#33BC55"
    },
}

export const BlueSwitch = withStyles({
    switchBase: {
        color: 'white',
        '&$checked': {
            color: 'white',
        },
        '&$checked + $track': {
            backgroundColor: '#684EF3',
        },
    },
    checked: {},
    track: {},
})(Switch);
const useStyles: Record<string, CSSProperties> = {
    inputText: {
        border: "1px solid #E2E8F0",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "12px",
        backgroundColor: "#fff",
        borderRadius: "8px",
    },
    inputStyle: {
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        color: "#30353B",
    },
    customdropdown: {
        borderRadius: "26px",
        fontFamily: "DM Sans",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        border: "1px solid #E2E8F0",
        color: "#fff",
        backgroundColor: "#684EF3 !important",
        '& .MuiFilledInput-input': {
            padding: "2px 12px",
            height: "fit-content",
            backgroundColor: "#684EF3",
            borderRadius: "26px",
        },
        '&::before, &::after': {
            borderBottom: "none !important"
        },
        '&:hover': {
            textDecoration: "none !important",
            backgroundColor: "#684EF3 !important",
        },
        '&.MuiSelect-select:hover': {
            backgroundColor: "#684EF3 !important",
        }
    },
    dropdownitem: {
        fontFamily: "DM Sans",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        cursor: "pointer",
    },
}
export default withStyles(useStyles)(UserProfileBasicBlockWeb)
// Customizable Area End
