// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import createRequestMessage from "../../utilities/src/create-request-message";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { getStorageData } from "../../../framework/src/Utilities";

export type historyDataType = {
    id?: string | number;
    dateAndTime?: string;
    action?: {
        avarta: string,
		userName: string,
		actionDesc: string
	};
}

export interface ActionHistory {
    id: number,
    workspace_id: number,
    workspace_member_id: number,
    action_type: string,
    description: string,
    created_at: string,
    updated_at: string
}

export const configJSON = require("./config.js");

const avatarTpm = require("./svg-icons/avatar.svg");

export type Props = {
    classes?: Record<string, string>
    workspaceId: number
    currentUserId: string
    isReload: boolean
}

interface S {
    historyList?: ActionHistory[];
    anchorEl?:HTMLElement | null;
}

interface SS {
    id: string | number;
}

class HistoryTabController extends BlockComponent<Props, S, SS> {
    getHistoryListId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            historyList: [],
            
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    receive = (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getHistoryListId) {

                if(responseJson?.error){
                    this.setState({
                        historyList: [
                            {
                                id: 0,
                                workspace_id: 0,
                                workspace_member_id: 0,
                                action_type: "David Cooper",
                                description: "updated Report_67891",
                                created_at: "09/15/2024 9:12",
                                updated_at: "09/15/2024 9:12"
                            }
                        ]
                    });
                } else {
                    console.log("historyList: ",responseJson);
                    this.setState({ historyList: responseJson })
                }
            }
        }
    }


    async gethistoryListData() {
        console.log("call here: gethistoryListData() -workspaceId",  this.props.workspaceId);
        // `${configJSON.lastInteractions}?workspace_id=${workspaceId}&page=${this.state.page}&per_page=${this.state.rowsPerPage}`
        const token = await getStorageData("authToken");
        const workspaceId = this.props.workspaceId;
        const webHeader = {
            "Content-Type": configJSON.apiContentType,
            token
          };
        const webRequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getHistoryListId = webRequestMessage.messageId;
        webRequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.lastInteractions}?workspace_id=${workspaceId}`
        );
    
        webRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(webHeader)
        );
    
        webRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
        runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
        return true;
    }

    handleClick = () => {
        this.setState({anchorEl:null});
    };

    async componentDidMount(){
        super.componentDidMount();
        this.gethistoryListData();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isReload !== this.props.isReload) {
          this.gethistoryListData();
        }
    }

    handleReset = () => {
        this.gethistoryListData();
    }
}

export default HistoryTabController;
// Customizable Area End
