import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";

export const webConfigJSON = require("../src/config.js");
// Customizable Area End

 
  // Customizable Area Start
export interface FileInteraction {
  filename: string,
  url: string,
}

export interface FileObject {
  filename: string
  content_type: string
  url: string
}
  export interface InteractionObject {
    id: number;
    workspace_id: number;
    workspace_member_id: number;
    action_type: string
    description: string
    created_at: string
    updated_at: string
    file_details: FileObject[]
  }

  export interface InteractionTableObject {
    actionType: string
    type: string
    url: string
    filename: string
    lastUpdate: string
  }
  // Customizable Area End
export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  errorMsg: string;
  loading: boolean; 
  rows: InteractionObject[];
  page: number;
  rowsPerPage: number;
  lastInteractions: InteractionTableObject[];
  currentName: string
  isDialogOpen: boolean
  currentFile?: FileInteraction
  // Customizable Area End
}
interface SS { 
 // Customizable Area Start
  // Customizable Area End
}

export default class GeneralTabAllFilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  workspaceId: number = 0;
  apiGetAllInteractions:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage), 
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      rows: [],
      loading: true,
      page: 1,
      rowsPerPage: 10,
      errorMsg: "",
      token: "",
      lastInteractions:[],
      currentName: "",
      isDialogOpen: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAllInteractionsAPI();
    // Customizable Area End
  }

  async getAllInteractionsAPI() {
    // Customizable Area Start
    const token = await getStorageData("authToken");
    const workspaceId = this.props.navigation.getParam("id");
    const webHeader = {
        "Content-Type": webConfigJSON.apiContentType,
        token
      };
    const webAPIRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAllInteractions = webAPIRequestMessage.messageId;
    webAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.lastInteractions}?workspace_id=${workspaceId}&page=${this.state.page}&per_page=${this.state.rowsPerPage}`
    );

    webAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getApiMethod
    );
    runEngine.sendMessage(webAPIRequestMessage.id, webAPIRequestMessage);
    // Customizable Area End
    return true;
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if(webApiRequestCallId === this.apiGetAllInteractions){
          this.handleListInteraction(webResponseJson)
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if(webApiRequestCallId === this.apiGetAllInteractions){
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start 
  closeDialog = () => this.setState({ isDialogOpen: false })

  handleListInteraction = async (response: InteractionObject[]) => {
    const currentName = await getStorageData("workspace_name")
    const dataTable = response.map((item: InteractionObject) => {
      const listName = item?.file_details[0]?.content_type?.split('/') ?? ['']
      const extension = listName[listName.length - 1]
      return {
        actionType: item.action_type,
        type: extension.toUpperCase(),
        url: `${baseURL}${item?.file_details[0]?.url ?? ""}`,
        lastUpdate: moment(item.updated_at).format("MM/DD/YYYY, hh:mm"),
        filename: item?.file_details[0]?.filename ?? ""
      }
    })
    this.setState({ lastInteractions: dataTable, currentName });
  }

  downloadFile =  async() => {
    let response = await fetch(this.state.currentFile?.url ?? "",
        {
            method: 'GET',
        }
    );
    let blobImage = await response.blob();
    let href = URL.createObjectURL(new Blob([blobImage]));
    let anchorElement = document.createElement("a");
    anchorElement.setAttribute("href", href);
    anchorElement.setAttribute("download", "QRCode.pdf");
    anchorElement.setAttribute("rel", "noreferrer");
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  }

  onChooseAction = (row: InteractionTableObject) => {
    if (row.url !== "") {
      this.setState({
        currentFile: {
          filename: row.filename,
          url: row.url
      },
        isDialogOpen: true
      })
    }
  }

  handleChangePage = (event: any, newPage: number) => {
    this.setState({ page: newPage },()=>{
      this.getAllInteractionsAPI()
    });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 1,
    },()=>{
      this.getAllInteractionsAPI()
    });
  };
  goToWorkspaceList = () => {
    this.props.navigation.navigate("WorkspaceList");
  }

  goToWorkspaceListById = () => {
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
