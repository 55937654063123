import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Drawer,
  List,
  ListItem,
  Avatar,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { multiIcon, teamIcon, chatIcon, workspaceIcon, dashboardIcon, avatar, logoText } from "./assets";

// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  list = () => (
    <div
      className="left"
      style={{
        width: 250,
        background: "#14101E", height: "100%"
      }}
      role="presentation"
    >

      <Box style={{ display: "flex", justifyContent: "center", paddingTop: "30px" }}>
      <img style={{ width: '148.05px', height: '30px', gap: '2.8px ' }} src={logoText} />
      </Box>

      <Box style={{ display: "flex", flexDirection: "column", paddingTop: "4rem", gap: "2rem", marginLeft: "45px", position: 'relative' }}>
        <StyledBox style={{ cursor: "pointer" }} onClick={this.handleClick.bind(this, 'dashboard')}>
          <img src={dashboardIcon} alt="dashboardIcon" />
          <StyledText className={this.state.isActive ==='dashboard'? 'active' : ''}  >
            Dashboard
          </StyledText>
        </StyledBox>
        <StyledBox style={{ cursor: "pointer" }} onClick={this.handleClick.bind(this, 'workspaces')}>
          <img style={{ height: "20px", width: "25px" }} src={workspaceIcon} alt="workspaceIcon" />
          <StyledText data-test-id='clickbtn' className={this.state.isActive ==='workspaces'? 'active' : ''} >
            Workspaces
          </StyledText>
        </StyledBox>

        <StyledBox style={{ cursor: "pointer" }}>
          <img src={chatIcon} alt="chatIcon" />
          <StyledText className={this.state.isActive ==='chats' ? 'active' : ''} onClick={this.handleClick.bind(this, 'chats')} >
            Chats
          </StyledText>
        </StyledBox>
        <StyledBox style={{ cursor: "pointer" }} onClick={this.handleClick.bind(this, 'teams')}>
          <img src={teamIcon} alt="teamIcon" />
          <StyledText data-test-id='clickbtn' className={this.state.isActive ==='teams'? 'active' : ''} onClick={this.handleClick.bind(this, 'teams')} >
            Teams
          </StyledText>
        </StyledBox>
        <StyledBox style={{ cursor: "pointer" }}>
          <img src={multiIcon} alt="multiIcon" />
          <StyledText  className={this.state.isActive ==='multipageforms'? 'active' : ''} onClick={this.handleClick.bind(this, 'multipageforms')} >
            Multipage Forms
          </StyledText>
        </StyledBox>
      </Box>

      <Box style={{ display: "block", position: 'absolute', bottom: '0', width: "100%" }}>
        <Box onClick={this.toggleDrawer} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: "1px solid grey", borderBottom: "1px solid grey", height: "2.5rem", cursor: "pointer" }}>
          <ArrowBackIosOutlinedIcon style={webStyle.arrowMobile} />
        </Box>
        <Box onClick={this.handleShowProfile} style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "1rem", padding: "16px 22px" }} >
          <Avatar key={this.state.image ?? ""} alt="avatar" src={this.state.image|| ""} style={webStyle.avatar}>
            {this.state.userName?.charAt(0) ?? ""}
          </Avatar>
          <Typography style={{ color: "white" }}>{this.state.userName}</Typography>
        </Box>
      </Box>

      <Divider />
    </div>
  );


  mobileList = () => (
    <div
      className="left"
      style={{
        width: "88px",
        background: "#14101E", height: "100%"
      }}
      role="presentation"
    >

      <Box style={{ display: "flex", justifyContent: "center", paddingTop: "30px" }}>
        <Typography variant="h4" style={{ color: "white", fontSize: "28px", fontWeight: "500" }}>EG<span style={{ fontWeight: "400" }}>AI</span></Typography>
      </Box>

      <Box style={{ display: "flex", flexDirection: "column", paddingTop: "4rem" }}>
        <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1.5rem" }}>
          <img style={{ cursor: "pointer" }} data-test-id='clickbtn' src={dashboardIcon} alt="dashboardIcon" onClick={this.handleClick.bind(this, 'dashboard')} />
          <img style={{ height: "19px", width: "25px",  cursor: "pointer" }} src={workspaceIcon} alt="workspaceIcon" onClick={this.handleClick.bind(this, 'workspaces')}/>
          <img src={chatIcon} alt="chatIcon" style={{ cursor: "pointer" }} />
          <img src={teamIcon} alt="teamIcon" onClick={this.handleClick.bind(this, 'teams')} style={{ cursor: "pointer" }}/>
          <img src={multiIcon} alt="multiIcon" style={{ cursor: "pointer" }} />
        </Box>
      </Box>

      <Box style={webStyle.viewInfoMobile}>
        <Box onClick={this.toggleDrawer} style={webStyle.arrowViewMobile}>
          <ArrowForwardIosIcon style={webStyle.arrowMobile} />
        </Box>
        <Box onClick={this.handleShowProfile} style={webStyle.avatarMobile}>
          <Avatar key={this.state.image ?? ""} alt="avatar" src={this.state.image|| ""} style={webStyle.avatar}>
            {this.state.userName?.charAt(0) ?? ""}
          </Avatar>
        </Box>
      </Box>

      <Divider />
    </div>
  );

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box height={"100%"} position={"relative"} style={{display:"flex"}}>
        <Box height={"100%"}> {this.state.webDrawer ? this.list() : this.mobileList()}</Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledText=styled(Typography)({
  fontSize: 16,
  color: "grey",
  cursor: "pointer",
  '&:hover':{
    color:"white"
  },
  '&.active': {
    color: "white" 
  }
});


const StyledBox = styled(Box)({
  display: "flex",
  gap: "1rem",
  '&:hover': {
    color: "green"
  }
});

const webStyle = {
  avatar: {
    width: "44px",
    height: "44px",
    borderRadius: "44px",
    fontSize: "14px"
  },
  viewInfoMobile: {
    display: "block",
    position: 'absolute',
    bottom: '0'
  } as React.CSSProperties,
  arrowViewMobile: {
    width: "119%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: "1px solid grey",
    borderBottom: "1px solid grey",
    cursor: "pointer",
    height: "2.5rem"
  },
  arrowMobile: {
    color: "#fff",
    fontSize: "19px"
  },
  avatarMobile: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    justifyContent: "center",
    padding: "1rem 1rem",
    width: "76%"
  },
};
// Customizable Area End
