import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";




interface RequestBody {
  data: {
    type: string;
    attributes: {
      email: string;
      password: string;
      password_confirmation: string;
    };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  emailError: boolean;
  password: string;
  passwordError: string;
  confirmPasswordError: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  confirmPassword: string;
  showPassword: boolean;
  emailErrorMsg: string;
  emailInvalidErrorMsg: string;
  termsError: string;
  confirmPasswordReqError: boolean;
  showConfirmPassword: boolean;
  agreed: boolean;
  passwordCheckUppercase: boolean;
  passwordCheckLowercase: boolean;
  passwordCheckNumber: boolean;
  passwordCheckMinLength: boolean;
  isColorBtn:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  apiSignupCallId: string = ""

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      emailError: false,
      password: "",
      passwordError: "",
      showConfirmPassword: false,
      confirmPasswordError: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      confirmPassword: '',
      termsError: "",
      emailErrorMsg: "",
      emailInvalidErrorMsg: "",
      showPassword: false,
      confirmPasswordReqError: false,
      agreed: false,
      passwordCheckUppercase: false,
      passwordCheckLowercase: false,
      passwordCheckNumber: false,
      passwordCheckMinLength: false,
      isColorBtn:false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    this.labelFirstName = configJSON.labelFirstName;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.labelLegalText = configJSON.labelLegalText;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelHeader = configJSON.labelHeader;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.apiSignupCallId) {
          this.handleSubmitSignupResponse(responseJson)
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getSessionStorageData();
  }

  getSessionStorageData() {
    let isBackFromPolicyTearmCondition = sessionStorage.getItem('movedToTearmsAndConditions');
    if(isBackFromPolicyTearmCondition) {
      let emailSS = sessionStorage.getItem('emailSS');
      let passwordSS = sessionStorage.getItem('passwordSS');
      let confirmPasswordSS = sessionStorage.getItem('confirmPasswordSS');

      if(emailSS) {
        this.setState({email: emailSS});
      }
      if(passwordSS) {
        this.setState({password: passwordSS});
      }
      if(confirmPasswordSS) {
        this.setState({confirmPassword: confirmPasswordSS});
      }

      sessionStorage.removeItem('emailSS');
      sessionStorage.removeItem('passwordSS');
      sessionStorage.removeItem('confirmPasswordSS');
      sessionStorage.removeItem('movedToTearmsAndConditions');
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    const phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPwdNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };
  handleChange = (e: any) => {

    const { name, value } = e.target;
    switch (name) {
      case 'email':
        this.handleEmailChange(value);
        break;
      case 'password':
        this.handlePasswordChange(value);
        break;
      case 'confirmPassword':
        this.handleConfirmPasswordChange(value);
        break;
      default:
        break;
    }
  };

  handleEmailChange = (value: string) => {    
    this.setState({ email: value });
    if (value.length===0) {
      this.setState({ emailInvalidErrorMsg: "", emailErrorMsg: "Email is required"});
    }else{
      this.setState({ emailInvalidErrorMsg: "", emailErrorMsg: ""});
    }
  };

  handlePasswordChange = (value: string) => {
    this.setState({password: value});
    const isMinLengthValid  = (value || '').length >= 8

    const hasUppercase = /[A-Z]/.test(value || '');
    const hasLowercase = /[a-z]/.test(value || '');
    const hasNumber = /\d/.test(value || '');

    this.setState({
      passwordCheckUppercase: hasUppercase,
      passwordCheckLowercase: hasLowercase,
      passwordCheckNumber: hasNumber,
      passwordCheckMinLength: isMinLengthValid,
    });

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (value.match(passwordRegex)) {
      this.setState({ password: value, passwordError: '' });
    } else {
      this.setState({ passwordError: configJSON.pwdMsgError })
    }

    if (this.state.confirmPassword !== value) {
      this.setState({confirmPasswordError: configJSON.confirmPwd });
    } else {
      this.setState({confirmPasswordError: "" });
    }
  };


  handleConfirmPasswordChange = (value: string) => {
    this.setState({confirmPassword: value});
    if (!value || value.trim() === '') {
      this.setState({ confirmPasswordReqError: true })
    } else if (this.state.password !== value) {
      this.setState({confirmPasswordError: configJSON.confirmPwd, confirmPasswordReqError: false });
    } else {
      this.setState({confirmPasswordError: "", confirmPasswordReqError: false });
    }
  };

  validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  isValidateSignup = () => {
    let flag = true;
    if (this.state.email.length === 0) {
      this.setState({ emailErrorMsg: "Email is required" })
      flag = false
    } else if (!this.validateEmail(this.state.email)) {
      this.setState({ emailInvalidErrorMsg: "Invalid Email" })
      flag = false
    } 

    if(this.state.password && this.state.password.length > 0) {
        this.handlePasswordChange(this.state.password);
        if ((this.state.passwordError || this.state.passwordError.trim().length > 0)
        ) {
          flag = false
        }
    } else {
      this.setState({ passwordError: configJSON.pwdMsg })
      flag = false
    }

    if(this.state.confirmPassword && this.state.confirmPassword.length > 0) {
      this.handleConfirmPasswordChange(this.state.confirmPassword);
      if((this.state.confirmPasswordError.trim().length > 0 || this.state.confirmPassword !== this.state.password)
      ) {
        flag = false
      }
    } else {
      this.setState({ confirmPasswordReqError: true })
      flag = false
    } 

    if (!this.state.agreed) {
      this.setState({ termsError: "To agree with Privacy Policy and Terms and Conditions is required" , isColorBtn:false})
      flag = false
    }

    return flag;
  }

   handleAccount = () => {
    if (this.isValidateSignup()) {
      const requestBody = {
        data: {
          type: "email_account",
          attributes: {
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.password
          }
        }
      }
      this.handleSubmitSignup(requestBody)
    }
  }

  handleClickShowPassword = (type: string) => {
    if (type === 'password') {
      this.setState({ showPassword: !this.state.showPassword });
    } else if (type === 'confirmpassword') {
      this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }
  };




  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ agreed: event.target.checked, termsError: '' });
    if(event.target.checked){
      this.setState({ isColorBtn:true });
    }else{
      this.setState({ isColorBtn:false });
    }
  };


  phonenumberVerification = () => {
    localStorage.setItem("email", this.state.email)
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailVerification'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { email: this.state.email })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }



  backToLogin = () => {
    const messageType: Message = new Message(getName(MessageEnum.NavigationMessage))
    messageType.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    messageType.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { email: this.state.email })
    messageType.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageType);
  }

  navigateTotearmsAndConditions = () => {
    sessionStorage.setItem('movedToTearmsAndConditions', JSON.stringify(true));
    sessionStorage.setItem('emailSS', this.state.email);
    sessionStorage.setItem('passwordSS', this.state.password);
    sessionStorage.setItem('confirmPasswordSS', this.state.confirmPassword);
    const messageType: Message = new Message(
      getName(MessageEnum.NavigationMessage))
    messageType.addData(
      getName(MessageEnum.NavigationTargetMessage),'TermsConditionsDetail');
    messageType.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(messageType);
  }

  handleSubmitSignup = async (requestBody: RequestBody) => {


    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };


    let requestUrl = configJSON.signupEndpoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSignupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubmitSignupResponse = async (responseJson: {
    data: Array<string>,
    errors: Array<{ account: string }>;
  }) => {
    if (responseJson?.data) {
      this.phonenumberVerification();
    } else if (responseJson?.errors[0]?.account.includes("Email")) {
      this.setState({ emailErrorMsg: 'This email already registered within EmergeGen' })
    }

  };
  // Customizable Area End
}
