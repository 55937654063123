Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
exports.verifyOtpEndpoint="/account_block/accounts/verify_otp"
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPwdNotValid = "Password not valid.";
exports.pwdMsg='Password is required'
exports.errorTitle = "Error";
exports.pwdMsgError='Use at least 8 characters. Include an uppercase, lowercase and a number'
exports.confirmPwd="Confirm password doesn't match"
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";
exports.emailValid='/^[^\s@]+@[^\s@]+\.[^\s@]+$/'
exports.apiMethodTypeAddDetail = "POST";
exports.apiMethodTypeGet = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.signupEndpoint="account_block/accounts";
exports.factorAuthentication = "/bx_block_login/logins/verify_two_factor";
exports.resendcodeEndPoint = "/bx_block_login/logins/resend_login_email";
exports.resendcodeEmailEndPoint = "/account_block/accounts/resend_signup_email"
// Customizable Area End
