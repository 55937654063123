// Customizable Area Start
import React from "react";
import EmailVerificationController, { Props } from "./EmailVerificationController";
export const configJSON = require("./config");
import { Box, Button, IconButton, Snackbar, SnackbarContent, Typography} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import OtpInput from "react-otp-input";
import { styled } from "@material-ui/core/styles";
import { imageLogo, imageBackground, mail } from './assets';

export default class EmailVerification extends EmailVerificationController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <MainContainerBox >
                <LogoContainer>
                    <img src={imageLogo} alt="Logo" />
                </LogoContainer>
                <InnerBox >
                        <OuterContainer>
                        <InnerContainer>
                            <img src={mail} alt="Mail" />
                        </InnerContainer>
                    </OuterContainer>
                    <MainHeadingTypography>Verify your email</MainHeadingTypography>
                    <SubHeadingTypo >
                        We’ve just sent a 5-digit code to an email <span style={{fontWeight:700}}>{this.state.email}</span> enter it below
                    </SubHeadingTypo>
                    <BoxOtp>
                        <OtpInput
                            value={this.state.otpNumber}
                            inputType="tel"
                            onChange={this.handleOtp}
                            containerStyle={{ outline: "none" }}
                            data-test-id="otpinput"
                            numInputs={5}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{
                                width: '80px',
                                height: '56px',
                                padding: '10px 12px 10px 12px',
                                margin: '8px',
                                borderRadius: '8px',
                                border: this.state.otpMessage ? '1px solid red' : '1px solid rgba(205, 215, 230, 1)',
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                            }}
                        />
                    </BoxOtp>
                    {this.state.otpMessage &&
                  <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}>{this.state.otpMessage}</Box>}
                    
                    <ButtonVerified
                       style={{background: this.state.isBtnColor ? "#684EF3" : "rgba(148, 163, 184, 1)", cursor: "pointer"}}
                        data-test-id="verify"
                        onClick={this.verifyOtp}
                    >
                        Verify 
                    </ButtonVerified>
                    <Typography
                        style={webStyle.optText}
                        data-test-id="resend"
                        onClick={this.handleReSendCodeEmail}
                    >
                        Resend email
                    </Typography>
                    <ButtonTextBox data-testId="back-button" onClick={this.backToSignup}>
                        <CustomButton style={{textTransform:"capitalize"}}>Back to sign up</CustomButton>
                    </ButtonTextBox>
                </InnerBox>
                <Snackbar
                  anchorOrigin={{ vertical:"top", horizontal:"center" }}
                  open={this.state.isresendClicked}
                  onClose={this.handleClose}
                  key={"top" + "center"}
                >
                  <SnackbarContent
                    message="Code has been resent"
                    style={{ backgroundColor: '#33BC55',borderRadius:"8px" }}
                    action={
                      <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                  />
                </Snackbar>
            </MainContainerBox>
        );
    }
}
export const MainContainerBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: `url(${imageBackground})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
    "@media only screen and (max-width: 450px)": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
});

const LogoContainer = styled(Box)({
    position: "absolute",
    top: '48px',
    left: '80px',
    img: {
        width: '141.05px',
        height: '28px',
        gap: '2.8px'
    }
});

const ButtonTextBox = styled(Box)(({ theme }) => ({
    ...webStyle.buttonText,
}));

const CustomButton = styled(Button)({
    color: "rgba(104, 78, 243, 1)",
    fontFamily: 'DM Sans',
    fontWeight: 400,
    fontSize: '16px',
});

const OuterContainer = styled(Box)({
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
});

const InnerContainer = styled(Box)({
    boxShadow: 'rgba(227, 222, 255, 1)',
});

const MainHeadingTypography = styled(Typography)({
    marginTop: "32px",
    fontSize: "32px",
    textAlign: "center",
    color: "rgba(20, 16, 30, 1)",
    fontWeight: 500,
    lineHeight: "41.6px",
    fontFamily: "DM Sans",
    "@media only screen and (max-width: 502px)": {
        lineHeight: "32px",
        fontSize: "24px",
        marginTop: "129px",
    },
});

const BoxOtp = styled(Box)({
    marginTop: "16px",
    display: 'flex',
    gap: '8px',
    justifyContent:'center',
    "@media only screen and (max-width: 502px)": {
        marginTop: "96px",
    },
});

const ButtonVerified = styled(Box)({
    color: "rgba(255, 255, 255, 1)",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "DM Sans",
    textTransform: "none" as "none",
    height: "56px",
    borderRadius: "8px",
    marginTop: "16px",
    display:"flex", 
    justifyContent:"center",
    alignItems:"center",
    width:"100%",
    "@media only screen and (max-width: 502px)": {
        marginBottom: "48px",
        marginTop: "145px",
    },
});

const SubHeadingTypo = styled(Typography)({
    color: "rgba(48, 53, 59, 1)",
    fontWeight: 400,
    marginTop: "16px",
    lineHeight: "24px",
    fontFamily: "DM Sans",
    textAlign: "center",
    fontSize: "16px",

    "@media only screen and (max-width: 502px)": {
        fontSize: "14px",
        lineHeight: "22px",
        marginTop: "8px",
    },
});
const InnerBox = styled(Box)({
  display:"flex", 
  flexDirection:"column",
  alignItems:"center",
    width:"465px",
    "@media only screen and (max-width: 439px)": {
        width: "327px",
    },
    "@media only screen and (max-width: 341px)": {
        width: "278",
    },
});

const webStyle = {
    createBtn: {
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Inter",
        textTransform: "none" as "none",
        background: "#395169",
        height: "56px",
        borderRadius: "8px",
        marginTop: "42px",
        widht: "100%",
    },
    optText: {
        color: "rgba(104, 78, 243, 1)",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "center" as "center",
        marginTop: "28px",
        cursor: "pointer",
    },
    buttonText: {
        color: "rgba(104, 78, 243, 1)",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "center" as "center",
        marginTop: "146px",
        cursor: "pointer",
    },

    popup: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        color: "#1E293B",
        borderRadius: "8px",
        height: "42px",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Inter",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    poperror: {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        backgroundColor: "#FEE2E2",
        color: "DC2626",
        borderRadius: "4px",
        borderLeft: "4px solid #DC2626",
        height: "42px",
        fontFamily: "Inter",
        paddingLeft: "10px",
        fontWeight: 400,
        paddingRight: "10px",
        lineHeight: "24px",
        marginTop: "40px"
    },
};
// Customizable Area End