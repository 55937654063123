// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { AddNewMemberToWorkspaceObject } from "./MemberTabController.web";

export const configJSON = require("./config");

export type Props = {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: () => void
    onOkCloseDialog: () => void
    title: string
    description: string
    titleCancelButton: string
    titleOkButton: string
    checkConfirmationTitle?: string
}

interface S {
    agreed: boolean;
}

interface SS {
}

class ConfirmationDialogController extends BlockComponent<Props, S, SS> {
    getListUserId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            agreed: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    closeDialog = () => {
        this.props.onCloseDialog()
    }

    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ agreed: event.target.checked});
    };

    okClick = () => {
        if (this.props.checkConfirmationTitle){
            if(this.state.agreed) {
                this.props.onOkCloseDialog()
            }
        } else {
            this.props.onOkCloseDialog()
        }
    }
}

export default ConfirmationDialogController;
// Customizable Area End
