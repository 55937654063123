// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import { UserResponse } from "./CreateWorkspaceDialogController.web";
import { AddNewMemberToWorkspaceObject } from "./MemberTabController.web";
import { debounce } from "@material-ui/core";
import React from "react";

export const configJSON = require("./config");

export type Props = {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: (data?: AddNewMemberToWorkspaceObject) => void
    classes?: Record<string, string>
    workspaceId: number,
    actionType: string,
}

interface S {
    userList: Array<UserResponse>
    listChosenUser: Array<UserResponse>
    searchText: string
    selectedUser:any
    userIds:any;
    anchorEl:any;
}

interface SS {
}

class AddMemberDialogController extends BlockComponent<Props, S, SS> {
    getListUserId: string = ""
    debouncedSearch: any;
    anchorRef:any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            userList: [],
            listChosenUser: [],
            searchText: "",
            userIds:"",
            anchorEl:null,
            selectedUser:[]
        };
        this.anchorRef = React.createRef();
        this.debouncedSearch = debounce(this.getListUser.bind(this), 300);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getListUserId) {
                    this.setState({ userList: responseJson?.data ?? [] })
                }
            }
        }
        // Customizable Area End
    }

    handleUserSelect = (user:any) => {
        this.setState((prevState) => ({
          selectedUser: [...prevState.selectedUser, user],
          userIds: [...prevState.userIds, user.id],
          searchText: '',
          userList:[],
          anchorEl:null,
        }));
      };

      removeUser = (userId:any) => {
        this.setState((prevState) => ({
          selectedUser: prevState.selectedUser.filter((user: { id: any; }) => user.id !== userId),
          userIds: prevState.userIds.filter((id: any) => id !== userId),
        }));
      };

      handleSearchChange(event:any) {
        this.setState({ searchText:event.target.value},()=>this.debouncedSearch(this.state.searchText));
      }
    
    getListUser = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.getListUserId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.searchMemberWorkspace}?term=${this.state.searchText}`
        });
    }

    closeDialog = () => {
        this.setState({
            listChosenUser: [],
            searchText: "",
            userIds:"",
            anchorEl:null,
            selectedUser:[]
        })
        this.props.onCloseDialog()
    }

    createAndCloseDialog = () => {
        if (this.validationValue()) {
            const listAccountIds = this.state.selectedUser.map((item: { id: any; }) => Number(item.id)) 
            const listAccountEmails = this.state.selectedUser.map((item: { attributes: { email: any; }; }) => String(item.attributes.email))

            const data = {
                workspace_id: this.props.workspaceId,
                account_ids: listAccountIds,
                emails: listAccountEmails
            }
            this.setState({
                listChosenUser: [],
                searchText: "",
                userIds:"",
                anchorEl:null,
                selectedUser:[]
            })
            this.props.onCloseDialog(data)
        }
    }

    getCurrentCreateColor = () => {
        if (this.validationValue()) {
            return "#684EF3"
        } else return "#94A3B8"
    }

    validationValue = () => {
        if (this.state.selectedUser.length === 0) {
            return false
        } else return true
    }
}

export default AddMemberDialogController;
// Customizable Area End
