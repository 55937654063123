// Customizable Area Start
import React from "react";
import { Button,TextField, Typography, makeStyles } from "@material-ui/core";

import JsonTabController, { configJSON } from "./JsonTabController.web";

const copyJSONIcon = require("./svg-icons/copyJson.svg");

export class JsonTab extends JsonTabController {
    render() {
        const { classes } = this.props;

        return (
            <div style={{ padding: "16px" }} className="containerJsonData">
                <div style={{float: 'right', fontFamily: 'DM Sans'}}>
                    <Button
                        data-test-id="btnCopyJson"
                        onClick={this.handleCopyToClipboard}
                        startIcon={<img src={copyJSONIcon}/>}
                        style={jsonStlye.copyJsonButton}
                    >
                        {configJSON.copyJsonButtonTitle}
                    </Button>
                </div>
                <div style={{float: 'left', fontFamily: 'DM Sans', width: '100%'}}>
                    <TextField
                        data-test-id="txtJsonData"
                        multiline
                        rows={20}
                        fullWidth
                        value={this.state.jsonData}
                        style={jsonStlye.noBottomBorder}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            style: {
                                fontFamily: 'DM Sans',
                                border: "none",
                                
                              },
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default JsonTab;

const jsonStlye = {
    copyJsonButton: {
        color: '#684EF3', 
        backgroundColor: '#F6F5F9', 
        fontSize: '16px', 
        fontFamily: 'DM Sans',
        fontWeight: 400, 
        borderRadius: '8px', 
        border: '1px solid #684EF3',
        gap: '6px', 
        textTransform: 'none',
        width: "131px",
        height: "32px",
        padding: "16px 5px",
        '&:hover': {
            backgroundColor: '#F6F5F9',
            boxShadow: 'none', 
        },
    } as React.CSSProperties,
    noBottomBorder: {
        "& .MuiInput-underline:before": {
            borderBottom: "none", 
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none",
        },
    } as React.CSSProperties,
}

// Customizable Area End
